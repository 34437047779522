import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { inputClasses } from "@mui/material";

const FocusFormError: FC = () => {
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      const el = document.getElementsByClassName(inputClasses.error)[0];
      (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSubmitting, isValid, isValidating, errors]);

  return null;
};

export default FocusFormError;
