/**
 * This module processes server change log entries to create a timeline of changes.
 * It formats dates, maps server actions to user-friendly strings, and handles different types of updates.
 * Each log entry is processed and categorized by date, and additional data is included based on the type of action.
 */

import dayjs from "dayjs";
import {
  ChangelogDate,
  ChangelogEntry,
  ChangeLogUpdates,
  ServerChangeLogEntry,
  ServerEscalationCaseDetails,
  type WorkPacketActionName,
} from "src/types/work-packets";
import { WorkPacketType } from "../WorkPacketType";
import { getActionObject } from "./mapActionNameToPacketActionObject";
import { caseActionOptions } from "../Cases/caseActions";
import { attempt } from "src/utils/attempt";
import { getFeatureFlagFromSnapshot } from "src/feature-flags";
import {
  CorrespondenceWorkFlag,
  correspondenceWorkFlagLabels,
} from "src/pages/UserDashboard/WorkPackets/Cases/correspondence-work-flags.ts";

/**
 * Formats a date string into "Today" if the date is the current date, otherwise returns "MMM DD" format.
 */
function formatTimelineDate(dateString: string): string {
  const date = new Date(dateString);
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  } else {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }
}

const formatDate = (dateString: string) => attempt(() => dayjs(dateString).format("MMM DD, YYYY"), dateString);

const mapServerAction = (action: string, type: string): string => {
  const actions: Record<string, string> = {
    ASSIGNED: `Assigned the ${type}`,
    UNASSIGNED: `Unassigned the ${type}`,
    IDENTIFIED: `Identified the ${type}`,
    UPDATED: "Changed",
    LINKED: "Linked the",
    UNLINKED: "Unlinked the",
    FLAGGED: "",
    UNFLAGGED: "",
  };
  return actions[action];
};

type UpdateMapper<T> = (
  value: NonNullable<T>,
  log: ServerChangeLogEntry,
  workPacketType: WorkPacketType,
) => Partial<ChangelogEntry>[];

type UpdateMappers = {
  [UpdatedField in keyof ChangeLogUpdates]: UpdateMapper<ChangeLogUpdates[UpdatedField]>;
};

const escalationCaseDetailsMapper = (value: ServerEscalationCaseDetails) =>
  Object.entries(value).reduce((entries, [key, value]) => {
    if (key === "APPROVED_AMOUNT") {
      return [...entries, { state: "Approved Amount", to: value }];
    }
    if (key === "APPROVED_DATE") {
      return [...entries, { state: "Approved At", to: formatDate(value) }];
    }
    if (key === "MONITORED_DATE") {
      return [...entries, { state: "Monitored At", to: formatDate(value) }];
    }
    return entries;
  }, [] as Partial<ChangelogEntry>[]);

const updateMappers: UpdateMappers = {
  PACKET_OWNER_ID: (value, log) => [
    {
      to: log.PACKET_OWNER?.NICKNAME || value,
    },
  ],
  CURRENT_ACTION: (value, _log, workPacketType) => [
    {
      state: "Packet State",
      to: getActionObject(value as WorkPacketActionName, workPacketType).title,
    },
  ],
  NOTES: value => [
    {
      state: "Notes",
      to: value,
    },
  ],
  VALID: value => [
    {
      state: "Chargeback Validity",
      to: value ? "Valid" : "Invalid",
    },
  ],
  CASE_ID: (value, log) => {
    if (log.ACTION === "LINKED") {
      return [
        {
          state: "work packet to case",
          value,
        },
      ];
    } else if (log.ACTION === "UNLINKED") {
      return [
        {
          state: "work packet from case",
          value,
        },
      ];
    }
    return [
      {
        state: "Case ID",
        value,
      },
    ];
  },
  CASE_OWNER_ID: (value, log) => [
    {
      to: log.CASE_OWNER?.NICKNAME || value,
    },
  ],
  STATUS: value =>
    value.toLowerCase() === "verified"
      ? [
          {
            state: "Case",
            to: "Verified",
          },
        ]
      : [
          {
            state: "Case Status",
            to: caseActionOptions.find(action => action.value === value)?.title ?? value,
          },
        ],
  CHARGEBACKS_ESCALATION_CASE_DETAILS: escalationCaseDetailsMapper,
  SHORTAGES_ESCALATION_CASE_DETAILS: escalationCaseDetailsMapper,
  WORK_PACKETS: (value, log) => {
    if (log.ACTION === "LINKED") {
      return value.map(work_packet => ({
        state: "case to work packet",
        value: work_packet,
      }));
    }
    if (log.ACTION === "UNLINKED") {
      return value.map(work_packet => ({
        state: "case from work packet",
        value: work_packet,
      }));
    }
    return [
      {
        state: "work packet on case",
      },
    ];
  },
  DISPUTE_ID: value => [
    {
      state: "Original Disputed ID",
      to: value,
    },
  ],
  CONSOLIDATED_DISPUTE_ID: value => [
    {
      state: "Consolidated Disputed ID",
      to: value,
    },
  ],
  CORRESPONDENCE_WORK_FLAG: (value, log) => {
    if (log.ACTION === "FLAGGED") {
      return [
        {
          state: "Case received a new correspondence work flag - ",
          value: correspondenceWorkFlagLabels[value as CorrespondenceWorkFlag],
        },
      ];
    }
    if (log.ACTION === "UNFLAGGED") {
      return [
        {
          state: "Cleared a correspondence work flag - ",
          value: correspondenceWorkFlagLabels[value as CorrespondenceWorkFlag],
        },
      ];
    }
    return [
      {
        state: "Case correspondence work flags changed",
      },
    ];
  }
};

/**
 * Creates a changelog entry with the given log data, time, and additional data.
 */
const createEntry = (
  serverLog: ServerChangeLogEntry,
  time: string,
  originalDate: Date,
  isSystem: boolean,
  additionalData: Partial<ChangelogEntry> = {},
): ChangelogEntry => {
  const changeLogType = serverLog.CASE_ID ? "case" : "work packet";

  const entry: ChangelogEntry = {
    time,
    originalDate,
    user: isSystem ? "System auto update" : serverLog.USER?.NICKNAME || "",
    action: mapServerAction(serverLog.ACTION, changeLogType),
    isSystem,
    ...additionalData,
  };

  if (!isSystem && serverLog.USER?.PROFILE_PIC) {
    entry.avatarUrl = serverLog.USER.PROFILE_PIC;
  }

  return entry;
};

/**
 * Handles the updates for a log entry, creating separate changelog entries for each update.
 * @returns An array of changelog entries for the updates.
 */
const mapServerUpdateToLocalUpdates = (
  serverLog: ServerChangeLogEntry,
  time: string,
  isSystem: boolean,
  workPacketType: WorkPacketType,
  originalDate: Date,
): ChangelogEntry[] => {
  return Object.entries(serverLog.UPDATES).flatMap(([key, value]) => {
    const mapper = updateMappers[key as keyof ChangeLogUpdates] as UpdateMapper<
      ChangeLogUpdates[keyof ChangeLogUpdates]
    >;
    if (!mapper) return [];
    return mapper(value as NonNullable<ChangeLogUpdates[keyof ChangeLogUpdates]>, serverLog, workPacketType).map(
      partialLog => {
        partialLog.originalDate = originalDate;
        return createEntry(serverLog, time, originalDate, isSystem, partialLog);
      },
    );
  });
};

/**
 * Processes a single log entry, creating and categorizing changelog entries by date.
 * @param serverLog - The server change log entry.
 * @param entriesByDate - An object categorizing entries by date.
 */
const processLogEntry = (
  serverLog: ServerChangeLogEntry,
  entriesByDate: Record<string, ChangelogEntry[]>,
  workPacketType: WorkPacketType,
) => {
  const date = formatTimelineDate(serverLog.RECORDED_AT);
  const time = new Date(serverLog.RECORDED_AT).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const originalDate = new Date(serverLog.RECORDED_AT);

  const isSystem = serverLog.USER?.NICKNAME === "systemuser";

  if (serverLog.ACTION === "LINKED" || serverLog.ACTION === "UPDATED" || serverLog.ACTION === "UNLINKED" || serverLog.ACTION === "FLAGGED" || serverLog.ACTION === "UNFLAGGED") {
    if (Object.keys(serverLog.UPDATES).length === 0) return;
    const updateEntries = mapServerUpdateToLocalUpdates(serverLog, time, isSystem, workPacketType, originalDate);
    entriesByDate[date] = entriesByDate[date] || [];
    entriesByDate[date].push(...updateEntries);
    return;
  }

  const additionalData: Partial<ChangelogEntry> = {};
  if (serverLog.ACTION === "IDENTIFIED") {
    additionalData.packetId = serverLog.WORK_PACKET_ID;
  }

  if (serverLog.ACTION === "ASSIGNED") {
    additionalData.to =
      serverLog.PACKET_OWNER?.NICKNAME ||
      serverLog.UPDATES.PACKET_OWNER_ID ||
      serverLog.CASE_OWNER?.NICKNAME ||
      serverLog.UPDATES.CASE_OWNER_ID;

    if (serverLog.UPDATES.PACKET_OWNER_ID === null || serverLog.UPDATES.PACKET_OWNER_ID === null) {
      serverLog.ACTION = "UNASSIGNED";
    }
  }

  const entry = createEntry(serverLog, time, originalDate, isSystem, additionalData);
  entriesByDate[date] = entriesByDate[date] || [];
  entriesByDate[date].push(entry);
};

export const mapServerChangeLog = ({
  serverChangeLog,
  workPacketType,
  isCaseChangeLog = false,
}: {
  serverChangeLog: ServerChangeLogEntry[];
  workPacketType: WorkPacketType;
  isCaseChangeLog?: boolean;
}): ChangelogDate[] => {
  const entriesByDate: Record<string, ChangelogEntry[]> = {};
  serverChangeLog.forEach(log => processLogEntry(log, entriesByDate, workPacketType));

  if (isCaseChangeLog) {
    const firstLogDate = formatTimelineDate(serverChangeLog.at(-1)!.RECORDED_AT);
    const firstParsedLog = entriesByDate[firstLogDate].at(-1)!;

    const identifiedLogIndex = serverChangeLog.findIndex(log => log.ACTION === "IDENTIFIED");
    if (identifiedLogIndex >= 0) {
      const originalLog = serverChangeLog[identifiedLogIndex];
      const useSmartSelect = getFeatureFlagFromSnapshot("PRE_CASE_SMART_SELECT", false);
      const modifiedLog = useSmartSelect
        ? {
            action: "Case created through smart select",
            isSystem: true,
            time: firstParsedLog.time,
            user: "Smart Select",
            originalDate: firstParsedLog.originalDate,
          }
        : {
            action: `Case created - case ID ${originalLog.UPDATES.AMAZON_CASE_ID}`,
            time: firstParsedLog.time,
            user: firstParsedLog.user,
            originalDate: firstParsedLog.originalDate,
            avatarUrl: firstParsedLog.avatarUrl,
          };
      entriesByDate[firstLogDate].splice(identifiedLogIndex, 1, modifiedLog);
    }
  }

  return Object.keys(entriesByDate)
    .filter(date => entriesByDate[date].length > 0)
    .map(date => ({
      date,
      entries: entriesByDate[date],
    }));
};
