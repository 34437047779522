import type { Option } from "./case-types";

export const caseActionOptions: Option[] = [
  {
    title: "Waiting on Amazon",
    value: "WAITING_ON_AMAZON",
    color: "#FFCD4D",
  },
  {
    title: "Waiting on CG",
    value: "WAITING_ON_CG",
    color: "#FF820F",
  },
  {
    title: "Waiting on Client",
    value: "WAITING_ON_CLIENT",
    color: "#FF820F",
  },
  {
    title: "Waiting on Retemplate Submission",
    value: "WAITING_ON_RETEMPLATE_SUBMISSION",
    color: "#FF820F",
  },
  {
    title: "Paid Out",
    value: "PAID_OUT",
    color: "#318FFF",
  },
  {
    title: "Approved",
    value: "APPROVED",
    color: "#39D4A5",
  },
  {
    title: "Partially Approved",
    value: "PARTIALLY_APPROVED",
    color: "#318FFF",
  },
  {
    title: "Denied",
    value: "DENIED",
    color: "#E03741",
  },
  {
    title: "Closed w/o Resolution",
    value: "CLOSED_WITHOUT_RESOLUTION",
    color: "#FECDD6",
  },
  {
    title: "Hold",
    value: "HOLD",
    color: "#EAECF0",
  },
];

export const caseSettlementActionOptions: Option[] = [
  {
    title: "Waiting on Amazon",
    value: "WAITING_ON_AMAZON",
    color: "#FFCD4D",
  },
  {
    title: "Waiting on Client",
    value: "WAITING_ON_CLIENT",
    color: "#318FFF",
  },
  {
    title: "Waiting on Re-Template Submission",
    value: "WAITING_ON_RETEMPLATE_SUBMISSION",
    color: "#F63D68",
  },
  {
    title: "Waiting on CG",
    value: "WAITING_ON_CG",
    color: "#FECDD6",
  },
  {
    title: "Waiting on VM/AM",
    value: "WAITING_ON_VM_AM",
    color: "#FF820F",
  },
  {
    title: "Waiting on Proposed Offer Approval",
    value: "WAITING_ON_PROPOSAL_OFFER_APPROVAL",
    color: "#39D4A5",
  },
  {
    title: "Re-Analysis",
    value: "REANALYSIS",
    color: "#7B3DFF",
  },
  {
    title: "Waiting on Evidence",
    value: "WAITING_ON_EVIDENCE",
    color: "#F155FF",
  },
  {
    title: "Paid Out",
    value: "PAID_OUT",
    color: "#318FFF",
  },
  {
    title: "Submitted to Invoicing",
    value: "SUBMITTED_TO_INVOICING",
    color: "#F155FF",
  },
  {
    title: "Hold",
    value: "HOLD",
    color: "#EAECF0",
  },
];
