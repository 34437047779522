import { ServerCaseFilters } from "./casesApi";

export const initialFilters: ServerCaseFilters = {
  [ServerCaseFilters.AmazonCaseId]: "",
  [ServerCaseFilters.CreatedAt]: "",
  [ServerCaseFilters.CurrentAction]: "",
  [ServerCaseFilters.CurrentCaseOwner]: "",
  [ServerCaseFilters.StoreName]: "",
  [ServerCaseFilters.VendorName]: "",
  [ServerCaseFilters.PresubmissionRequired]: "",
  [ServerCaseFilters.Processor]: "",
  [ServerCaseFilters.SubmissionPath]: "",
  [ServerCaseFilters.Stage]: "",
  [ServerCaseFilters.SprinterViewedAt]: "",
  [ServerCaseFilters.Sprinter]: "",
  [ServerCaseFilters.SubmissionAmount]: [],
  [ServerCaseFilters.SubmissionDate]: "",
  [ServerCaseFilters.RedFlags]: [],
  [ServerCaseFilters.ManualFilingUser]: "",
  [ServerCaseFilters.CorrespondenceWorkFlags]: [],
};
