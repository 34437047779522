import { type MutationFunction, QueryFunction } from "@tanstack/react-query";
import type {
  FileUpload,
  FileUploadStore,
} from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/FileUploadContext.ts";
import { CaseBase } from "src/types/cases.ts";
import type { ServerModelAttachment } from "src/types/work-packets.ts";
import { getCaseAttachments, getCaseInvoices, uploadCaseAttachment } from "./api/casesApi";

export const getCaseAttachmentList: QueryFunction<
  ServerModelAttachment[],
  ["cases", caseId: string, "attachments"]
> = async ({ queryKey }) => getCaseAttachments(queryKey[1]);

export const getCaseInvoicesList: QueryFunction<
  ServerModelAttachment[],
  ["cases", caseId: string, "invoices"]
> = async ({ queryKey }) => getCaseInvoices(queryKey[1]);

export interface AttachmentUploadVariables {
  caseItem: CaseBase;
  fileUploads: FileUpload[];
  setFileUploadStatus: FileUploadStore["setFileUploadStatus"];
}

export const uploadCaseAttachments: MutationFunction<void, AttachmentUploadVariables> = async ({
  caseItem,
  fileUploads,
  setFileUploadStatus,
}) => {
  let failureCount = 0;
  for (const fileUpload of fileUploads) {
    if (fileUpload.status !== "pending") continue;
    await uploadCaseAttachment(caseItem.id, fileUpload.file, uploadProgress => {
      setFileUploadStatus(fileUpload.file.name, { status: "uploading", uploadProgress });
    })
      .then(() => {
        setFileUploadStatus(fileUpload.file.name, { status: "uploaded" });
      })
      .catch(err => {
        failureCount += 1;
        setFileUploadStatus(fileUpload.file.name, { status: "failed", error: err.message });
        console.error("upload failure", err);
      });
  }
  if (failureCount > 0) throw new Error("Attachment upload failed");
};
