import { TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import { type ShortageWorkPacket, WorkPacketOwner } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { AutocompleteField, DatePickerField, SelectField } from "components/UI/Form";
import { useUserOptions } from "src/pages/UserDashboard/WorkPackets/useUserOptions";
import { settlementCaseStatusMap } from "src/pages/UserDashboard/Cases/api/constants";
import { CaseDetailsKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import { Link } from "react-router-dom";

export const Settlements: FC = () => {
  const { values: packet } = useFormikContext<ShortageWorkPacket>();
  const userOptions = useUserOptions();

  if (!packet.settlement) {
    return null;
  }

  return (
    <FormFieldsGroup
      title="Settlement"
      withDivider
      id="settlement"
    >
      <p style={{ fontSize: '12px', gridColumn: "1 / -1" }}>
        To make updates to these fields, please go{' '}
        <Link 
          to={`/user-dashboard/work-packets/shortages/settlements/${packet.settlement[CaseDetailsKeys.CaseId]}/details`}
          style={{ color: 'primary.main', textDecoration: 'underline' }}
        >
          here
        </Link>
      </p>
  
      <Field
        name={`settlement.${CaseDetailsKeys.CaseId}`}
        as={TextField}
        variant="outlined"
        size="small"
        label="CG Case ID"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Status}`}
        component={AutocompleteField}
        label="Case Item Status"
        options={settlementCaseStatusMap}
        getOptionLabel={(option: any) => option.title}
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.PreSubmissionRequired}`}
        component={SelectField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Presubmission Required"
        options={[
          { value: "", label: "" },
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Business}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Business"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Payee}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Payee"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.VendorCodes}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Vendor Codes"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.DirectImport}`}
        component={SelectField}
        variant="outlined"
        placeholder="Direct Import"
        size="small"
        label="Direct Import"
        options={[
          { value: "", label: "" },
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Category}`}
        as={TextField}
        variant="outlined"
        placeholder="Category"
        size="small"
        label="Category"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.InvoiceStartDueDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="Invoice Start Due Date"
        size="small"
        label="Invoice Start Due Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.InvoiceEndDueDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="Invoice End Due Date"
        size="small"
        label="Invoice End Due Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.CaseOwner}`}
        component={AutocompleteField}
        label="Current Case Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Case Owner"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Sprinter}`}
        as={TextField}
        variant="outlined"
        placeholder="Sprinter"
        size="small"
        label="Sprinter"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SprinterDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="Sprinter Date"
        size="small"
        label="Sprinter Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.OpsExternalCommunication}`}
        component={SelectField}
        variant="outlined"
        placeholder="OPS External Communication"
        size="small"
        label="OPS External Communication"
        options={[
          { value: "", label: "" },
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Processor}`}
        component={AutocompleteField}
        label="Processor"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Processor"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.ProcessorDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="Processor Date"
        size="small"
        label="Processor Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SubmissionPath}`}
        as={TextField}
        variant="outlined"
        placeholder="Submission Path"
        size="small"
        label="Submission Path"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.ManualFilingUser}`}
        component={AutocompleteField}
        label="Manual Filing User"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Manual Filing User"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.CcUser}`}
        component={AutocompleteField}
        label="CC'd User"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="CC'd User"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.AmazonCaseId}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Current Case #"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Link}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Link"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.PrevCaseIds}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Previous Case # (s)"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.AmazonLastRespondedAt}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="AMZ Last Response"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.CgLastRespondedAt}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="CG Last Response"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.AmazonCaseManager}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Amazon Case Manager"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.Stage}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Stage"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SubmissionAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Submission Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SubmissionDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Submission Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SubmissionCount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Submission Count"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.AlignedBalAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Aligned Bal Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.AlignedBalDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Aligned Bal Date (CG)"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.RcaEndDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="RCA End Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Paid Amount Before Open Balance Alignment"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.ValidAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Valid Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.FirstOfferID}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="1st Offer ID"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.FirstOfferAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="1st Offer Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.FirstOfferDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="1st Offer Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SecondOfferID}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="2nd Offer ID"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SecondOfferAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="2nd Offer Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SecondOfferDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="2nd Offer Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.ThirdOfferID}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="3rd Offer ID"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.ThirdOfferAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="3rd Offer Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.ThirdOfferDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="3rd Offer Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.SettlementAcceptanceDateToAmazon}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Settlement Acceptance Date To Amazon"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.FinalAmount}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Final Amount"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.FinalAmountDate}`}
        component={DatePickerField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Final Amount Date"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.FinalRecoveryRateCalc}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Recovery Rate Calculation"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.PaymentIds}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Payment ID(s)"
        disabled
      />

      <Field
        name={`settlement.${CaseDetailsKeys.PayoutDate}`}
        as={TextField}
        variant="outlined"
        placeholder="-"
        size="small"
        label="Payout Date(s)"
        disabled
      />
    </FormFieldsGroup>
  );
};