import dayjs from "dayjs";
import { ServerCaseItem } from "src/pages/UserDashboard/Cases/api/types";
import { ServerChargebackResource, ServerStream } from "src/types/work-packets";
import { formatMaybeCurrency, valueOrUndefined } from "src/pages/UserDashboard/Cases/mappers/utils";
import {
  BaseCaseItem,
  CaseItem,
  ChargebackEscalationCaseItem,
  ShortageEscalationCaseItem,
  ShortageSettlementCaseItem,
} from "src/types/case-items.ts";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { CaseItemKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import { dateOrUndefined } from "src/utils/date-parse";

const getWorkPacketProperties = (item: ServerCaseItem, key: string): string => {
  return (
    item.WORK_PACKET?.WORK_PACKET_PROPERTIES?.filter(prop => prop.KEY === key)
      .map(prop => prop.VALUE)
      .join(", ") || ""
  );
};

function getLastItem<T>(items: T[], sortKey: keyof T): T | null {
  return (
    items.sort((a, b) => {
      return dayjs(a[sortKey] as any as string).isAfter(dayjs(b[sortKey] as any as string)) ? -1 : 1;
    })[0] || null
  );
}

export function mapBaseCaseItem(item: ServerCaseItem): BaseCaseItem {
  return {
    [CaseItemKeys.CaseItemId]: item.ID,
    [CaseItemKeys.CaseId]: item.CASE_ID,
    [CaseItemKeys.WorkPacketId]: item.WORK_PACKET_ID,
    [CaseItemKeys.ARN]: getWorkPacketProperties(item, "ARN"),
    [CaseItemKeys.ASN]: getWorkPacketProperties(item, "ASN"),
  };
}

function mapServerEscalationItemsToLocalEscalationCaseItem(item: ServerCaseItem): ShortageEscalationCaseItem {
  const lastInvoice = getLastItem(item.SHORTAGES_ESCALATION_PAYMENT_DETAILS, "PAYMENT_DATE");

  const vcParentInvoiceId = getWorkPacketProperties(item, "PARENT_INVOICE_NUMBER");

  return {
    ...mapBaseCaseItem(item),
    [CaseItemKeys.CaseItemStatus]: item.SHORTAGES_ESCALATION_CASE_ITEM?.STATUS || "",
    [CaseItemKeys.VcDisputedInvoiceId]: item.SHORTAGES_OSSR_WORK_PACKET?.DISPUTED_INVOICE_ID || "",
    [CaseItemKeys.VcParentInvoiceId]: vcParentInvoiceId,
    [CaseItemKeys.RemainingOpenBalance]: item.SHORTAGES_OSSR_WORK_PACKET?.REMAINING_BALANCE_OPEN || undefined,
    [CaseItemKeys.OSSRWorkPacketId]: item.SHORTAGES_OSSR_WORK_PACKET?.ID,
    [CaseItemKeys.PaymentDetailsId]: lastInvoice?.ID,
    [CaseItemKeys.PoNumber]: item.SHORTAGES_OSSR_WORK_PACKET?.PO_NUMBER || "",
    [CaseItemKeys.LastInvoiceNumberForPayment]: lastInvoice?.INVOICE_NUMBER_FOR_PAYMENT || "",
    [CaseItemKeys.LastInvoicePaymentId]: lastInvoice?.PAYMENT_ID || "",
    [CaseItemKeys.LastInvoiceClientAmount]: lastInvoice?.CUSTOMER_INVOICE_AMOUNT || "",
    [CaseItemKeys.LastInvoicePaymentDate]: dateOrUndefined(lastInvoice?.PAYMENT_DATE)?.toDate(),
    [CaseItemKeys.LastInvoiceClientDate]: dateOrUndefined(lastInvoice?.CUSTOMER_INVOICED_AT)?.toDate(),
    [CaseItemKeys.ApprovedAmount]: valueOrUndefined(item.SHORTAGES_ESCALATION_CASE_ITEM?.APPROVED_AMOUNT),
    [CaseItemKeys.ShortagesEscalationCaseItemDetailId]: item.SHORTAGES_ESCALATION_CASE_ITEM?.ID || "",
    [CaseItemKeys.ShortagesEscalationCaseDetailsId]: item.CASE?.SHORTAGES_ESCALATION_CASE_DETAILS?.ID || "",
  };
}

function mapServerChargebackEscalationCaseItemToLocalCaseItem(item: ServerCaseItem): ChargebackEscalationCaseItem {
  let financialCharge,
    asin = "",
    po = "";
  if (item.WORK_PACKET.RECOVERY_STREAM === ServerStream.CHARGEBACKS) {
    const workPacketResource = item.WORK_PACKET?.RESOURCE as ServerChargebackResource;
    financialCharge = workPacketResource.FINANCIAL_CHARGE;
    asin = workPacketResource.ASIN_ID;
    po = workPacketResource.VC_PO_ID;
  }

  const lastInvoice = getLastItem(item.CHARGEBACKS_ESCALATION_PAYMENT_DETAILS, "PAYMENT_DATE");

  return {
    ...mapBaseCaseItem(item),
    [CaseItemKeys.ChargeBackIssueId]:
      (item.WORK_PACKET?.RESOURCE as ServerChargebackResource).CHARGEBACK_ISSUE_ID || "",
    [CaseItemKeys.FinancialCharge]: financialCharge,
    [CaseItemKeys.CaseItemStatus]: item.CHARGEBACKS_ESCALATION_CASE_ITEM?.STATUS || "",
    [CaseItemKeys.ChargebacksEscalationCaseItemDetailId]: item.CHARGEBACKS_ESCALATION_CASE_ITEM?.ID || "",
    [CaseItemKeys.PaidAmount]: lastInvoice?.PAID_AMOUNT,
    [CaseItemKeys.PaymentId]: lastInvoice?.PAYMENT_ID || "",
    [CaseItemKeys.ASIN]: asin,
    [CaseItemKeys.PoNumber]: po,
    [CaseItemKeys.ApprovedAmount]: item.CASE.CHARGEBACKS_ESCALATION_CASE_DETAILS?.APPROVED_AMOUNT || undefined,
  };
}

function mapServerSettlementToLocalCaseItem(item: ServerCaseItem): ShortageSettlementCaseItem {
  const lastInvoice = getLastItem(item.SHORTAGES_SETTLEMENT_PAYMENT_DETAILS, "PAYMENT_DATE");

  return {
    ...mapBaseCaseItem(item),
    [CaseItemKeys.OSSRWorkPacketId]: item.SHORTAGES_OSSR_WORK_PACKET?.ID,
    [CaseItemKeys.PoNumber]: item.SHORTAGES_OSSR_WORK_PACKET?.PO_NUMBER || "",
    [CaseItemKeys.InvoiceForPayment]: lastInvoice?.INVOICE_ID_FOR_PAYMENT || "",
    [CaseItemKeys.PaymentDetailsId]: lastInvoice?.ID,
    [CaseItemKeys.PaymentId]: lastInvoice?.PAYMENT_ID || "",
    [CaseItemKeys.PaymentDate]: dateOrUndefined(lastInvoice?.PAYMENT_DATE)?.toDate(),
    [CaseItemKeys.RecoveredAmount]: formatMaybeCurrency(lastInvoice?.RECOVERED_AMOUNT),
  };
}

export const serverToLocalCaseItemMappers: Record<CaseType, (_: ServerCaseItem) => CaseItem> = {
  [CaseType.SHORTAGE_ESCALATION]: mapServerEscalationItemsToLocalEscalationCaseItem,
  [CaseType.CHARGEBACK_ESCALATION]: mapServerChargebackEscalationCaseItemToLocalCaseItem,
  [CaseType.SHORTAGE_SETTLEMENT]: mapServerSettlementToLocalCaseItem,
};
