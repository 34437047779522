import { FC } from "react";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup";
import { Field } from "formik";
import { AutocompleteField, DatePickerField } from "components/UI/Form";
import { TextField } from "@mui/material";
import { CurrencyField } from "../../../../../components/UI/Form/CurrencyField";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";

export const SettlementInfo: FC = () => {
  return (
    <FormFieldsGroup title="Settlements" id={"settlement-info"}>
      <Field
        name={CaseDetailsKeys.PreSubmissionRequired}
        component={AutocompleteField}
        variant="outlined"
        placeholder="Pre Submission Required"
        size="small"
        label="PreSubmission Required"
        getOptionLabel={(option: any) => option.title}
        options={[
          { value: "", title: "" },
          { value: true, title: "Yes" },
          { value: false, title: "No" },
        ]}
      />
      <Field
        name={CaseDetailsKeys.Business}
        as={TextField}
        variant="outlined"
        placeholder="Business"
        size="small"
        label="Business"
        disabled
      />
      <Field
        name={CaseDetailsKeys.Payee}
        as={TextField}
        variant="outlined"
        placeholder="Payee"
        size="small"
        label="Payee"
        disabled
      />
      <Field
        name={CaseDetailsKeys.VendorCodes}
        as={TextField}
        variant="outlined"
        placeholder="Vendor Codes"
        size="small"
        label="Vendor Codes"
      />
      <Field
        name={CaseDetailsKeys.DirectImport}
        component={AutocompleteField}
        variant="outlined"
        placeholder="Direct Import"
        size="small"
        label="Direct Import"
        getOptionLabel={(option: any) => option.title}
        options={[
          { value: "", title: "" },
          { value: true, title: "Yes" },
          { value: false, title: "No" },
        ]}
      />
      <Field
        name={CaseDetailsKeys.Category}
        as={TextField}
        variant="outlined"
        placeholder="Category"
        size="small"
        label="Category"
      />
      <Field
        name={CaseDetailsKeys.VcDisputedIds}
        as={TextField}
        variant="outlined"
        placeholder="VC Disputed Invoice ID"
        size="small"
        label="VC Disputed Invoice ID"
        disabled
      />
      <Field
        name={CaseDetailsKeys.InvoiceStartDueDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="INV Start Due Date"
        size="small"
        label="INV Start Due Date"
      />
      <Field
        name={CaseDetailsKeys.InvoiceEndDueDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="INV End Due Date"
        size="small"
        label="INV End Due Date"
      />
      <Field
        name={CaseDetailsKeys.SubmissionAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="Submission Amount"
        size="small"
        label="Submission Amount"
        disabled
      />
      <Field
        name={CaseDetailsKeys.SubmissionDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Submission Date"
        size="small"
        label="Submission Date"
      />
      <Field
        name={CaseDetailsKeys.SubmissionCount}
        as={TextField}
        variant="outlined"
        placeholder="Submission Count"
        size="small"
        label="Submission Count"
        disabled
      />
      <Field
        name={CaseDetailsKeys.AlignedBalAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="Aligned Bal Amount"
        size="small"
        label="Aligned Bal Amount"
      />
      <Field
        name={CaseDetailsKeys.AlignedBalDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Aligned Bal Date"
        size="small"
        label="Aligned Bal Date (CG)"
      />
      <Field
        name={CaseDetailsKeys.RcaEndDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="RCA End Date"
        size="small"
        label="RCA End Date"
      />
      <Field
        name={CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment}
        as={CurrencyField}
        variant="outlined"
        placeholder="Paid Amount Before Open Balance Alignment"
        size="small"
        label="Paid Amount Before Open Balance Alignment"
      />
      <Field
        name={CaseDetailsKeys.ValidAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="Valid Amount"
        size="small"
        label="Valid Amount"
      />
      <Field
        name={CaseDetailsKeys.FirstOfferAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="1st Offer Amount"
        size="small"
        label="1st Offer Amount"
      />
      <Field
        name={CaseDetailsKeys.FirstOfferDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="1st Offer Date"
        size="small"
        label="1st Offer Date"
      />
      <Field
        name={CaseDetailsKeys.SecondOfferAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="2nd Offer Amount"
        size="small"
        label="2nd Offer Amount"
      />
      <Field
        name={CaseDetailsKeys.SecondOfferDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="2nd Offer Date"
        size="small"
        label="2nd Offer Date"
      />
      <Field
        name={CaseDetailsKeys.ThirdOfferAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="3rd Offer Amount"
        size="small"
        label="3rd Offer Amount"
      />
      <Field
        name={CaseDetailsKeys.ThirdOfferDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="3rd Offer Date"
        size="small"
        label="3rd Offer Date"
      />
      <Field
        name={CaseDetailsKeys.SettlementAcceptanceDateToAmazon}
        component={DatePickerField}
        variant="outlined"
        placeholder="Settlement Acceptance Date to Amzn"
        size="small"
        label="Settlement Acceptance Date to Amzn"
      />
      <Field
        name={CaseDetailsKeys.FinalAmount}
        as={CurrencyField}
        variant="outlined"
        placeholder="Final Amount"
        size="small"
        label="Final Amount"
      />
      <Field
        name={CaseDetailsKeys.FinalAmountDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Final Amount Date"
        size="small"
        label="Final Amount Date"
      />
      <Field
        name={CaseDetailsKeys.FinalRecoveryRateCalc}
        as={TextField}
        variant="outlined"
        placeholder="Recovery Rate Calc"
        size="small"
        label="Recovery Rate Calc"
        disabled
      />
    </FormFieldsGroup>
  );
};
