import { http } from "src/axios";
import {
  CommentAttachment,
  CommentData,
  ServerAttachment,
  ServerComment,
  ServerGetCommentsResponse,
  ServerResponse,
} from "src/types/comments";

function mapServerAttachmentToLocalAttachment(serverAttachment: ServerAttachment): CommentAttachment {
  return {
    id: serverAttachment.ID,
    name: serverAttachment.FILE_NAME,
    url: serverAttachment.OBJECT_KEY,
    type: serverAttachment.TYPE,
  };
}

function mapServerCommentToLocalComment(serverComment: ServerComment): CommentData {
  return {
    id: serverComment.ID,
    comment: serverComment.COMMENT,
    isPinned: serverComment.IS_PINNED,
    createdAt: serverComment.CREATED_AT,
    attachment: serverComment.ATTACHMENT ? mapServerAttachmentToLocalAttachment(serverComment.ATTACHMENT) : null,
    userData: {
      name: serverComment.CREATED_BY.NICKNAME,
      picture: serverComment.CREATED_BY.PROFILE_PIC,
    },
  };
}

export async function postModelComment(
  modelType: string,
  modelId: string,
  comment: string,
  attachment: File | null,
): Promise<boolean> {
  const url = `/api/v2/${modelType}/${modelId}/comments`;
  const body = new FormData();
  body.append("comment", comment);
  if (attachment) {
    body.append("file", attachment);
  }
  const response = await http.post<ServerResponse>(url, body, {
    errorMessage: "An error occurred while trying to post a comment",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
}

export async function getModelComments(modelType: string, modelId: string): Promise<CommentData[]> {
  const url = `/api/v2/${modelType}/${modelId}/comments`;
  const response = await http.get<ServerGetCommentsResponse>(url, {
    errorMessage: "An error occurred while fetching comments",
  });
  return response.data.comments.map(modelComment => mapServerCommentToLocalComment(modelComment.COMMENT));
}

export async function patchIsPinned(commentId: string, newIsPinned: boolean): Promise<ServerResponse> {
  const url = `/api/v2/comments/${commentId}`;
  const response = await http.patch<ServerResponse>(
    url,
    {
      is_pinned: newIsPinned,
    },
    {
      errorMessage: "An error occurred while trying to toggle pin on a comment",
    },
  );
  return response.data;
}

export async function getAttachmentLink(attachmentId: string): Promise<string> {
  const url = `/api/v2/attachments/${attachmentId}`;
  const response = await http.get<{ attachment: { url: string } }>(url, {
    errorMessage: "An error occurred while trying to fetch attachment",
  });
  return response.data.attachment.url;
}
