import { useState, useEffect, FC } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import AuditServices from "../../../Services/AuditsServices";
import { AutocompleteField, SelectField } from "components/UI/Form";

interface Option {
  label: string;
  title?: string;
  value: any;
}

interface AuditInput {
  sh_years: Number;
  cb_years: Number;
  login_account: string;
  marketplace: string;
  store: string;
  coop_years: Number;
  po_years: Number;
}

const timeFrameOptions: Option[] = [
  { label: "0 Years", value: 0 },
  { label: "1 Year", value: 1 },
  { label: "2 Years", value: 2 },
  { label: "3 Years", value: 3 },
  { label: "5 Years", value: 5 },
];

const initialValues: AuditInput = {
  sh_years: 5,
  cb_years: 1,
  login_account: "lucy",
  marketplace: "us",
  store: "",
  coop_years: 2,
  po_years: 3,
};

const validationSchema = Yup.object().shape({
  cb_years: Yup.string().required("Chargeback timeframe is required"),
  login_account: Yup.string().required("VC account user is required"),
  marketplace: Yup.string().required("VC market is required"),
  store: Yup.string().required("Prospect name is required"),
  coop_years: Yup.string().required("Accrual timeframe is required"),
});

const CreateAudit: FC<{
  onAuditCreated: () => void;
}> = ({ onAuditCreated }) => {
  const [auditAccounts, setAuditAccounts] = useState<Option[]>([]);
  const [auditMarket, setAuditMarket] = useState<Option[]>([]);
  const [clientName, setClientName] = useState<Option[]>([]);
  const [lastRefresh, setLastRefresh] = useState<string | null>(null);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchAuditAccounts = async () => {
    try {
      setOptionsLoading(true);
      const response = await AuditServices.GetAuditAccounts();

      if (response.status !== 200) {
        throw new Error("Error while fetching audit accounts");
      }

      setAuditAccounts(
        response.data.accounts.map((val: [string]) => ({
          title: val[0],
          value: val[0],
        }))
      );

      /* Remove duplicate marketplaces */
      const uniqueMarketplaces = response.data.marketplaces.reduce(
        (acc: string[], val: [string]) => {
          if (!acc.includes(val[0])) {
            acc.push(val[0]);
          }
          return acc;
        },
        []
      );

      setAuditMarket(
        uniqueMarketplaces.map((val: string) => ({
          title: val,
          value: val,
        }))
      );
    } catch (error) {
      toast.error("Error fetching audit accounts");
    } finally {
      setOptionsLoading(false);
    }
  };

  const fetchClientNames = async (account: string, market: string) => {
    try {
      setOptionsLoading(true);
      const response = await AuditServices.GetAuditVCName(account);

      if (response.status !== 200) {
        throw new Error("Error while fetching client names");
      }

      setLastRefresh(response.data.last_refresh);

      const marketPlace = market === "uk" ? "gb" : market;
      const reg = new RegExp(`^${marketPlace}`, "i");

      const filteredData = response.data.data.filter((val: string) =>
        reg.test(val)
      );

      if (filteredData.length === 0) {
        toast.error("No Store exists for this market");
        setClientName([]);
        return;
      }

      setClientName(
        filteredData.map((val: string) => ({ title: val, value: val }))
      );
    } catch (error) {
      toast.error("Error fetching client names");
    } finally {
      setOptionsLoading(false);
    }
  };

  const handleCreate = async (values: AuditInput) => {
    try {
      setLoading(true);
      const response = await AuditServices.CreateAudit(values);

      if (response.status !== 200) {
        throw new Error("Error creating audit");
      }

      toast.success(response.data.message);
      onAuditCreated();
    } catch (error) {
      console.error("Error creating audit:", error);
      toast.error("Error creating audit");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditAccounts();
  }, []);

  useEffect(() => {
    if (initialValues.marketplace && initialValues.login_account) {
      fetchClientNames(initialValues.login_account, initialValues.marketplace);
    }
  }, [initialValues.login_account, initialValues.marketplace]);

  return (
    <Box pb={4}>
      <Typography variant="h6" mb={3}>
        Create Audit
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreate}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
                display="grid"
                gap={2}
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                  },
                }}
            >
              <Field
                  name="sh_years"
                  component={SelectField}
                  placeholder="Select Shortage timeframe"
                  label="Shortage timeframe"
                  disabled
                  options={timeFrameOptions}
              />

              <Field
                  name="cb_years"
                  component={SelectField}
                  placeholder="Select Chargeback timeframe"
                  label="Chargeback timeframe"
                  required
                  options={timeFrameOptions.slice(0, 2)}
                  error={touched.cb_years && Boolean(errors.cb_years)}
                  helperText={touched.cb_years && errors.cb_years}
              />

              <Field
                  name="coop_years"
                  component={SelectField}
                  placeholder="Select Accrual timeframe"
                  label="Accrual timeframe"
                  required
                  options={timeFrameOptions.slice(1, 3)}
                  error={touched.coop_years && Boolean(errors.coop_years)}
                  helperText={touched.coop_years && errors.coop_years}
                  onChange={async (new_var: Number) => {
                    switch (new_var) {
                      case 1:
                        await setFieldValue("po_years", 2);
                        break;
                      case 2:
                        await setFieldValue("po_years", 3);
                        break;
                    }
                  }}
              />

              <Field
                  name="po_years"
                  component={SelectField}
                  placeholder="Select PO timeframe"
                  label="PO timeframe"
                  disabled
                  options={timeFrameOptions}
              />
            </Box>

            <Box
              display="grid"
              gap={2}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                },
              }}
              mt={3}
            >
              <Box>
                <Field
                  name="login_account"
                  component={AutocompleteField}
                  placeholder="Select VC account user"
                  label="VC account user"
                  required
                  options={auditAccounts}
                  defaultValue={{ label: "lucy", value: "lucy" }}
                  getOptionLabel={(option: Option) => option.title}
                  setFieldValue={setFieldValue}
                  onChange={(option: Option) => {
                    fetchClientNames(option.value, values.marketplace || "us");
                  }}
                  value={values.login_account}
                  error={touched.login_account && Boolean(errors.login_account)}
                  helperText={touched.login_account && errors.login_account}
                  disabled={optionsLoading}
                />
              </Box>

              <Box>
                <Field
                  name="marketplace"
                  component={AutocompleteField}
                  placeholder="Select VC market"
                  label="VC market"
                  required
                  options={auditMarket}
                  defaultValue={{ title: "us", value: "us" }}
                  getOptionLabel={(option: Option) => option.title}
                  setFieldValue={setFieldValue}
                  onChange={(option: Option) => {
                    fetchClientNames(
                      values.login_account || "lucy",
                      option.value
                    );
                  }}
                  value={values.marketplace}
                  error={touched.marketplace && Boolean(errors.marketplace)}
                  helperText={touched.marketplace && errors.marketplace}
                  disabled={optionsLoading}
                />
              </Box>

              <Box>
                <Box display="flex" alignItems="center" gap={2}>
                  {optionsLoading && (
                    <CircularProgress size={16} sx={{ ml: 1, color: "#999" }} />
                  )}

                  <Field
                    name="store"
                    component={AutocompleteField}
                    placeholder="Select Prospect name"
                    label="Prospect name"
                    required
                    getOptionLabel={(option: Option) => option.title}
                    options={clientName}
                    setFieldValue={setFieldValue}
                    value={values.store}
                    error={touched.store && Boolean(errors.store)}
                    helperText={touched.store && errors.store}
                    disabled={optionsLoading}
                  />
                </Box>
                <Typography
                  variant="body2"
                  pt={0.5}
                  sx={{ fontSize: "0.75rem", textAlign: "right" }}
                >
                  Last Updated: {lastRefresh}
                </Typography>
              </Box>
            </Box>

            <Box mt={3} display="flex" justifyContent="flex-end" gap="12px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || !dirty || loading}
              >
                <span>Start Processing</span>
                {loading && (
                  <CircularProgress size={16} sx={{ ml: 1, color: "#fff" }} />
                )}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateAudit;
