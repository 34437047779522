import { QueryFunctionContext, queryOptions, useQuery } from "@tanstack/react-query";
import { CommentData } from "src/types/comments";
import { getModelComments } from "../api/commentsApi";

export type ListModelCommentsKey = ["comments", modelType: string, modelId: string, "list"];

const listModelCommentsByModelIdQuery = async ({
  queryKey: [, modelType, modelId],
}: QueryFunctionContext<ListModelCommentsKey>): Promise<CommentData[]> => getModelComments(modelType, modelId);

export function useListModelCommentsQuery(queryKey: ListModelCommentsKey) {
  const listModelQueryOptions = queryOptions({
    queryKey,
    queryFn: listModelCommentsByModelIdQuery,
  });

  return useQuery(listModelQueryOptions);
}
