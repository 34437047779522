import { FC } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { DataGridPro } from "@mui/x-data-grid-pro";
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { attempt } from "src/utils/attempt";
import {
  type WorkPacket,
  type WorkPacketActionName,
  WorkPacketFilter,
  WorkPacketsSummaryByStatus,
  WorkPacketSummaryFilters,
} from "src/types/work-packets";
import { AutocompleteField, StringDateRangePickerField } from "components/UI/Form";
import { Field, Form, useFormikContext } from "formik";
import { getActionObject } from "src/pages/UserDashboard/WorkPackets/mappers";
import { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";
import { useWorkPacketsContext } from "src/pages/UserDashboard/WorkPackets/WorkPacketsContext";
import { useAccountOptions } from "../WorkPacketsGrid/filters/hooks/useAccountOptions";

const renderCurrencyCell = (params: GridRenderCellParams<any, string>) =>
  attempt(
    () =>
      params.value ? Number.parseFloat(params.value).toLocaleString("en", { style: "currency", currency: "USD" }) : "",
    params.value,
    "Error parsing currency or rendering currency cell",
  );

const currentActionDisplay = (params: GridRenderCellParams<WorkPacket, WorkPacketActionName>) => {
  if (!params.value) return "";
  const { color, title } = getActionObject(params.value, WorkPacketType.SHORTAGES);

  return (
    <Box display="flex" alignItems="center">
      {!!color && <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />}
      <Box ml={1}>{title}</Box>
    </Box>
  );
};

const areFiltersEqual = (
  currentFilters: Partial<WorkPacketSummaryFilters>,
  nextFilters: Partial<WorkPacketSummaryFilters>,
): boolean =>
  Object.entries(nextFilters).every(([filterKey, newValue]) => {
    const previousValue = currentFilters[filterKey as keyof WorkPacketSummaryFilters];
    if (Array.isArray(previousValue) && Array.isArray(newValue)) {
      if (previousValue.length !== newValue.length) return false;
      return previousValue.every((previousArrayEntry, index) => previousArrayEntry === newValue[index]);
    }
    return previousValue === newValue;
  });

const columns: Partial<Record<WorkPacketType, GridColDef[]>> = {
  [WorkPacketType.SHORTAGES]: [
    {
      field: "status",
      headerName: "Pre-Escalation Status",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: currentActionDisplay,
    },
    {
      field: "count",
      headerName: "Work Packet Counts",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "remainingOpenBalance",
      headerName: "Remaining Open Balance",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: renderCurrencyCell,
    },
  ],
  [WorkPacketType.CHARGEBACKS]: [
    {
      field: "status",
      headerName: "Pre-Escalation Status",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: currentActionDisplay,
    },
    {
      field: "count",
      headerName: "Work Packet Counts",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "financialCharge",
      headerName: "Financial Charge",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: renderCurrencyCell,
    },
    {
      field: "chargebackCreateDate",
      headerName: "Chargeback Create Date",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
  ],
};


interface RecoverableSummaryProps {
  rows: WorkPacketsSummaryByStatus[];

  loading: boolean;
}

export const SummaryByStatus: FC<RecoverableSummaryProps> = ({
  rows,
  loading,
}) => {
  const { currentWorkPacketType } = useWorkPacketsContext();
  const { initialValues, values, setFieldValue } = useFormikContext<WorkPacketSummaryFilters>();
  const accountOptions = useAccountOptions();

  return (
    <Box
      boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
      paddingX={3}
      paddingY={3}
      borderRadius={2}
      border="1px solid #D0D5DD"
    >
      <Form>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} gap={4}>
          <Box gap={2} display="grid" gridTemplateColumns="repeat(auto-fit, minmax(16rem, 1fr))" flexGrow={1}>
            <Field
              name={WorkPacketFilter.StoreName}
              component={AutocompleteField}
              label="Store Name"
              options={accountOptions}
              getOptionLabel={(option: any) => option.title}
              placeholder="Store Name"
              setFieldValue={setFieldValue}
            />

            {currentWorkPacketType == WorkPacketType.SHORTAGES && <Field
              name={WorkPacketFilter.DisputeCreatedAt}
              component={StringDateRangePickerField}
              label="Dispute Created At"
            />}

            {currentWorkPacketType == WorkPacketType.CHARGEBACKS && <Field
              name={WorkPacketFilter.ChargebackCreatedAt}
              component={StringDateRangePickerField}
              label="Chargeback Create Date"
            />}
          </Box>

          <Box display="flex">
            <Button
              type="submit"
              variant="contained"
              disabled={areFiltersEqual(initialValues, values) || loading}
              sx={{ paddingX: "24px" }}
            >
              <span>Apply</span>
              {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
            </Button>
          </Box>
        </Box>
      </Form>

      <DataGridPro
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: "white",
          },
          '& .MuiDataGrid-columnHeader *': {
            color: "#667085",
          },
        }}
        loading={loading}
        rows={rows ?? EMPTY_ARRAY}
        getRowId={row => row.status}
        columns={columns[currentWorkPacketType] ?? []}
        autoHeight
        hideFooter
        disableColumnFilter
        disableColumnMenu
        disableColumnResize
      />
    </Box>
  );
};
