import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { type AccountStore, getAccounts } from "src/queries/accounts";
import { EMPTY_ARRAY } from "src/utils/empty-values";

export enum AccountValueField {
  AccountId = "accountId",
  AccountName = "accountName",
}

const accountValueGetters: Record<AccountValueField, (account: AccountStore) => string> = {
  [AccountValueField.AccountId]: account => account["Account ID"].toString(),
  [AccountValueField.AccountName]: account => account["Account Config Vendor Name"] ?? account["Account Name"],
};

export const useAccountOptions = (valueField: AccountValueField = AccountValueField.AccountName) => {
  const { data: accountList } = useQuery({
    queryKey: ["accounts"],
    queryFn: getAccounts,
    staleTime: 30000,
  });

  const accountOptions = useMemo(() => {
    if (!accountList) return EMPTY_ARRAY;
    const getValue = accountValueGetters[valueField];
    return accountList.stores.map(account => ({
      value: getValue(account),
      title: account["Account Name"].trim(),
      id: account["Account ID"],
    }));
  }, [accountList, valueField]);

  return accountOptions;
};
