import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup";
import { Box, Chip, Tooltip } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useFormikContext } from "formik";
import { CaseBase } from "src/types/cases";
import {
  CorrespondenceWorkFlag,
  correspondenceWorkFlagLabels,
} from "src/pages/UserDashboard/WorkPackets/Cases/correspondence-work-flags";
import { useMutation } from "@tanstack/react-query";
import { deleteCaseCorrespondenceWorkFlag } from "src/pages/UserDashboard/Cases/api/casesApi";
import toast from "react-hot-toast";
import { queryClient } from "src/query-client.ts";

interface UpdateMutationVars {
  caseId: string;
  flag: CorrespondenceWorkFlag;
}

const onWorkFlagRemovalSuccess = (_response: unknown, { caseId }: UpdateMutationVars) => {
  toast.success("Work Flag removed");
  queryClient.invalidateQueries({ queryKey: ["cases", caseId, "details"] });
  queryClient.invalidateQueries({ queryKey: ["case-logs", caseId] });
};

export const CaseWorkFlags = () => {
  const { values, setFieldValue } = useFormikContext<CaseBase>();

  const mutation = useMutation({
    mutationKey: ["cases", values.id, "work-flag-update"],
    mutationFn: ({ caseId, flag }: UpdateMutationVars) => deleteCaseCorrespondenceWorkFlag(caseId, flag),
    onSuccess: onWorkFlagRemovalSuccess,
  });

  const onDelete = (flag: CorrespondenceWorkFlag) => {
    setFieldValue(
      "correspondenceWorkFlags",
      values.correspondenceWorkFlags?.filter(f => f !== flag),
    );
    return mutation.mutateAsync({ caseId: values.id, flag });
  };

  return (
    <FormFieldsGroup title="Work Flags" id={"work-flags"}>
      <Box sx={{ gridColumn: "1 / -1", display: "flex", flexWrap: "wrap", gap: 1 }}>
        {values.correspondenceWorkFlags && values.correspondenceWorkFlags.length > 0 ? (
          values.correspondenceWorkFlags.map(flag => (
            <Chip
              label={correspondenceWorkFlagLabels[flag] ?? flag}
              variant="outlined"
              onDelete={() => onDelete(flag)}
              deleteIcon={
                <Tooltip title="Delete work flag">
                  <TaskAltIcon sx={{ "&&": { color: "#F7104D" } }} />
                </Tooltip>
              }
            />
          ))
        ) : (
          <span>No correspondence work flags.</span>
        )}
      </Box>
    </FormFieldsGroup>
  );
};
