import { Box, Typography, Divider, IconButton } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";

export const FormFieldsGroup = ({
  title,
  withDivider,
  asSubsection,
  onRemove,
  children,
  id,
}: {
  title?: string;
  withDivider?: boolean;
  asSubsection?: boolean;
  onRemove?: () => void;
  children: React.ReactNode;
  id: string;
}) => {
  return (
    <Box id={id} style={asSubsection ? {gridColumn: "1 / -1"}:{}}>
      {title ? (
        <Box paddingBottom={2} paddingTop={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" color={asSubsection ? "#667085" : "#F7104D"}>
            {title}
          </Typography>
          {onRemove && (
            <IconButton onClick={onRemove}>
              <DeleteOutlineOutlined />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box paddingTop={2} />
      )}
      <Box
        display="grid"
        rowGap={3}
        columnGap={2}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          },
        }}
      >
        {children}
      </Box>

      {withDivider && <Divider sx={{ mt: title ? 4 : 2 }} />}
    </Box>
  );
};
