import { QueryClient, useMutation } from "@tanstack/react-query";
import { CommentData, NewCommentFormikValues, UserData } from "src/types/comments";
import { MutationContext } from "./usePatchIsPinned";
import { postModelComment } from "../api/commentsApi";
import { ListModelCommentsKey } from "./useListModelComments";

export function usePostModelCommentMutation(
  modelType: string,
  modelId: string,
  userData: UserData,
  listCommentQueryKey: ListModelCommentsKey,
  queryClient: QueryClient,
) {
  return useMutation<boolean, unknown, NewCommentFormikValues, MutationContext>({
    mutationFn: ({ newComment, attachment }) => {
      return postModelComment(modelType, modelId, newComment, attachment);
    },
    onMutate: async variables => {
      // Cancel any outgoing queries to avoid conflicts
      await queryClient.cancelQueries({ queryKey: listCommentQueryKey });
      // Snapshot the previous value
      const prevComments = queryClient.getQueryData<CommentData[]>(listCommentQueryKey);

      // Optimistically update the query data
      queryClient.setQueryData(listCommentQueryKey, (old: CommentData[]) => {
        return [
          ...old,
          {
            id: Math.random().toString(),
            comment: variables.newComment,
            createdAt: new Date().toISOString(),
            isPinned: false,
            attachment: variables.attachment,
            userData,
          },
        ];
      });

      // Return a context object with the snapshot value for rollback
      return { prevComments: prevComments ?? [] };
    },
    onError: (err, _variables, context) => {
      console.log("Error during postCommentMutation");
      console.error(err);
      // Roll back to the previous value if the mutation fails
      if (context?.prevComments) {
        queryClient.setQueryData(listCommentQueryKey, context.prevComments);
      }
    },
    onSuccess: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: listCommentQueryKey });
    },
  });
}
