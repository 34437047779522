import { Box } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import {
  AutocompleteField,
  CheckboxField,
  MultiAutocompleteField,
  StringDateRangePickerField
} from "src/components/UI/Form";
import { StagelessAction, WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { createStagelessAction } from "../../mappers";
import { useUserNickname } from "../../useUserNickname";
import { useUserOptions } from "../../useUserOptions";
import { filterableWorkPacketActions } from "../helpers/getStaticFilterOptions";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useAuditAccountOptions } from "./hooks/useAuditAccountOptions";
import { useVendorOptions } from "./hooks/userVendorOptions";
import { useAccountOptions } from "./hooks/useAccountOptions";
import { EMPTY_ARRAY } from "src/utils/empty-values";

const recoveryStreamOptions = [
  { value: "PPV", title: "PPV" },
  { value: "PQV", title: "PQV" },
];

export const ShortageFiltersForm: FC = () => {
  const { currentFilters } = useWorkPacketsContext();
  const isPreCase = !!currentFilters[WorkPacketFilter.PreCase];
  if (isPreCase) {
    return <PreCaseShortageFilters />;
  } else {
    return <NormalShortageFiltersForm />;
  }
};

const NormalShortageFiltersForm: FC = () => {
  const { setFieldValue } = useFormikContext();
  const userNickname = useUserNickname();
  const { currentView, currentWorkPacketType } = useWorkPacketsContext();

  const userOptions = useUserOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const accountOptions = useAccountOptions();
  const vendorOptions = useVendorOptions();

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
        gap: 2,
        rowGap: 3,
      }}
    >
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={accountOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={filterableWorkPacketActions[WorkPacketType.SHORTAGES]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.RecoveryStream}
        component={AutocompleteField}
        label="Recovery Stream"
        options={recoveryStreamOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.ParentInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Parent Invoice ID"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.DisputedInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Disupted Invoice ID"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Box display="none" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={WorkPacketFilter.DisputeCreatedAt}
            component={StringDateRangePickerField}
            label="Dispute - Created At"
          />
        </Box>
        <Field type="checkbox" name={WorkPacketFilter.RedFlags} component={CheckboxField} label="Red flags" />
      </Box>
    </Box>
  );
};

const preCaseActions = [
  createStagelessAction(StagelessAction.ReadyForReview),
  createStagelessAction(StagelessAction.NotPursuing),
];

const PreCaseShortageFilters: FC = () => {
  const { currentWorkPacketType, currentView } = useWorkPacketsContext();
  const userOptions = useUserOptions();
  const vendorOptions = useVendorOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const userNickname = useUserNickname();
  const accountOptions = useAccountOptions();
  const { setFieldValue } = useFormikContext();

  return (
    <Box display="grid" sx={{ gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(3, 1fr)" }, gap: 2, rowGap: 3 }}>
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={accountOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={preCaseActions}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.DisputeCreatedAt}
        component={StringDateRangePickerField}
        label="Dispute - Created At"
      />

      <Field
        name={WorkPacketFilter.ParentInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Parent Invoice ID"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.DisputedInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Disupted Invoice ID"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.InvoiceDueDate}
        component={StringDateRangePickerField}
        label="Invoice Due Date"
      />

      <Field
        name={WorkPacketFilter.NumberOfEscalations}
        component={AutocompleteField}
        label="# Of Escalations"
        options={[
          { value: "ZERO", title: "Zero" },
          { value: "MORE_THAN_ZERO", title: "More Than Zero" },
        ]}
        getOptionLabel={(option: any) => option.title}
      />
    </Box>
  );
};
