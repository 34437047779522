import { http } from "src/axios";
import type { CommandCenterGridRow, ServerVendor } from "src/types/work-packets";
import { parseDateRange } from "src/utils/date-range";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import type { SortOption } from "../api/workPacketsAPI";
import { mapCommandCenterData } from "src/pages/UserDashboard/WorkPackets/mappers/mapServerCommandCenterData";
import { Case, CaseFields, CaseType } from "./case-types";
import { serverToLocalCasesMap } from "src/pages/UserDashboard/WorkPackets/mappers/mapServerResponseToCases";
import type { ServerResponse } from "src/pages/UserDashboard/Cases/api/types.ts";
import {
  ShortagesEscalationCaseItemStatus,
  ShortagesSettlementsCaseStage,
} from "src/pages/UserDashboard/Cases/api/constants.ts";
import { ServerUser } from "src/types/user";

// TODO: make this a subset of CaseFields
export const ServerCaseFilters = {
  AmazonCaseId: "amazonCaseId", // no options
  CurrentCaseOwner: "caseOwnerId", // useUserOptions
  CurrentAction: "currentAction", // [Waiting on Amazon, Waiting on CG, Approved, Partially Approved, Denied, Closed w/o Resolution]
  CreatedAt: "createdAt", // date
  VendorName: "vendorName", // useVendorOptions
  StoreName: "storeName", // useAccountOptions
  PresubmissionRequired: "preSubmissionRequired",
  Processor: "processor",
  SubmissionPath: "submissionPath",
  Stage: "stage",
  SprinterViewedAt: "sprinterViewedAt",
  Sprinter: "sprinter",
  SubmissionAmount: "submissionAmount",
  SubmissionDate: "submissionDate",
  RedFlags: "redFlags",
  ManualFilingUser: "manualFilingUser",
  CorrespondenceWorkFlags: "correspondenceWorkFlags",
} as const;

export type ServerCaseFilters = {
  [ServerCaseFilters.AmazonCaseId]: string;
  [ServerCaseFilters.CurrentAction]: string;
  [ServerCaseFilters.CurrentCaseOwner]: string;
  [ServerCaseFilters.CreatedAt]: string;
  [ServerCaseFilters.VendorName]: string;
  [ServerCaseFilters.StoreName]: string;
  [ServerCaseFilters.PresubmissionRequired]: string;
  [ServerCaseFilters.Processor]: string;
  [ServerCaseFilters.SubmissionPath]: string;
  [ServerCaseFilters.Stage]: string;
  [ServerCaseFilters.SprinterViewedAt]: string;
  [ServerCaseFilters.Sprinter]: string;
  [ServerCaseFilters.SubmissionAmount]: string[];
  [ServerCaseFilters.SubmissionDate]: string;
  [ServerCaseFilters.RedFlags]: string[];
  [ServerCaseFilters.ManualFilingUser]: string;
  [ServerCaseFilters.CorrespondenceWorkFlags]: string[];
};

export type ServerFilterKeys = keyof typeof ServerCaseFilters;
export type ServerFilterValues = (typeof ServerCaseFilters)[keyof typeof ServerCaseFilters];

type ServerAccount = {
  ACTIVATION_DATE: string; // Date;
  ID: number;
  SCOPES: unknown[];
  SHORTAGE_LAG: string;
  STORE_NAME: string;
  TECH_USER: string | null;
  VC_FILING_USER: string | null;
  VENDOR: ServerVendor;
  VENDOR_ID: string;
};

export type ServerCase = {
  ACCOUNT: ServerAccount | null;
  ACCOUNT_ID: unknown;
  AMAZON_CASE_ID: string;
  CASE_OWNER: ServerUser | null;
  CASE_OWNER_ID: string | null; // auth0Id
  CREATED_AT: string; // Date;
  AMAZON_CASE_CREATED_DATE: string; // Date;
  ID: string;
  NOTES: unknown | null;
  NO_OF_ITEMS: number;
  PAID_AMOUNT: number;
  REMAINING_OPEN_BALANCE: number;
  STATUS: string; // TODO: update with enum value
  TYPE: CaseType;
  INVOICE_START_DUE_DATE: string | null;
  INVOICE_END_DUE_DATE: string | null;
  PAYEE: string | null;
  ESCALATION_APPROVED_AMOUNT: number | null;
  SHORTAGES_SETTLEMENT_CASE_DETAILS: ServerShortageSettlementCaseDetails | null;
  SHORTAGES_ESCALATION_CASE_DETAILS: ServerShortageEscalationCaseDetails | null;
  CHARGEBACKS_ESCALATION_CASE_DETAILS: ServerChargebackEscalationCaseDetails | null;
  SHORTAGES_SETTLEMENT_OFFERS: {
    CASE_ID: string;
    CREATED_AT: string;
    ID: string;
    OFFER_AMOUNT?: number;
    OFFER_DATE?: string;
  }[];
  PAYMENT_DATES: {
    INVOICE_DATE_END: string;
    INVOICE_DATE_START: string;
    INVOICE_PAYMENT_DUE_DATE_END: string;
    INVOICE_PAYMENT_DUE_DATE_START: string;
  };
  CURRENT_RR_CALC: number;
  FINAL_RR_CALC: number;
  DISPUTED_AMOUNT: number;
  LAST_MODIFIED_BY: string | null;
  ITEMS: {
    CASE_ID: string;
    CASE_ITEM_ID: string;
    CREATED_AT: string;
    ID: string;
    SHORTAGES_ESCALATION_CASE_ITEM: null;
    SHORTAGES_ESCALATION_PAYMENT_DETAILS: [];
    SHORTAGES_OSSR_WORK_PACKET: {
      CASE_ITEM_ID: string;
      CREATED_AT: string;
      DISPUTED_INVOICE_ID: string;
      ID: string;
      PARENT_INVOICE_DATE: string;
      PARENT_INVOICE_PAYMENT_DUE_DATE: string;
      PAYEE: string;
      REMAINING_BALANCE_OPEN: number;
      SHORTAGE: {
        DUE_DATE: string;
        ID: string;
        INVOICE_AMOUNT: number;
        INVOICE_CREATE_DATE: string;
        INVOICE_DATE: string;
        INVOICE_ID: string;
        INVOICE_STATUS: string;
        PAID_AMOUNT: null;
        PAYEE: string;
        SHORTAGE_AMOUNT: null;
        STORE_NAME: string;
        VENDOR: string;
      };
      SHORTAGE_ID: string;
      VENDOR: string;
    };
    SHORTAGES_SETTLEMENT_PAYMENT_DETAIL: null;
    WORK_PACKET: null;
    WORK_PACKET_ID: "";
  }[];

};

export type ServerShortageSettlementCaseDetails = {
  ID: string;
  CASE_ID: string;
  SPRINTER: string | null;
  SPRINTER_VIEWED_AT: string | null;
  PROCESSOR: string | null;
  PROCESSOR_VIEWED_AT: string | null;
  OPS_EXTERNAL_COMMUNICATION: boolean | null;
  SUBMISSION_PATH: string | null;
  STAGE: string | null;
  CC_USER: string | null;
  PREVIOUS_CASE_IDS: string | null;
  AMAZON_LAST_RESPONDED_AT: string | null;
  LAST_RESPONDED_AT: string | null;
  AMAZON_CASE_MANAGER: string | null;
  PRESUBMISSION_REQUIRED: boolean | null;
  BUSINESS: string | null;
  VENDOR_CODES: string | null;
  DIRECT_IMPORT: boolean | null;
  CATEGORY: string | null;
  SETTLEMENT_COUNT: number | null;
  ALIGNED_BALANCE_AMOUNT: number | null;
  ALIGNED_BALANCE_DATE: string | null;
  RCA_ENDED_DATE: string | null;
  PAID_AMOUNT_DURING_OPEN_SHORTAGE: number | null;
  CURRENT_AMOUNT: number | null;
  VALID_AMOUNT: number | null;
  SETTLEMENT_ACCEPTED_AT: string | null;
  FINAL_AMOUNT: number | null;
  FINAL_AMOUNT_RECEIVED_AT: string | null;
};

export type ServerShortageEscalationCaseDetails = {
  ID: string;
  CASE_ID: string;
  APPROVED_AMOUNT: number | null;
  APPROVED_DATE: string | null;
  CREATED_AT: string | null;
  MONITORED_DATE: string | null;
};

export type ServerChargebackEscalationCaseDetails = {
  ID: string;
  CASE_ID: string;
  APPROVED_AMOUNT: number | null;
  APPROVED_DATE: string | null;
  CREATED_AT: string | null;
  MONITORED_DATE: string | null;
};

const computeFilters = (filters: ServerCaseFilters) => {
  const serverCaseFilters = Object.values(ServerCaseFilters);
  const computedFilters: Record<string, any> = Object.fromEntries(
    Object.entries(filters).filter(([key, val]) => {
      if (Array.isArray(val) && !val.length) return false;
      if (val === "") return false;
      return serverCaseFilters.includes(key as ServerFilterValues);
    }),
  );

  for (const dateRangeFilterName of [
    ServerCaseFilters.CreatedAt,
    ServerCaseFilters.SprinterViewedAt,
    ServerCaseFilters.SubmissionDate,
  ]) {
    if (dateRangeFilterName in computedFilters) {
      const dateRange = parseDateRange(computedFilters[dateRangeFilterName]);
      computedFilters[dateRangeFilterName] = dateRange?.map(date => date.format("YYYY-MM-DD")) ?? "";
    }
  }

  return computedFilters;
};

export const ServerCaseSorting = {
  [CaseFields.AmazonCaseId]: "amazonCaseId",
  [CaseFields.CurrentCaseOwner]: "caseOwnerId",
  [CaseFields.CurrentAction]: "currentAction",
  [CaseFields.CreatedAt]: "createdAt",
  [CaseFields.VendorName]: "vendorName",
  [CaseFields.StoreName]: "storeName",
  [CaseFields.ManualFilingUser]: "manualFilingUser",
  [CaseFields.NumOfPackets]: "numberOfPackets",
  [CaseFields.RemainingOpenBalance]: "remainingOpenBalance",
} as const;
export type ServerCaseSortingKey = keyof typeof ServerCaseSorting;

function computeSortOption(sorting?: SortOption): null | SortOption {
  if (!sorting || !(sorting.key in ServerCaseSorting)) return null;
  return {
    key: ServerCaseSorting[sorting.key as ServerCaseSortingKey],
    direction: sorting.direction,
  };
}

export interface FetchCasesArgs {
  caseType: CaseType;
  page: number;
  pageSize: number;
  filters: ServerCaseFilters;
  sorting?: SortOption;
}

export interface FetchCasesResponse {
  cases: Case[];
  totalRecords: number;
  totalPages: number;
}

export async function fetchCases(
  { caseType, page, pageSize, filters, sorting }: FetchCasesArgs,
  signal: AbortSignal,
): Promise<FetchCasesResponse> {
  const computedSortOption = computeSortOption(sorting);
  const computedFilters = computeFilters(filters);

  const response = await http.get("/api/v2/cases", {
    signal,
    params: {
      page: page + 1,
      per_page: pageSize,
      filters: JSON.stringify(computedFilters),
      sorting: JSON.stringify(
        computedSortOption ? { [computedSortOption.key]: computedSortOption.direction } : EMPTY_OBJECT,
      ),
      case_type: caseType,
    },
    errorMessage: "Error while fetching Cases",
  });

  return {
    cases: serverToLocalCasesMap[caseType](response.data.data.cases),
    totalRecords: response.data.data.total_records,
    totalPages: response.data.data.pages,
  };
}

const commandCenterUrlMap: Record<CaseType, string> = {
  [CaseType.CHARGEBACK_ESCALATION]: "/api/v2/work_packets/chargebacks/command_centre",
  [CaseType.SHORTAGE_ESCALATION]: "/api/v2/cases/shortages/SHORTAGE_ESCALATION/command_centre",
  [CaseType.SHORTAGE_SETTLEMENT]: "/api/v2/cases/shortages/SHORTAGE_SETTLEMENT/command_centre",
};

export async function fetchCommandCenterData(
  caseType: CaseType,
  signal?: AbortSignal,
): Promise<CommandCenterGridRow[]> {
  const response = await http.get(commandCenterUrlMap[caseType], {
    signal,
    errorMessage: "Error while fetching command centre data.",
  });

  const data = mapCommandCenterData(response.data);
  // backend when converts json it does not keep insertion order and sort it based on alpha order so reversing it to keep Done at bottom of list
  if (caseType == CaseType.SHORTAGE_SETTLEMENT) {
    return data.reverse();
  }
  return data;
}

export interface CasesSummaryRecoverableAmount {
  approved: number;
  pending: number;
  total: number;
  denied: number;
}

interface ServerSettlementCasesStatusSummary {
  STATUS: string;
  CASES_COUNT: number;
  AMOUNT: number;
  INVOICE_DUE_DATE: string;
  C6_INVOICED_DATE: string;
}

export enum SettlementCasesSummaryKeys {
  Status = "status",
  CasesCount = "casesCount",
  Amount = "amount",
  InvoiceDueDate = "invoiceDueDate",
  C6InvoicedDate = "c6InvoicedDate",
}

export interface SettlementCasesStatusSummary {
  [SettlementCasesSummaryKeys.Status]: ShortagesSettlementsCaseStage|ShortagesEscalationCaseItemStatus;
  [SettlementCasesSummaryKeys.CasesCount]: number;
  [SettlementCasesSummaryKeys.Amount]: number;
  [SettlementCasesSummaryKeys.InvoiceDueDate]: string;
  [SettlementCasesSummaryKeys.C6InvoicedDate]: string;
}

export interface FetchCasesSummaryResponse {
  data: {
    recoverable_amount: CasesSummaryRecoverableAmount;
  };
  message: string;
  status: number;
}

const casesSummaryUrlMap = {
  [CaseType.CHARGEBACK_ESCALATION]: "/api/v2/work_packets/chargebacks/summary/all", // TODO: update this once the endpoint is available
  [CaseType.SHORTAGE_ESCALATION]: "/api/v2/cases/shortages/SHORTAGE_ESCALATION/summary",
  [CaseType.SHORTAGE_SETTLEMENT]: "/api/v2/cases/shortages/SHORTAGE_SETTLEMENT/summary",
};

export async function fetchCasesSummary(
  caseType: CaseType,
  signal?: AbortSignal,
): Promise<CasesSummaryRecoverableAmount> {
  const response = await http.get<FetchCasesSummaryResponse>(casesSummaryUrlMap[caseType], {
    signal,
    errorMessage: "Error while fetching cases summary",
  });

  return response.data.data.recoverable_amount;
}

export enum SettlementCasesSummaryFilterKeys {
  AccountId = "accountId",
  InvoiceDueDate = "invoiceDueDate",
  C6InvoicedDate = "c6InvoicedDate",
}

export interface FetchSettlementsSummaryFilters {
  [SettlementCasesSummaryFilterKeys.AccountId]: string;
  [SettlementCasesSummaryFilterKeys.InvoiceDueDate]: Date | null;
  [SettlementCasesSummaryFilterKeys.C6InvoicedDate]: Date | null;
}

export interface FetchSettlementsSummaryArgs {
  filters?: FetchSettlementsSummaryFilters;
}

function settlementsSummaryMapper(summary: ServerSettlementCasesStatusSummary): SettlementCasesStatusSummary {
  return {
    [SettlementCasesSummaryKeys.Status]: summary.STATUS as ShortagesSettlementsCaseStage,
    [SettlementCasesSummaryKeys.CasesCount]: summary.CASES_COUNT,
    [SettlementCasesSummaryKeys.Amount]: summary.AMOUNT,
    [SettlementCasesSummaryKeys.InvoiceDueDate]: summary.INVOICE_DUE_DATE,
    [SettlementCasesSummaryKeys.C6InvoicedDate]: summary.C6_INVOICED_DATE,
  };
}

function computeSettlementsSummaryFilters(filters: FetchSettlementsSummaryFilters): Record<string, any> | null {
  const computed = Object.fromEntries(
    Object.entries({
      [SettlementCasesSummaryFilterKeys.AccountId]: filters[SettlementCasesSummaryFilterKeys.AccountId] || undefined,
      [SettlementCasesSummaryFilterKeys.InvoiceDueDate]: filters[SettlementCasesSummaryFilterKeys.InvoiceDueDate]?.toISOString(),
      [SettlementCasesSummaryFilterKeys.C6InvoicedDate]: filters.c6InvoicedDate?.toISOString(),
    }).filter(([_, value]) => value !== undefined),
  );

  if (!Object.keys(computed).length) {
    return null
  }

  return computed;
}

export async function fetchSettlementsSummaryByStatus(
  caseType: CaseType,
  { filters }: FetchSettlementsSummaryArgs,
  signal?: AbortSignal): Promise<SettlementCasesStatusSummary[]> {
  const params: Record<string, any> = {};
  const computedFilters = filters && computeSettlementsSummaryFilters(filters);
  if (computedFilters) {
    params.filters = JSON.stringify(computedFilters);
  }
  const response = await http.get<ServerResponse<ServerSettlementCasesStatusSummary[]>>(
    `/api/v2/cases/shortages/${caseType}/summary-by-status`,
    {
      signal,
      params,
      errorMessage: "Error while fetching settlements summary by status",
    },
  );

  return response.data.data.map(settlementsSummaryMapper);
}
