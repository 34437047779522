import {useAuth0} from "@auth0/auth0-react";
import {useContext, useEffect, useState} from "react";
import { AppContext } from "src/AppContext";
import { http } from "../../axios";
import Loader from "../Loader/Loader.jsx";
import useUsers from "../CustomHooks/useUsers";
import { useFeatureFlagClientInit } from "src/feature-flags";

export const AuthorisationGuard = ({children}) => {
  const {getAccessTokenSilently, isAuthenticated, user, logout} = useAuth0();
  const {setPermissions} = useContext(AppContext);
  const [isAuthing, setIsAuthing] = useState(false);
  const {postUser} = useUsers();

  const { isFeatureFlagClientReady } = useFeatureFlagClientInit();

  useEffect(() => {
    setIsAuthing(true);
    if (isAuthenticated) {
      http.setTokenGenerator(getAccessTokenSilently);
      http.setLogout(logout)
      updateUser();
    } else {
      setIsAuthing(false)
      setPermissions([])
      http.unsetTokenGenerator();
    }

  }, [isAuthenticated]);

  const showLoadingScreen = isAuthing || !isFeatureFlagClientReady;

  const updateUser = () => {
    postUser(user).then(response => {
      const permissions = response?.data?.user?.PERMISSIONS?.map((permission) => permission?.PERMISSION) || []
      setPermissions(permissions);
    }).then(() => {
      setIsAuthing(false);
    });
  };

  return showLoadingScreen ? <Loader/> : children;
};
