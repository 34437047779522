import { PaletteOptions } from "@mui/material";

export const palette: PaletteOptions = {
  primary: {
    // Grid header background, numbers in summary cards, black text
    main: "#101828",
    dark: "#101828",
    // CTA's, tabs
    light: "#F7104D",
    contrastText: "#fff",
  },
  secondary: {
    // Secondary text, e.g. summary card labels
    light: "#667085",
    main: "#f44336",
    dark: "#ba000d",
    contrastText: "#000",
  },
};

export const commentPalette = {
  pinned: "#98A2B3",
  unPinned: "#667085",
  timestamp: "#667085",
  commentBackground: "#F2F4F7",
  iconColor: "#667085",
  lightBorder: "#C7D0DC",
};
