import { InputAdornment, TextField } from "@mui/material";
import { isNaN } from "lodash";
import { FC } from "react";

export const CurrencyField: FC<{ value: number } & React.ComponentProps<typeof TextField>> = ({ value, ...props }) => {
  const renderValue = isNaN(value)
    ? undefined
    : value;

  return <TextField
    type="number"
    InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputProps: { step: 0.01 },
      }}
    value={renderValue}
    {...props} />;
};
