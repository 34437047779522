import { ArrowForwardRounded } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { FC, useContext } from "react";
import { WorkPacketPopupContext } from "src/pages/UserDashboard/WorkPackets/WorkPacketsGrid/WorkPacketPopupContext";
import { AiOutlineDelete } from "react-icons/ai";
import { CaseItemDeletePopupContext } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid/CaseItemDeletePopupContext.ts";
import { CaseItem } from "src/types/case-items.ts";

export const CaseItemsActionButtons: FC<GridRenderCellParams<{ packetId: string; id: string }>> = ({ row }) => {
  const setSelectedWorkPacketId = useContext(WorkPacketPopupContext);
  const setCaseItemID = useContext(CaseItemDeletePopupContext);
  return (
    <Box display="flex" gap="10px">
      <Button
        variant="outlined"
        endIcon={<ArrowForwardRounded />}
        onClick={() => setSelectedWorkPacketId(row.packetId)}
      >
        Details
      </Button>
      <Button
        variant="outlined"
        sx={{ width: "50px", minWidth: "50px" }}
        onClick={() => setCaseItemID(row as CaseItem)}
      >
        <AiOutlineDelete size={24} />
      </Button>
    </Box>
  );
};
