import {
  ChargebacksEscalationCaseItemDetails,
  ShortagesEscalationsPaymentDetails,
  ShortagesSettlementPaymentDetail,
} from "src/pages/UserDashboard/Cases/api/types.ts";
import {
  CaseItem,
  ChargebackEscalationCaseItem,
  ShortageEscalationCaseItem,
  ShortageSettlementCaseItem,
} from "src/types/case-items.ts";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types.ts";
import { ServerChargebackEscalationCaseDetails } from "src/pages/UserDashboard/WorkPackets/Cases/casesApi.ts";
import { CaseItemKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase.ts";
import { formatDateToISO } from "src/pages/UserDashboard/Cases/mappers/utils.ts";

export interface ChargebackEscalationCaseItemUpdateModel {
  CHARGEBACKS_ESCALATION_CASE_DETAILS: Partial<ServerChargebackEscalationCaseDetails>;
  CHARGEBACKS_ESCALATION_CASE_ITEM_DETAILS: Partial<ChargebacksEscalationCaseItemDetails>;
  WORK_PACKET_PROPERTIES: Partial<{
    WORK_PACKET_ID: string;
    ASN: string;
    ARN: string;
  }>;
}

export interface ShortageSettlementCaseUpdateModel {
  ID: string;
  WORK_PACKET_ID: string;
  SHORTAGES_SETTLEMENT_CASE_ITEM: {
    STATUS: string;
  };
  SHORTAGES_SETTLEMENT_PAYMENT_DETAILS: Partial<ShortagesSettlementPaymentDetail>;
}

export interface ShortageEscalationCaseItemUpdateModel {
  ID: string;
  WORK_PACKET_ID: string;
  SHORTAGES_ESCALATION_CASE_ITEM_DETAILS: Partial<{
    ID: string;
    STATUS: string;
    APPROVED_AMOUNT: number;
  }>;
  SHORTAGES_ESCALATION_CASE_DETAILS: Partial<{
    ID: string;
    APPROVED_AMOUNT: number;
  }>;
  SHORTAGES_OSSR_WORK_PACKETS: Partial<{
    ID: string;
    REMAINING_BALANCE_OPEN: number;
    PO_NUMBER: string;
  }>;
  WORK_PACKET_PROPERTIES: Partial<{
    WORK_PACKET_ID: string;
    ASN: string;
    ARN: string;
    DISPUTED_INVOICE_NUMBER: string;
  }>;
  SHORTAGES_ESCALATION_PAYMENT_DETAILS: Partial<ShortagesEscalationsPaymentDetails>;
}

export type CaseItemUpdateModel =
  | ShortageEscalationCaseItemUpdateModel
  | ChargebackEscalationCaseItemUpdateModel
  | ShortageSettlementCaseUpdateModel;

function shortageEscalationCaseItemMapper(
  caseItem: ShortageEscalationCaseItem,
  updates: Partial<ShortageEscalationCaseItem>,
): Partial<ShortageEscalationCaseItemUpdateModel> {
  const data: Partial<ShortageEscalationCaseItemUpdateModel> = {};

  if (updates[CaseItemKeys.CaseItemId]) {
    data.ID = updates[CaseItemKeys.CaseItemId];
  }
  if (updates[CaseItemKeys.WorkPacketId]) {
    data.WORK_PACKET_ID = updates[CaseItemKeys.WorkPacketId];
  }
  const SHORTAGES_ESCALATION_CASE_ITEM_DETAILS = {
    ...(CaseItemKeys.CaseItemStatus in updates && {
      STATUS: updates[CaseItemKeys.CaseItemStatus],
    }),
    ...(CaseItemKeys.ApprovedAmount in updates && {
      APPROVED_AMOUNT: updates[CaseItemKeys.ApprovedAmount],
    }),
  };
  if (Object.keys(SHORTAGES_ESCALATION_CASE_ITEM_DETAILS).length > 0) {
    data.SHORTAGES_ESCALATION_CASE_ITEM_DETAILS = {
      ID: caseItem[CaseItemKeys.ShortagesEscalationCaseItemDetailId],
      ...SHORTAGES_ESCALATION_CASE_ITEM_DETAILS,
    };
  }
  if (updates[CaseItemKeys.RemainingOpenBalance] || updates[CaseItemKeys.PoNumber]) {
    data.SHORTAGES_OSSR_WORK_PACKETS = {
      ID: caseItem[CaseItemKeys.OSSRWorkPacketId],
      REMAINING_BALANCE_OPEN: updates[CaseItemKeys.RemainingOpenBalance],
      PO_NUMBER: updates[CaseItemKeys.PoNumber],
    };
  }

  const workPacketProperties = {
    ASN: updates[CaseItemKeys.ASN],
    ARN: updates[CaseItemKeys.ARN],
    DISPUTED_INVOICE_NUMBER: updates[CaseItemKeys.VcDisputedInvoiceId],
  };
  if (Object.values(workPacketProperties).some(value => value !== undefined)) {
    data.WORK_PACKET_PROPERTIES = {
      WORK_PACKET_ID: caseItem[CaseItemKeys.WorkPacketId],
      ...workPacketProperties,
    };
  }
  const paymentDetails: Partial<ShortagesEscalationsPaymentDetails> = {
    INVOICE_NUMBER_FOR_PAYMENT: updates[CaseItemKeys.LastInvoiceNumberForPayment],
    PAYMENT_ID: updates[CaseItemKeys.LastInvoicePaymentId],
    CUSTOMER_INVOICE_AMOUNT: updates[CaseItemKeys.LastInvoiceClientAmount],
    PAYMENT_DATE: formatDateToISO(updates[CaseItemKeys.LastInvoicePaymentDate]),
    CUSTOMER_INVOICED_AT: formatDateToISO(updates[CaseItemKeys.LastInvoiceClientDate]),
  };

  if (Object.values(paymentDetails).some(value => value !== undefined)) {
    data.SHORTAGES_ESCALATION_PAYMENT_DETAILS = {
      ID: caseItem[CaseItemKeys.PaymentDetailsId],
      ...paymentDetails,
    };
  }

  Object.keys(data).forEach(key => {
    const typedKey = key as keyof ShortageEscalationCaseItemUpdateModel;
    if (
      data[typedKey] === undefined ||
      (typeof data[typedKey] === "object" && Object.keys(data[typedKey]).length === 0)
    ) {
      delete data[typedKey];
    }
  });

  return data;
}

export function chargebackEscalationCaseItemMapper(
  caseItem: ChargebackEscalationCaseItem,
  updates: Partial<ChargebackEscalationCaseItem>,
): Partial<ChargebackEscalationCaseItemUpdateModel> {
  const data: Partial<ChargebackEscalationCaseItemUpdateModel> = {};

  if (updates[CaseItemKeys.ApprovedAmount]) {
    data.CHARGEBACKS_ESCALATION_CASE_DETAILS = { APPROVED_AMOUNT: updates[CaseItemKeys.ApprovedAmount] };
  }

  if (updates[CaseItemKeys.CaseItemStatus]) {
    data.CHARGEBACKS_ESCALATION_CASE_ITEM_DETAILS = {
      ID: caseItem[CaseItemKeys.ChargebacksEscalationCaseItemDetailId],
      STATUS: updates[CaseItemKeys.CaseItemStatus],
    };
  }
  const workPacketProperties = {
    ASN: updates[CaseItemKeys.ASN],
    ARN: updates[CaseItemKeys.ARN],
  };
  if (Object.values(workPacketProperties).some(value => value !== undefined)) {
    data.WORK_PACKET_PROPERTIES = {
      WORK_PACKET_ID: caseItem[CaseItemKeys.WorkPacketId],
      ...workPacketProperties,
    };
  }

  return data;
}

export function shortageSettlementCaseItemMapper(
  _caseItem: ShortageSettlementCaseItem,
  _item: Partial<ShortageSettlementCaseItem>,
): Partial<ShortageSettlementCaseUpdateModel> {
  return {};
}

export const toServerMappers: Record<
  CaseType,
  (caseItem: any, item: Partial<CaseItem>) => Partial<CaseItemUpdateModel>
> = {
  [CaseType.SHORTAGE_ESCALATION]: shortageEscalationCaseItemMapper,
  [CaseType.CHARGEBACK_ESCALATION]: chargebackEscalationCaseItemMapper,
  [CaseType.SHORTAGE_SETTLEMENT]: shortageSettlementCaseItemMapper,
};
