import { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType.ts";
import { ServerFilterKeys } from "src/pages/UserDashboard/WorkPackets/Cases/casesApi.ts";

export enum CaseType {
  CHARGEBACK_ESCALATION = "CHARGEBACK_ESCALATION",
  SHORTAGE_ESCALATION = "SHORTAGE_ESCALATION",
  SHORTAGE_SETTLEMENT = "SHORTAGE_SETTLEMENT",
}

export const CaseWorkPacketTypeMap = {
  [CaseType.CHARGEBACK_ESCALATION]: WorkPacketType.CHARGEBACKS,
  [CaseType.SHORTAGE_ESCALATION]: WorkPacketType.SHORTAGES,
  [CaseType.SHORTAGE_SETTLEMENT]: WorkPacketType.SHORTAGES,
};

export const isValidCaseType = (value: string): value is CaseType => {
  return Object.values(CaseType).includes(value as CaseType);
};

export const CaseTypeLabelMap: Record<CaseType, string> = {
  [CaseType.CHARGEBACK_ESCALATION]: "Chargeback Escalation",
  [CaseType.SHORTAGE_ESCALATION]: "Shortage Escalation",
  [CaseType.SHORTAGE_SETTLEMENT]: "Shortage Settlement",
};

export interface Option {
  label?: string;
  title?: string;
  color?: string;
  value: any;
}

export const BaseCaseFields = {
  ChargeGuardCaseId: "chargeGuardCaseId",
  NumOfPackets: "numOfPackets",
  AmazonCaseId: "amazonCaseId",
  VendorName: "vendorName",
  StoreName: "storeName",
  CurrentCaseOwner: "currentCaseOwner",
  CurrentAction: "currentAction",
  CreatedAt: "createdAt",
  ManualFilingUser: "manualFilingUser",
  RemainingOpenBalance: "remainingOpenBalance",
  CurrentCaseNumber: "currentCaseNumber",
  InvoiceStartDueDate: "invoiceStartDueDate",
  InvoiceEndDueDate: "invoiceEndDueDate",
  Link: "link",
  Payee: "payee",
  SubmissionAmt: "submissionAmt",
  SubmissionDate: "submissionDate",
} as const;

export const SettlementCaseFields = {
  ...BaseCaseFields,
  PresubmissionRequired: "presubmissionRequired",
  Business: "business",
  Sprinter: "sprinter",
  SprinterViewedAt: "sprinterViewedAt",
  Processor: "processor",
  ProcessorViewedAt: "processorViewedAt",
  VendorCodes: "vendorCodes",
  NumOfSettlements: "numOfSettlements",
  SubmissionPath: "submissionPath",
  Stage: "stage",
  AlignedBalanceAmount: "alignedBalanceAmount",
  CurrentRRCalc: "currentRRCalc",
  CurrentAmount: "currentAmount",
  ValidAmount: "validAmount",
  FinalRecoveryRateCalc: "finalRecoveryRateCalc",
} as const;

export const EscalationCaseFields = {
  ...BaseCaseFields,
  ApprovedAmount: "approvedAmount",
  ApprovedDate: "approvedDate",
} as const;

export const CaseFields = {
  ...BaseCaseFields,
  ...SettlementCaseFields,
  ...EscalationCaseFields,
} as const;

export type CaseFieldKeys = keyof typeof CaseFields;
export type CaseFields = (typeof CaseFields)[CaseFieldKeys];

/**
 * Type for data grid values
 */
export interface BaseCase {
  [CaseFields.ChargeGuardCaseId]: string;
  [CaseFields.NumOfPackets]: number;
  [CaseFields.AmazonCaseId]: string;
  [CaseFields.VendorName]: string;
  [CaseFields.StoreName]: string;
  [CaseFields.CurrentCaseOwner]: string;
  [CaseFields.CurrentAction]: string;
  [CaseFields.CreatedAt]: string;
  [CaseFields.ManualFilingUser]: string;
  [CaseFields.RemainingOpenBalance]: number;
  [CaseFields.CurrentCaseNumber]: string;
  [CaseFields.InvoiceStartDueDate]: string;
  [CaseFields.InvoiceEndDueDate]: string;
  [CaseFields.Link]: string;
  [CaseFields.Payee]: string;
  [CaseFields.SubmissionAmt]: number;
  [CaseFields.SubmissionDate]: string;
}

export interface ShortageSettlementCase extends BaseCase {
  [CaseFields.PresubmissionRequired]: boolean;
  [CaseFields.Business]: string;
  [CaseFields.Sprinter]: string;
  [CaseFields.SprinterViewedAt]: string;
  [CaseFields.Processor]: string;
  [CaseFields.ProcessorViewedAt]: string;
  [CaseFields.VendorCodes]: string;
  [CaseFields.NumOfSettlements]: number;
  [CaseFields.SubmissionPath]: string;
  [CaseFields.Stage]: string;
  [CaseFields.AlignedBalanceAmount]: number;
  [CaseFields.CurrentRRCalc]: number;
  [CaseFields.CurrentAmount]: number;
  [CaseFields.ValidAmount]: number;
  [CaseFields.FinalRecoveryRateCalc]: number;
}

export interface ChargebackEscalationCase extends BaseCase {
  [EscalationCaseFields.ApprovedDate]: string;
  [CaseFields.ApprovedAmount]: number;
}

export interface ShortageEscalationCase extends BaseCase {
  [CaseFields.ApprovedDate]: string;
  [CaseFields.ApprovedAmount]: number;
}

export type Case = ChargebackEscalationCase | ShortageSettlementCase | ShortageEscalationCase;

/**
 * Column names for data grid
 */
export const caseColMap: Record<CaseFields, string> = {
  [CaseFields.ChargeGuardCaseId]: "CG Case ID",
  [CaseFields.AmazonCaseId]: "Amazon Case ID",
  [CaseFields.NumOfPackets]: "# of Packets",
  [CaseFields.VendorName]: "Vendor Name",
  [CaseFields.StoreName]: "Store (Account Name)",
  [CaseFields.CurrentCaseOwner]: "Current Case Owner",
  [CaseFields.CurrentAction]: "Case Status",
  [CaseFields.CreatedAt]: "Case Creation Date",
  [CaseFields.ManualFilingUser]: "Manual Filing User",
  [CaseFields.RemainingOpenBalance]: "Remaining Open Balance",
  [CaseFields.ApprovedAmount]: "Approved Amount",
  [CaseFields.ApprovedDate]: "Approved Date",

  [CaseFields.PresubmissionRequired]: "Presubmission Required",
  [CaseFields.Business]: "Business",
  [CaseFields.Sprinter]: "Sprinter",
  [CaseFields.SprinterViewedAt]: "Sprinter Date",
  [CaseFields.Processor]: "Processor",
  [CaseFields.ProcessorViewedAt]: "Processor Date",
  [CaseFields.Payee]: "Payee",
  [CaseFields.VendorCodes]: "Vendor Codes",
  [CaseFields.NumOfSettlements]: "# Of Settlements",
  [CaseFields.InvoiceStartDueDate]: "Invoice Start Due Date",
  [CaseFields.InvoiceEndDueDate]: "Invoice End Due Date",
  [CaseFields.SubmissionPath]: "Submission Path",
  [CaseFields.CurrentCaseNumber]: "Current Case Number",
  [CaseFields.Link]: "Link",
  [CaseFields.Stage]: "Stage",
  [CaseFields.SubmissionAmt]: "Submission Amt",
  [CaseFields.SubmissionDate]: "Submission Date",
  [CaseFields.AlignedBalanceAmount]: "Aligned Balance Amount",
  [CaseFields.CurrentRRCalc]: "Current RR Calc",
  [CaseFields.CurrentAmount]: "Current Amount",
  [CaseFields.ValidAmount]: "Valid Amount",
  [CaseFields.FinalRecoveryRateCalc]: "Final Recovery Rate Calc",
} as const;

export const caseFilterLabelMap: Record<ServerFilterKeys, string> = {
  AmazonCaseId: caseColMap.amazonCaseId,
  CreatedAt: caseColMap.createdAt,
  CurrentAction: caseColMap.currentAction,
  CurrentCaseOwner: caseColMap.currentCaseOwner,
  PresubmissionRequired: caseColMap.presubmissionRequired,
  Processor: caseColMap.processor,
  SprinterViewedAt: caseColMap.sprinterViewedAt,
  Sprinter: caseColMap.sprinter,
  SubmissionAmount: caseColMap.submissionAmt,
  SubmissionDate: caseColMap.submissionDate,
  Stage: caseColMap.stage,
  StoreName: caseColMap.storeName,
  SubmissionPath: caseColMap.submissionPath,
  VendorName: caseColMap.vendorName,
  RedFlags: "Red Flags",
  ManualFilingUser: caseColMap.manualFilingUser,
  CorrespondenceWorkFlags: "Correspondence Work Flags",
};

export const isShortageCaseType = (caseType: CaseType): boolean => {
  return caseType === CaseType.SHORTAGE_SETTLEMENT || caseType === CaseType.SHORTAGE_ESCALATION;
};

export const isChargebackCaseType = (caseType: CaseType): boolean => {
  return caseType === CaseType.CHARGEBACK_ESCALATION;
};

export const isEscalationCaseType = (caseType: CaseType): boolean => {
  return caseType === CaseType.SHORTAGE_ESCALATION || caseType === CaseType.CHARGEBACK_ESCALATION;
};
