import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { type FC, Fragment, type ReactNode } from "react";
import type { Correspondence } from "./correspondence-types";
import { MessageDisplay } from "./MessageDisplay";
import { fetchCaseCorrespondence } from "src/pages/UserDashboard/Cases/api/casesApi";

const fetchCorrespondence = async ({
  queryKey: [, caseId],
}: QueryFunctionContext<["case-correspondence", caseId: string]>): Promise<Correspondence | null> =>
  fetchCaseCorrespondence(caseId);

const gridSx: SxProps = {
  gridTemplateColumns: {
    xs: "repeat(1, 1fr)",
    sm: "repeat(2, 1fr)",
    md: "repeat(4, 1fr)",
  },
  gap: 2,
  rowGap: 3,
  alignItems: "center",
};

export const CaseCorrespondence: FC<{ caseId: string }> = ({ caseId }) => {
  const query = useQuery({
    queryFn: fetchCorrespondence,
    queryKey: ["case-correspondence", caseId],
  });

  if (query.isPending)
    return (
      <Box sx={{ marginBlock: 8, display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (query.data === null || query.isError)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          margin: 2,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="body1"
          color="error"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {query.isError
            ? `Failed to fetch correspondence for case ID "${caseId}"`
            : `No correspondence found for case with case ID "${caseId}"`}
        </Typography>
      </Box>
    );

  const data = query.data;

  return (
    <Box display="flex" flexDirection="column" gap={4} padding={4}>
      <Section title="Case Info">
        <Box display="grid" sx={gridSx}>
          <Field label="Amazon Case ID" value={data.amazonCaseId} />
          <Field label="Primary Email" value={data.primaryEmail} />
          <Field label="Date of Last Message" value={data.dateOfLastMessage} />
          <Field label="Our Last Message" value={data.ourLastMessage} />
          <Field label="Status" value={data.status} />
          {data.vcLink ? (
            <a style={{ color: "var(--primary)" }} href={data.vcLink} target="_blank">
              <Button
                sx={{
                  borderRadius: "4px",
                  borderColor: "#b0b6bd",
                  textDecorationColor: "var(--primary)",
                  color: "var(--primary)",
                  width: "250px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "block",
                }}
                variant="outlined"
              >
                Link To Case
              </Button>
            </a>
          ) : (
            <TextField label="Link To Case" placeholder="Link To Case" variant="outlined" size="small" disabled />
          )}
        </Box>
      </Section>
      <Section title="Correspondence">
        <Box display="flex" flexDirection="column">
          {data.messages.map((message, index) => (
            <Fragment key={message.id}>
              <MessageDisplay message={message} />
              {index < data.messages.length - 1 && <hr style={{ marginBlock: "2rem" }} />}
            </Fragment>
          ))}
        </Box>
      </Section>
    </Box>
  );
};

const Field: FC<{ value: string; label: string }> = ({ value, label }) => (
  <TextField value={value} label={label} placeholder={label} variant="outlined" size="small" />
);

const Section: FC<{ title: string; children: ReactNode }> = ({ title, children }) => (
  <Box border="1px solid rgba(0, 0, 0, 0.4)" borderRadius="8px" padding="1.5rem">
    <Typography variant="subtitle1" color="#F7104D" fontWeight={500} marginBottom={3}>
      {title}
    </Typography>
    {children}
  </Box>
);
