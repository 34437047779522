import { Box, CircularProgress } from "@mui/material";
import { type FC } from "react";
import { fetchCaseChangeLogs } from "../../../api/workPacketsAPI";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import {
  CaseType,
  CaseWorkPacketTypeMap,
} from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { ChangelogDate } from "src/types/work-packets";
import TimelineDate from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/ChangeLog/TimelineDate.tsx";

interface ChangeLogProps {
  caseId: string;
  caseType: CaseType,
}

const fetchLogs = async ({
  queryKey: [, caseId, caseType],
}: QueryFunctionContext<["case-logs", caseId: string, caseType: CaseType]>): Promise<ChangelogDate[]> =>
  fetchCaseChangeLogs(caseId, CaseWorkPacketTypeMap[caseType]);

export const CaseChangeLog: FC<ChangeLogProps> = ({ caseId, caseType }) => {
  const caseLogQuery = useQuery({
    queryFn: fetchLogs,
    queryKey: ["case-logs", caseId, caseType!],
    staleTime: 50000,
  });

  if (caseLogQuery.isPending || caseLogQuery.isFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (caseLogQuery.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        Failed to fetch change logs.
      </Box>
    );
  }

  return (
    <Box>
      <Box padding="32px">
        {caseLogQuery.data.map((dateGroup, index) => (
          <TimelineDate key={index} {...dateGroup} />
        ))}
      </Box>
    </Box>
  );
};
