// Permissions are strings with the structure "userType:featureType:accessType:scope"
// for example, "user:chargebacks:write:*" has feature type "chargebacks" and access type "write"

export interface AllPermissionsList {
  /** Feature types of permissions that have a `read` and `write` variant. */
  readWrite: string[];
  /** Feature types of permissions that have a `read`, `write` and `supervisor` variant. */
  supervisor: string[];
  /** Permissions that are completely independent. */
  misc: string[];
}

export const ADMIN_PERMISSION = "admin:*";

const USER_TYPE = "user";
const knownAccessTypes = ["read", "write", "supervisor"];
const knownScopes = ["*"];

export const getPermissionFromFeatureType = (feature: string, accessType: string) =>
  `${USER_TYPE}:${feature}:${accessType}:*`;

export const permissionFeatureLabels: Record<string, string> = {
  client_config: "Client Configuration",
  shortages: "Shortages",
  chargebacks: "Chargebacks",
  accruals: "Accruals",
  ossr: "OSSR",
  invoicing: "Invoicing",
  audit: "Audits",
  chargebacks_work_packet: "Chargeback Triggers",
  shortages_work_packet: "Shortage Triggers",
  accruals_work_packet: "Accrual Triggers",
  chargebacks_case: "Chargeback Cases",
  shortages_case: "Shortage Cases",
};

export const getPermissionFeatureLabel = (feature: string) => permissionFeatureLabels[feature] ?? feature;

/**
 * Parse the list of all possible permissions.
 * Permissions that have a `read` and `write` variant are returned in a `readWrite` bucket.
 * All other permissions (including those that are `read` but have no `write` and viceversa) are placed in a `misc` bucket.
 */
export const parsePermissionsList = (permissions: string[]): AllPermissionsList => {
  const readWrite: string[] = [];
  const supervisor: string[] = [];
  const misc: string[] = [];
  const unpairedPermissions = new Set<string>();

  for (const permissionName of permissions) {
    if (permissionName === ADMIN_PERMISSION) continue;

    const parts = permissionName.split(":");
    if (parts.length !== 4) {
      misc.push(permissionName);
      continue;
    }

    const [userType, featureType, accessType, scope] = parts;
    if (userType !== USER_TYPE || !knownAccessTypes.includes(accessType) || !knownScopes.includes(scope)) {
      misc.push(permissionName);
      continue;
    }
    const counterpartAccessType = accessType === "read" ? "write" : "read";
    const counterpartName = [userType, featureType, counterpartAccessType, scope].join(":");
    const counterpartExists = unpairedPermissions.has(counterpartName);
    const supervisorExists = supervisor.includes(featureType);
    if (counterpartExists && !supervisorExists) {
      unpairedPermissions.delete(counterpartName);
      readWrite.push(featureType);
    } else {
      if (accessType == "supervisor") {
        supervisor.push(featureType);
        readWrite.splice(readWrite.indexOf(featureType), 1);
        const readCounter = [userType, featureType, "read", scope].join(":");
        const writeCounter = [userType, featureType, "write", scope].join(":");
        unpairedPermissions.delete(readCounter);
        unpairedPermissions.delete(writeCounter);
      } else {
        unpairedPermissions.add(permissionName);
      }
    }
  }

  misc.push(...Array.from(unpairedPermissions));

  return { readWrite, misc, supervisor };
};

export type ReadWriteState = "none" | "read" | "read-write" | "supervisor";

export interface UserPermissionsState {
  readWrite: Record<string, ReadWriteState>;
  misc: Record<string, boolean>;
  supervisor: Record<string, ReadWriteState>;
}

/**
 * Given the list of all possible permissions, parses the read/write state of read/write permissions, and returns the
 * boolean state of all other permissions.
 */
export function parseUserPermissions(
  allPermissions: AllPermissionsList,
  userPermissions: string[],
): UserPermissionsState {
  const foreignUserPermissions = userPermissions.filter(permission => {
    if (permission === ADMIN_PERMISSION) return false;
    const feature = permission.split(":")[1];
    return (
      !allPermissions.readWrite.includes(feature) &&
      !allPermissions.supervisor.includes(feature) &&
      !allPermissions.misc.includes(permission)
    );
  });

  return {
    readWrite: allPermissions.readWrite.reduce(
      (acc, permissionFeature) => {
        const hasWrite = userPermissions.includes(getPermissionFromFeatureType(permissionFeature, "write"));
        if (hasWrite) return { ...acc, [permissionFeature]: "read-write" }; // assumes that user also has read permission

        const hasRead = userPermissions.includes(getPermissionFromFeatureType(permissionFeature, "read"));
        if (hasRead) return { ...acc, [permissionFeature]: "read" };

        return { ...acc, [permissionFeature]: "none" };
      },
      {} as Record<string, ReadWriteState>,
    ),
    supervisor: allPermissions.supervisor.reduce(
      (acc, permissionFeature) => {
        const hasSupervisor = userPermissions.includes(getPermissionFromFeatureType(permissionFeature, "supervisor"));
        if (hasSupervisor) return { ...acc, [permissionFeature]: "supervisor" };

        const hasWrite = userPermissions.includes(getPermissionFromFeatureType(permissionFeature, "write"));
        if (hasWrite) return { ...acc, [permissionFeature]: "read-write" }; // assumes that user also has read permission

        const hasRead = userPermissions.includes(getPermissionFromFeatureType(permissionFeature, "read"));
        if (hasRead) return { ...acc, [permissionFeature]: "read" };

        return { ...acc, [permissionFeature]: "none" };
      },
      {} as Record<string, ReadWriteState>,
    ),
    misc: {
      ...Object.fromEntries(allPermissions.misc.map(permission => [permission, userPermissions.includes(permission)])),
      ...Object.fromEntries(foreignUserPermissions.map(permission => [permission, true])),
    },
  };
}
