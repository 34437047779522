import { EscalationCase } from "src/types/cases";
import { FC } from "react";
import { Box, Divider } from "@mui/material";
import { Form, Formik } from "formik";
import { CustomerInfo } from "./Escalations/CustomerInfo";
import { ChargebackCaseInfo, ShortageCaseInfo } from "./Escalations/CaseInfo";
import { ShortageEscalationsInfo } from "src/pages/UserDashboard/Cases/CaseDetail/Escalations/ShortageEscalationsInfo";
import { FileUploadContext } from "../../WorkPackets/WorkPacketDetailsPopup/Summary/FileUploadContext";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup";
import { FileUploadBox } from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/components/FileUploadBox";
import { CaseItemsTable } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid";
import { CaseType, isChargebackCaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { FormSection } from "../../WorkPackets/WorkPacketDetailsPopup/Summary/components";
import { ChargebackEscalationInfo } from "src/pages/UserDashboard/Cases/CaseDetail/Escalations/ChargebackEscalationsInfo";
import { CaseDetailsKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import { CaseFormActions } from "./components/CaseFormActions";
import { useCaseUpdate } from "./hooks/useCaseUpdate";
import { ShortageEscalationSchema } from "src/pages/UserDashboard/Cases/CaseDetail/schemas";
import { FocusFormError } from "components/UI/Form";
import { CaseWorkFlags } from "src/pages/UserDashboard/Cases/CaseDetail/CaseWorkFlags";
import toast from "react-hot-toast";

interface CaseEscalationDetailsProps {
  caseItem: EscalationCase;
  caseType: CaseType;
  isRefreshing: boolean;
}

interface UpdateParams {
  STATUS?: string;
  CASE_OWNER_ID?: string;
  CHARGEBACKS_ESCALATION_CASE_DETAILS?: Record<string, any>;
  SHORTAGES_ESCALATION_CASE_DETAILS?: Record<string, any>;
}

export const CaseEscalationDetails: FC<CaseEscalationDetailsProps> = ({ caseItem, caseType, isRefreshing }) => {
  const { attachmentsUploadStore, attachmentUploadMutation, caseUpdateMutation, isSubmitting } = useCaseUpdate(
    caseItem.id,
  );

  const onSubmit = async (data: EscalationCase) => {
    const pendingUploads = attachmentsUploadStore.fileUploads.filter(upload => upload.status === "pending");

    if (pendingUploads.length > 0) {
      await attachmentUploadMutation.mutateAsync({
        caseItem,
        fileUploads: pendingUploads,
        setFileUploadStatus: attachmentsUploadStore.setFileUploadStatus,
      });
    }

    const updateParams: UpdateParams = {
      ...(data.status !== caseItem.status ? { STATUS: data.status } : {}),
      ...(data.caseOwner.id !== caseItem.caseOwner.id ? { CASE_OWNER_ID: data.caseOwner.id } : {}),
    };

    if (caseType === CaseType.CHARGEBACK_ESCALATION) {
      const CHARGEBACKS_ESCALATION_CASE_DETAILS: Record<string, any> = {};
      if (data[CaseDetailsKeys.ApprovedDate]?.valueOf() != caseItem[CaseDetailsKeys.ApprovedDate]?.valueOf()) {
        CHARGEBACKS_ESCALATION_CASE_DETAILS["APPROVED_DATE"] = data[CaseDetailsKeys.ApprovedDate]?.format("YYYY-MM-DD");
      }
      if (data[CaseDetailsKeys.MonitoringDate]?.valueOf() != caseItem[CaseDetailsKeys.MonitoringDate]?.valueOf()) {
        CHARGEBACKS_ESCALATION_CASE_DETAILS["MONITORED_DATE"] =
          data[CaseDetailsKeys.MonitoringDate]?.format("YYYY-MM-DD");
      }
      if (Object.keys(CHARGEBACKS_ESCALATION_CASE_DETAILS).length > 0) {
        updateParams["CHARGEBACKS_ESCALATION_CASE_DETAILS"] = CHARGEBACKS_ESCALATION_CASE_DETAILS;
      }
    } else if (caseType === CaseType.SHORTAGE_ESCALATION) {
      const SHORTAGES_ESCALATION_CASE_DETAILS: Record<string, any> = {};
      if (data[CaseDetailsKeys.ApprovedDate]?.valueOf() != caseItem[CaseDetailsKeys.ApprovedDate]?.valueOf()) {
        SHORTAGES_ESCALATION_CASE_DETAILS["APPROVED_DATE"] = data[CaseDetailsKeys.ApprovedDate]?.format("YYYY-MM-DD");
      }
      if (data[CaseDetailsKeys.MonitoringDate]?.valueOf() != caseItem[CaseDetailsKeys.MonitoringDate]?.valueOf()) {
        SHORTAGES_ESCALATION_CASE_DETAILS["MONITORED_DATE"] = data[CaseDetailsKeys.MonitoringDate]?.format("YYYY-MM-DD");
      }
      if (Object.keys(SHORTAGES_ESCALATION_CASE_DETAILS).length > 0) {
        updateParams["SHORTAGES_ESCALATION_CASE_DETAILS"] = SHORTAGES_ESCALATION_CASE_DETAILS;
      }
    }
    if (!Object.keys(updateParams).length) {
      toast("No changes to save");
      return;
    }
    await caseUpdateMutation.mutateAsync({ caseId: caseItem.id, caseType, updateParams });
  };

  const isChargebacks = isChargebackCaseType(caseType);

  return (
    <Box display="flex" flexDirection="column">
      <Formik
        initialValues={caseItem}
        validationSchema={isChargebacks ? undefined : ShortageEscalationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        <Form>
          <FocusFormError />
          <FileUploadContext.Provider value={attachmentsUploadStore}>
            <Box padding={2}>
              <FormSection title={"General Case Info"} id={"general-case-info"}>
                <CustomerInfo />
                {isChargebacks ? <ChargebackCaseInfo /> : <ShortageCaseInfo />}
                {!isChargebacks && <CaseWorkFlags />}
                <CaseFormActions isSubmitting={isSubmitting} isRefetching={isRefreshing} />
              </FormSection>
            </Box>

            <Divider variant="fullWidth" />

            <Box padding={2}>
              <Box sx={{ overflow: "auto" }} bgcolor="#FFF">
                <CaseItemsTable caseId={caseItem.id} caseType={caseType} />
              </Box>
            </Box>

            <Divider variant="fullWidth" />

            <Box padding={2}>
              <FormSection title={"Escalation"} id={"escalation-info"}>
              <Box paddingTop={4}>{isChargebacks ? <ChargebackEscalationInfo /> : <ShortageEscalationsInfo />}</Box>
                {!isChargebacks && (
                  <Box display="grid" gap={4} gridTemplateColumns="1fr 1fr">
                    <FormFieldsGroup withDivider title="Attachments" id="attachments">
                      <FileUploadBox accept="csv" acceptHint="CSV" />
                    </FormFieldsGroup>
                  </Box>
                )}
                <CaseFormActions isSubmitting={isSubmitting} isRefetching={isRefreshing} />
              </FormSection>
            </Box>
          </FileUploadContext.Provider>
        </Form>
      </Formik>
    </Box>
  );
};
