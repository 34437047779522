import { FC, useCallback, useMemo, useState } from "react";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
  fetchSettlementsSummaryByStatus,
  FetchSettlementsSummaryFilters,
  SettlementCasesStatusSummary,
  SettlementCasesSummaryFilterKeys,
  SettlementCasesSummaryKeys,
} from "src/pages/UserDashboard/WorkPackets/Cases/casesApi.ts";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types.ts";
import { shortagesCaseItemStatusMap } from "src/pages/UserDashboard/Cases/api/constants.ts";
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import css from "./SettlementsSummary.module.css";
import { Field, Form, Formik, useFormikContext } from "formik";
import {
  AccountValueField,
  useAccountOptions,
} from "src/pages/UserDashboard/WorkPackets/WorkPacketsGrid/filters/hooks/useAccountOptions.ts";
import { FilterList } from "@mui/icons-material";
import { AutocompleteField, DatePickerField } from "components/UI/Form";
import { formatMaybeCurrency } from "src/pages/UserDashboard/Cases/mappers/utils.ts";

type SettlementsSummaryProps = {
  caseType: CaseType
};

const getSettlementsCasesSummaryByStatus: QueryFunction<
  SettlementCasesStatusSummary[],
  ["cases", "settlements", caseType: CaseType, "summary-by-status", filters: FetchSettlementsSummaryFilters]
> = async ({
  queryKey: [, , caseType, _, filters],
  signal,
}) => fetchSettlementsSummaryByStatus(caseType, { filters }, signal);

const renderStatusCol = (params: GridRenderCellParams) => {
  const { color, title } = shortagesCaseItemStatusMap.find(action => action.value === params.value) || {
    color: "#EAECF0",
    title: params.value,
  };
  return (
    <Box display="flex" alignItems="center">
      {!!color && <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />}
      <Box ml={1}>{title}&nbsp;</Box>
    </Box>
  );
};

const columns = [
  { field: SettlementCasesSummaryKeys.Status, renderCell: renderStatusCol, headerName: "Status", flex: 4 },
  { field: SettlementCasesSummaryKeys.CasesCount, resizable: false, headerName: "Cases Count", flex: 1 },
  {
    field: SettlementCasesSummaryKeys.Amount,
    resizable: false,
    headerName: "Amount",
    flex: 1,
    valueFormatter: formatMaybeCurrency,
  },
  // { field: SettlementCasesSummaryKeys.InvoiceDueDate, resizable: false, headerName: "Invoice Due Date", flex: 1 },
  // { field: SettlementCasesSummaryKeys.C6InvoicedDate, resizable: false, headerName: "C6 Invoiced Date", flex: 1 },
];

const initialFilters: FetchSettlementsSummaryFilters = {
  [SettlementCasesSummaryFilterKeys.AccountId]: "",
  [SettlementCasesSummaryFilterKeys.InvoiceDueDate]: null,
  [SettlementCasesSummaryFilterKeys.C6InvoicedDate]: null,
};

const FilterForm: FC<{
  initialFilters: FetchSettlementsSummaryFilters,
  clearFilters: () => void
}
> = ({ initialFilters, clearFilters }) => {
  const accountOptions = useAccountOptions(AccountValueField.AccountId);
  const { dirty, resetForm } = useFormikContext();

  return (
    <Form style={{ width: "100%" }}>
      <Box
        display="grid"
        rowGap={3}
        columnGap={2}
        width={"100%"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            md: "repeat(4, 1fr)",
          },
        }}
      >
        <Field
          name={SettlementCasesSummaryFilterKeys.AccountId}
          component={AutocompleteField}
          label={"Store"}
          options={accountOptions}
          getOptionLabel={(option: any) => option.title}
          placeholder={"Store Name"}
        />
        <Field
          name={SettlementCasesSummaryFilterKeys.InvoiceDueDate}
          component={DatePickerField}
          label="Invoice Due Date"
        />
        <Field
          name={SettlementCasesSummaryFilterKeys.C6InvoicedDate}
          component={DatePickerField}
          label="C6 Invoiced Date"
        />
        <Box display="flex" justifyContent="flex-end" gap={3}>
          <Button type="submit" variant="contained" sx={{ paddingX: "24px" }} disabled={!dirty}>Apply</Button>
          <Button onClick={() => {
            resetForm({ values: initialFilters });
            clearFilters();
          }}>Clear</Button>
        </Box>
      </Box>
    </Form>
  );
};

const SettlementCasesSummaryTable: FC<{ data: SettlementCasesStatusSummary[], isLoading: boolean }> = ({
  data,
  isLoading,
}) => {
  const tableData = useMemo(() => {
    return shortagesCaseItemStatusMap.reduce((acc, statusItem) => {
      const matchingData = data.find(
        (data) => data.status === statusItem.value,
      );

      acc.push({
        status: statusItem.value,
        casesCount: matchingData?.casesCount || 0,
        amount: matchingData?.amount || 0,
        invoiceDueDate: matchingData?.invoiceDueDate || "",
        c6InvoicedDate: matchingData?.c6InvoicedDate || "",
      });

      return acc;
    }, [] as SettlementCasesStatusSummary[]);
  }, [data]);

  return (
    <DataGridPro<SettlementCasesStatusSummary>
      columns={columns}
      rows={tableData}
      autosizeOnMount
      loading={isLoading}
      getRowId={row => row.status}
      disableColumnMenu={true}
      disableColumnReorder
      disableColumnSelector
      autoHeight
      hideFooter
      className={css.summaryGrid} />
  );
};

const SettlementsSummary: FC<SettlementsSummaryProps> = ({ caseType }) => {
  const [currentFilters, setFilters] = useState(initialFilters);
  const [showFilters, setShowFilters] = useState(true);

  const settlementsSummaryQuery = useQuery({
    queryFn: getSettlementsCasesSummaryByStatus,
    queryKey: ["cases", "settlements", caseType, "summary-by-status", currentFilters],
    staleTime: 50000,
  });

  const applyFilters = useCallback((values: FetchSettlementsSummaryFilters) => setFilters(values), [setFilters]);
  const clearFilters = useCallback(() => setFilters(initialFilters), [setFilters]);

  return (
    <Box padding={3}
         borderRadius={2}
         border="1px solid #D0D5DD"
         display="flex" gap="12px" flexDirection="column"
         boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)">

      <Box display="flex" flexDirection="row" gap={2} flexGrow={1}>
        <Box flexGrow={1}>
          <Button
            startIcon={<FilterList />}
            variant="outlined"
            onClick={() => setShowFilters(prev => !prev)}
            className={showFilters ? "" : "active"}
          >
            Filters
          </Button>
        </Box>
        {showFilters && (
          <Formik initialValues={initialFilters} onSubmit={applyFilters} enableReinitialize>
            <FilterForm initialFilters={initialFilters} clearFilters={clearFilters} />
          </Formik>
        )}
      </Box>
      <SettlementCasesSummaryTable data={settlementsSummaryQuery.data || []}
                                   isLoading={settlementsSummaryQuery.isPending} />
    </Box>
  );
};

export { SettlementsSummary };
