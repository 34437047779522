import { type GridRenderEditCellParams } from "@mui/x-data-grid";
import { type FC } from "react";
import usePermissions from "src/components/CustomHooks/usePermissions";
import { PermissionLevel, type WorkPacket, type WorkPacketAction, WorkPacketFilter } from "src/types/work-packets";
import EditableAutocompleteCell from "../EditableAutocompleteCell";
import {getEditWorkPacketActionOptions} from "../helpers/getStaticFilterOptions";
import { useWorkPacketsContext } from "src/pages/UserDashboard/WorkPackets/WorkPacketsContext";
import { WORK_PACKET_SUPERVISOR_PERMISSIONS } from "src/pages/UserDashboard/WorkPackets/WorkPacketPermission";

const groupBy = (option: WorkPacketAction) => option.category;

export const CurrentActionEdit: FC<GridRenderEditCellParams<WorkPacket>> = props => {
  const { isAdmin, hasPermission } = usePermissions();
  const { currentFilters } = useWorkPacketsContext();
  const { workPacketType } = props.row;

  const isPreCaseView = !!currentFilters[WorkPacketFilter.PreCase];
  const isSupervisor = hasPermission(WORK_PACKET_SUPERVISOR_PERMISSIONS[workPacketType]);

  const permissionLevel = isAdmin
    ? PermissionLevel.Admin
    : isSupervisor
    ? PermissionLevel.Supervisor
    : PermissionLevel.Default;

  const options = getEditWorkPacketActionOptions(workPacketType, isPreCaseView, permissionLevel);

  return <EditableAutocompleteCell {...props} options={options} groupBy={groupBy} />;
};
