import { FC, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { Box, BoxProps, Collapse, IconButton, Typography } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import Loader from "components/Loader/Loader";
import {
  CommentData,
  CommentFormFieldKeys,
  NewCommentFormikActions,
  NewCommentFormikValues,
  TogglePinProps,
  UserData,
} from "src/types/comments";
import { commentPalette } from "src/styles/palette";

import { NewCommentFormWithUserAvatar, CommentCard } from "./components";
import {
  usePostModelCommentMutation,
  ListModelCommentsKey,
  useListModelCommentsQuery,
  useTogglePinMutation,
} from "./queries";

const commentContainerProps: BoxProps = {
  paddingInline: 2,
  paddingBlockStart: 2,
  sx: { borderBlockStart: `1px solid ${commentPalette.lightBorder}` },
};

export const Comments: FC<{ modelType: string; modelId: string }> = ({ modelType = "cases", modelId }) => {
  const { user } = useAuth0();

  const listCommentQueryKey: ListModelCommentsKey = ["comments", modelType, modelId, "list"];

  const queryClient = useQueryClient();

  const listCommentsQuery = useListModelCommentsQuery(listCommentQueryKey);
  const userData: UserData = {
    name: user!.nickname ?? user!.name ?? user!.email,
    picture: user!.picture,
  };
  const postNewCommentMutation = usePostModelCommentMutation(
    modelType,
    modelId,
    userData,
    listCommentQueryKey,
    queryClient,
  );
  const togglePinMutation = useTogglePinMutation(listCommentQueryKey, queryClient);

  const saveComment = async (values: NewCommentFormikValues, actions: NewCommentFormikActions) => {
    if ((!values[CommentFormFieldKeys.newComment] && !values[CommentFormFieldKeys.attachment]) || !user?.sub) {
      actions.resetForm();
      actions.setFieldValue(CommentFormFieldKeys.attachment, null);
      return;
    }
    try {
      postNewCommentMutation.mutate({
        newComment: values[CommentFormFieldKeys.newComment],
        attachment: values[CommentFormFieldKeys.attachment],
      });
    } catch (e) {
      console.error(e);
    } finally {
      actions.resetForm();
      actions.setFieldValue(CommentFormFieldKeys.attachment, null);
    }
  };

  const togglePin = async (id: string, prevPin: boolean) => {
    togglePinMutation.mutate({ commentId: id, newPin: !prevPin });
  };

  const pinnedComments = useMemo(() => (listCommentsQuery.data ?? []).filter(c => c.isPinned), [listCommentsQuery]);
  const unPinnedComments = useMemo(() => (listCommentsQuery.data ?? []).filter(c => !c.isPinned), [listCommentsQuery]);

  if (listCommentsQuery.isPending) return <Loader />;
  if (listCommentsQuery.isError) return <Loader />;

  return (
    <Box display="flex" gap="12px" flexDirection="column">
      <CollapsablePinnedComments pinnedComments={pinnedComments} togglePin={togglePin} />
      {/** Un Pinned Comments */}
      <Box {...commentContainerProps}>
        {Array.isArray(unPinnedComments) && unPinnedComments.length > 0
          ? unPinnedComments.map((comment, i) => (
              <CommentCard key={`${comment.id}-${i}`} commentData={comment} togglePin={togglePin} />
            ))
          : null}
      </Box>
      <Box {...commentContainerProps} position="sticky" bottom={0} bgcolor="background.paper" paddingBlockEnd={2}>
        <NewCommentFormWithUserAvatar userData={userData} handleNewComment={saveComment} />
      </Box>
    </Box>
  );
};

const CollapsablePinnedComments: FC<{ pinnedComments: CommentData[] } & TogglePinProps> = ({
  pinnedComments,
  togglePin,
}) => {
  const [showPinned, setShowPinned] = useState(true);

  const handleToggle = () => {
    setShowPinned(prev => !prev);
  };
  return (
    <Box>
      <Box
        onClick={handleToggle}
        display="flex"
        alignItems="center"
        bgcolor="#F9FAFB"
        sx={{
          cursor: "pointer",
        }}
      >
        <IconButton>{showPinned ? <ExpandLess /> : <ExpandMore />}</IconButton>
        <Typography sx={{ color: commentPalette.timestamp, fontSize: "0.7rem" }}>PINNED COMMENTS</Typography>
      </Box>
      <Collapse in={showPinned}>
        <Box {...commentContainerProps}>
          {Array.isArray(pinnedComments) && pinnedComments.length > 0
            ? pinnedComments.map(comment => (
                <CommentCard key={`${comment.id}`} commentData={comment} togglePin={togglePin} />
              ))
            : null}
        </Box>
      </Collapse>
    </Box>
  );
};
