import { GridColDef } from "@mui/x-data-grid";
import { getColorByAuditStatus } from "./getColorByAuditStatus";
import { Box, Button, Tooltip } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';

export const getAuditsGridColumns = (): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "requestId",
      headerName: "Request ID",
      width: 150,
    },
    {
      field: "store",
      headerName: "Store",
      width: 150,
    },
    {
      field: "market",
      headerName: "Market",
      width: 150,
    },
    {
      field: "account",
      headerName: "Account",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => {
        const color = getColorByAuditStatus(params.value);
        return (
          <Box display="flex" alignItems="center">
            <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />
            <Box ml={1} sx={{ textTransform: "capitalize" }}>
              {params.value}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "auditResults",
      headerName: "Audit Results",
      width: 160,
      display: "flex",
      renderCell: ({ row }) =>
        row.status === "success" && (
          <a
            href="https://docs.google.com/presentation/d/1We0CyPSFHisyreOHd0W7aH-TH0A4BQh2/edit#slide=id.p3"
            target="_blank"
          >
            <Button variant="outlined" sx={{ padding: "2px 8px" }} endIcon={<LaunchIcon />}>
              Open Results
            </Button>
          </a>
        ),
    },
    {
      field: "requestedDate",
      headerName: "Requested Date",
      width: 250,
    },
    {
      field: "completedDate",
      headerName: "Completed Date",
      width: 250,
    },
    {
      field: "chargebackMonths",
      headerName: "Chargeback Months",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "shortageMonths",
      headerName: "Shortage Months",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "accuralMonths",
      headerName: "Accural Months",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "poMonths",
      headerName: "PO Months",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "errorMessage",
      headerName: "Error Message",
      align: "left",
      headerAlign: "left",
      width: 250,
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <span>{value}</span>
        </Tooltip>
      ),
    },
  ];

  return columns;
};
