import { FC } from "react";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup.tsx";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { EscalationCase } from "src/types/cases";
import { Box, TextField } from "@mui/material";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";
import { DatePickerField } from "../../../../../components/UI/Form";
import { AutocompleteField } from "components/UI/Form";
import { WorkPacketOwner } from "src/types/work-packets.ts";
import { useUserOptions } from "src/pages/UserDashboard/WorkPackets/useUserOptions.ts";
import { shortagesCaseItemStatusMap } from "src/pages/UserDashboard/Cases/api/constants.ts";
import usePermissions from "components/CustomHooks/usePermissions";
import { VcLinkButton } from "src/components/work-packets/VcLinkButton";

const StatusSelectField: FC<{ name: string }> = ({ name }) => (
  <Box>
    <Field
      name={name}
      label="Case Status"
      variant="outlined"
      component={AutocompleteField}
      options={shortagesCaseItemStatusMap}
      getOptionLabel={(option: any) => option.title}
      fullWidth
    />
    <ErrorMessage
      render={message => <Box sx={{ fontSize: ".7rem", color: "primary.light" }}>{message}</Box>}
      name={name}
    />
  </Box>
);

export const CaseInfoTextField: FC<{ name: string; label: string; editable?: boolean }> = ({
  name,
  label,
  editable,
}) => (
  <Field
    name={name}
    disabled={!editable}
    as={TextField}
    variant="outlined"
    size="small"
    placeholder={label}
    label={label}
  />
);

export const ShortageCaseInfo: FC = () => {
  const userOptions = useUserOptions();
  const { hasPermission } = usePermissions();
  const { values } = useFormikContext<EscalationCase>();

  return (
    <FormFieldsGroup title="Case Info" id={"case-info"}>
      <CaseInfoTextField name={CaseDetailsKeys.AmazonCaseId} label="Amazon Case ID" />
      <StatusSelectField name={CaseDetailsKeys.Status} />
      <CaseInfoTextField name={CaseDetailsKeys.RecoveryStream} label="Recovery Stream" />
      <Field
        name={CaseDetailsKeys.RecoveryStreamActivationDate}
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        variant="outlined"
        size="small"
        disabled
      />
      <CaseInfoTextField name={CaseDetailsKeys.ManualFilingUser} label="Manual Filing User" />
      <Field
        name="caseOwner.id"
        component={AutocompleteField}
        label="Current Case Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Case Owner"
        disabled={!hasPermission(["admin:*", "user:shortages_case:supervisor:*"])}
      />
      <CaseInfoTextField name={CaseDetailsKeys.LastModifiedBy} label="Last Modified By" editable />
      {values.vcLink ? (
        <VcLinkButton link={values.vcLink} />
      ) : (
        <TextField label="Link To Case" placeholder="Link To Case" variant="outlined" size="small" disabled />
      )}
    </FormFieldsGroup>
  );
};

export const ChargebackCaseInfo: FC = () => {
  const userOptions = useUserOptions();
  const { hasPermission } = usePermissions();
  return (
    <FormFieldsGroup title="Case Info" id={"case-info"}>
      <CaseInfoTextField name={CaseDetailsKeys.AmazonCaseId} label="Amazon Case ID" />
      <StatusSelectField name={CaseDetailsKeys.Status} />
      {/* <CaseInfoTextField name={CaseDetailsKeys.RecoveryStream} label="Recovery Stream" /> */}
      <CaseInfoTextField name={CaseDetailsKeys.RecoveryStreamType} label="Recovery Stream Type" />
      <Field
        name={CaseDetailsKeys.RecoveryStreamActivationDate}
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        variant="outlined"
        size="small"
        disabled
      />
      <CaseInfoTextField name={CaseDetailsKeys.RecoveryStreamSubType} label="Recovery Stream Subtype" />
      <CaseInfoTextField name={CaseDetailsKeys.IssueSubTypeDesc} label="Recovery Stream Subtype 1" />
      <CaseInfoTextField name={CaseDetailsKeys.Notes} label="Recovery Stream Subtype 2" />
      <CaseInfoTextField name={CaseDetailsKeys.ManualFilingUser} label="Manual Filing User" />
      <Field
        name="caseOwner.id"
        component={AutocompleteField}
        label="Current Case Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Case Owner"
        disabled={!hasPermission(["admin:*", "user:chargebacks_case:supervisor:*"])}
      />
      <CaseInfoTextField name={CaseDetailsKeys.LastModifiedBy} label="Last Modified By" />
    </FormFieldsGroup>
  );
};
