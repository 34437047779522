import Button from "@mui/material/Button";
import { type FC } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const VcLinkButton: FC<{ link: string }> = ({ link }) => (
  <a style={{ color: "var(--primary)", textDecoration: "none" }} href={link} target="_blank">
    <Button
      color="success"
      endIcon={<OpenInNewIcon />}
      sx={{
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        color: "var(--primary)",
        width: "100%",
        display: "flex",
        height: "37.11px",
      }}
      variant="outlined"
    >
      Link to case
    </Button>
  </a>
);
