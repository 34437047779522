import { http } from "src/axios";
import type { WorkPacketDisputesInfo, WorkPacketFilters } from "src/types/work-packets";
import { type ServerModelAttachment } from "src/types/work-packets";
import { computeFilters } from "src/pages/UserDashboard/WorkPackets/api/workPacketsAPI";
import { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";
import { encodeUrlParams } from "./encodeUrlParams";
import { ServerCase } from "src/types/cases";
import {
  mapServerEscalationToLocalCase,
  mapServerSettlementToLocalCase,
} from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import type { AxiosRequestConfig } from "axios";
import apiClient from "src/pages/UserDashboard/WorkPackets/api/apiClient";
import type {
  CaseCorrespondenceResponse,
  CaseItemsListResponse,
  FetchCaseItemsArgs,
  GetCaseItemsResponse,
  ServerResponse,
} from "./types";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import { serverToLocalCaseItemMappers } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCaseItem";
import { parseDate } from "src/utils/date-parse";
import { Correspondence } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseCorrespondence/correspondence-types";
import { CorrespondenceWorkFlag } from "src/pages/UserDashboard/WorkPackets/Cases/correspondence-work-flags.ts";

export async function createCase({
  caseId,
  caseType,
  caseCreatedAt,
  workPacketIds,
  excludedWorkPacketIds,
  filters,
  workPacketType,
  caseTextTitle,
  caseTextBody,
}: {
  caseId: string;
  workPacketIds: string[];
  excludedWorkPacketIds: string[];
  filters: WorkPacketFilters;
  caseType: string;
  caseCreatedAt: string;
  workPacketType: WorkPacketType;
  caseTextTitle: string;
  caseTextBody: string;
}) {
  const payload = {
    amazon_case_id: caseId,
    work_packet_ids: workPacketIds,
    case_type: caseType,
    case_created_at: caseCreatedAt,
    excluded_work_packets: excludedWorkPacketIds,
    filters: JSON.stringify(computeFilters(filters, workPacketType)),
    case_text_title: caseTextTitle,
    case_text_body: caseTextBody,
  };
  const response = await http.post(`/api/v2/cases`, payload, {
    errorMessage: "Error while creating case",
  });
  return response.data;
}

export async function getDisputesIdsAndVendorInfo({
  workPacketType,
  workPacketIds,
  excludedWorkPackets,
  filters,
}: {
  workPacketType: WorkPacketType;
  workPacketIds: string[];
  excludedWorkPackets: string[];
  filters: WorkPacketFilters;
}): Promise<WorkPacketDisputesInfo> {
  if (workPacketType == WorkPacketType.CHARGEBACKS) {
    const params = encodeUrlParams({
      work_packet_ids: workPacketIds,
      excluded_work_packets: excludedWorkPackets,
      filters: JSON.stringify(computeFilters(filters, workPacketType)),
    });
    const response = await http.get(`api/v2/work_packets/chargebacks/pre_create_case`, { params });
    return response.data.data as WorkPacketDisputesInfo;
  }

  const params = encodeUrlParams({
    work_packet_ids: workPacketIds,
    excluded_work_packets: excludedWorkPackets,
    filters: JSON.stringify(computeFilters(filters, workPacketType)),
  });
  const response = await http.get(`api/v2/work_packets/shortages/pre_create_case`, { params });
  return response.data.data as WorkPacketDisputesInfo;
}

interface CaseResponse {
  data: {
    cases: ServerCase[];
  };
}

export async function updateCase(caseId: string, caseType: CaseType, updateParams: Record<string, any>) {
  const caseUpdateUrl = {
    [CaseType.CHARGEBACK_ESCALATION]: "/api/v2/cases/chargebacks",
    [CaseType.SHORTAGE_SETTLEMENT]: "/api/v2/cases/shortages",
    [CaseType.SHORTAGE_ESCALATION]: "/api/v2/cases/shortages",
  };
  const response = await http.put<CaseResponse>(
    caseUpdateUrl[caseType],
    {
      case_id: caseId,
      update_params: updateParams,
    },
    { errorMessage: "Error while updating case" },
  );
  return response.data;
}

export async function deleteCaseCorrespondenceWorkFlag(caseId: string, flag: CorrespondenceWorkFlag) {
  const response = await http.delete(
    `/api/v2/cases/${caseId}/correspondence_work_flags/${flag}`,
    { errorMessage: "Error while removing work flag" },
  );
  return response.data;
}

export async function bulkUpdateCaseItems({
  caseId,
  excludedItems,
  selectedItems,
  allSelected,
  updateParams,
}: {
  selectedItems: string[];
  excludedItems: string[];
  allSelected: boolean;
  caseId: string;
  caseType: string;
  updateParams: Record<string, any>;
}) {
  const response = await http.put<CaseResponse>(
    "/api/v2/cases/case_items",
    {
      case_id: caseId,
      selected_items: selectedItems,
      excluded_items: excludedItems,
      all_items: allSelected,
      update_params: updateParams,
    },
    { errorMessage: "Error while updating case" },
  );
  return response.data;
}

export async function getSettlementCaseById(caseId: string) {
  const response = await http.get<CaseResponse>("/api/v2/cases/" + caseId);
  return mapServerSettlementToLocalCase(response.data.data.cases[0]);
}

export async function getEscalationCaseById(caseId: string) {
  const response = await http.get<CaseResponse>("/api/v2/cases/" + caseId);
  return mapServerEscalationToLocalCase(response.data.data.cases[0]);
}

export async function getCaseAttachments(caseId: string): Promise<ServerModelAttachment[]> {
  const response = await http.get("/api/v2/cases/" + caseId + "/attachments");
  return response.data.model_attachments;
}

export async function getCaseInvoices(caseId: string): Promise<ServerModelAttachment[]> {
  const response = await http.get("/api/v2/cases/" + caseId + "/attachments");
  return response.data.model_attachments;
}

export async function uploadCaseAttachment(
  caseId: string,
  file: File,
  onUploadProgress?: AxiosRequestConfig["onUploadProgress"],
): Promise<any> {
  const formData = new FormData();
  formData.set("file", file);
  formData.set("file_name", file.name);
  formData.set("model", "cases");
  formData.set("model_id", caseId);

  return apiClient.post(`/api/v2/attachments`, formData, {
    adapter: "xhr",
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  });
}

export async function getCaseItems(
  caseId: string,
  caseType: CaseType,
  { page, pageSize, sorting }: FetchCaseItemsArgs,
  signal: AbortSignal,
): Promise<GetCaseItemsResponse> {
  const response = await http.get<ServerResponse<CaseItemsListResponse>>(`/api/v2/cases/${caseId}/case_items`, {
    signal,
    params: {
      page: page,
      per_page: pageSize,
      sorting: JSON.stringify(sorting ? { [sorting.key]: sorting.direction } : EMPTY_OBJECT),
    },
    errorMessage: "Error while fetching case items",
  });

  return {
    items: response.data.data.items.map(serverToLocalCaseItemMappers[caseType]),
    total_records: response.data.data.total_records,
    pages: response.data.data.pages,
  };
}

export async function deleteCaseItem(caseId: string, caseItemId: string) {
  const response = await http.delete(`/api/v2/cases/${caseId}/case_items/${caseItemId}`);
  return response.data;
}

export async function bulkDeleteCaseItems({
  caseId,
  excludedItems,
  selectedItems,
  allSelected,
}: {
  selectedItems: string[];
  excludedItems: string[];
  allSelected: boolean;
  caseId: string;
  caseType: string;
}) {
  const response = await http.post(
    `/api/v2/cases/${caseId}/case_items/bulk_delete`,
    {
      selected_items: selectedItems,
      excluded_items: excludedItems,
      all_items: allSelected,
    },
    { errorMessage: "Error while deleting work packets" },
  );
  return response.data;
}

export async function fetchCaseCorrespondence(caseId: string): Promise<Correspondence | null> {
  const response = await http.get<CaseCorrespondenceResponse>(`/api/v2/cases/${caseId}/case_correspondence`);

  const correspondence = response.data.case_correspondence;

  if (correspondence === null) return null;

  return {
    amazonCaseId: correspondence.AMAZON_CASE_ID,
    primaryEmail: correspondence.PRIMARY_EMAIL || "",
    dateOfLastMessage: correspondence.CORRESPONDENCE_MESSAGES[0]?.SENT_AT || "",
    ourLastMessage: correspondence.CORRESPONDENCE_MESSAGES.find(m => m.SENDER !== "Amazon")?.SENT_AT || "",
    status: correspondence.STATUS || "",
    vcLink: correspondence.VC_LINK || "",
    messages: correspondence.CORRESPONDENCE_MESSAGES.map(message => ({
      id: message.CGID,
      from: message.SENDER || "",
      timestamp: parseDate(message.SENT_AT),
      content: message.BODY,
    })),
  };
}
