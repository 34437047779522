import type { Option } from "src/pages/UserDashboard/WorkPackets/Cases/case-types.ts";
import {
  CorrespondenceWorkFlag,
  correspondenceWorkFlagLabels,
} from "src/pages/UserDashboard/WorkPackets/Cases/correspondence-work-flags.ts";

export const shortageSettlementCorrespondenceWorkFlagOptions: Option[] = [
  CorrespondenceWorkFlag.AMAZON_RESPONDED,
  CorrespondenceWorkFlag.CORRESPONDENCE_STATUS_CHANGED_BY_AMAZON,
  CorrespondenceWorkFlag.NO_RESPONSE_OR_STATUS_CHANGE_GT_3_DAYS,
  CorrespondenceWorkFlag.NO_SPRINTED_DATE_UPDATE_GT_3_DAYS,
].map(asOption);

export const shortageEscalationCorrespondenceWorkFlagOptions: Option[] = [
  CorrespondenceWorkFlag.AMAZON_RESPONDED,
  CorrespondenceWorkFlag.CORRESPONDENCE_STATUS_CHANGED_BY_AMAZON,
  CorrespondenceWorkFlag.NO_RESPONSE_OR_STATUS_CHANGE_GT_3_DAYS,
  CorrespondenceWorkFlag.NO_MONITORING_DATE_UPDATE_GT_3_DAYS,
].map(asOption);

function asOption(flag: CorrespondenceWorkFlag): Option {
  return {
    title: correspondenceWorkFlagLabels[flag],
    value: flag,
  };
}
