import { FC } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { ChargebackWorkPacket, EscalationPaymentDetail } from "src/types/work-packets.ts";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/components";
import { Box, Button, Divider, TextField } from "@mui/material";
import { AutocompleteField, DatePickerField } from "components/UI/Form";
import { chargebackEscalationWorkPacketActions } from "src/pages/UserDashboard/WorkPackets/WorkPacketsGrid/helpers/getStaticFilterOptions.ts";
import { createFieldFn } from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/field-util.ts";

const newPaymentDetail = (caseItemId: string): EscalationPaymentDetail => ({
  id: "",
  caseItemId: caseItemId,
  invoiceId: "",
  invoiceNumber: "",
  disputedInvoiceId: "",
  paidAmount: 0,
  paymentDate: undefined,
  c6InvoiceDate: undefined,
  // TODO: figure out details on Payout Type
  // payoutType: "",
  customerInvoiceAmount: "",
  paymentId: "",
  createdAt: null,
});

const getField = createFieldFn<ChargebackWorkPacket>();

export const Escalations: FC = () => {
  const { values: packet } = useFormikContext<ChargebackWorkPacket>();
  return packet.escalations.map((escalation, i) => (
    <FormFieldsGroup title={`Escalation ${i + 1}`} withDivider id={`escalation-${i + 1}`} key={escalation.id}>
      <Field
        name={getField(`escalations[${i}].id`)}
        as={TextField}
        variant="outlined"
        size="small"
        label="Chargeback Issue ID"
        disabled
      />

      {/*<Field*/}
      {/*  name={getField(`escalations[${i}].createdAt`)}*/}
      {/*  component={DatePickerField}*/}
      {/*  label="Case Created Date"*/}
      {/*  disabled*/}
      {/*/>*/}

      <Field
        name={getField(`escalations[${i}].status`)}
        component={AutocompleteField}
        label="Case Item Status"
        options={chargebackEscalationWorkPacketActions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={getField(`escalations[${i}].disputedAmount`)}
        as={TextField}
        variant="outlined"
        size="small"
        label="Disputed Amount"
        disabled
      />
      <Field
        name={getField(`escalations[${i}].approvedAmount`)}
        as={TextField}
        variant="outlined"
        size="small"
        label="Approved Amount"
        disabled
      />

      <Field
        name={getField(`escalations[${i}].approvedDate`)}
        component={DatePickerField}
        label="Approved Date in Case"
        disabled
      />

      <Field
        name={getField(`escalations[${i}].paidAmount`)}
        as={TextField}
        variant="outlined"
        size="small"
        label="Paid Amount"
        disabled
      />

      <FieldArray
        name={getField(`escalations[${i}].paymentDetails`)}
        render={({ push, remove }) => (
          <>
            {escalation.paymentDetails.map((_, j) => (
              <FormFieldsGroup
                title={`Payment Details ${j + 1}`}
                id={`escalation-${i + 1}-payment-${j + 1}`}
                key={`escalation-${i + 1}-payment-${j + 1}`}
                onRemove={() => {
                  remove(j);
                }}
                asSubsection
              >
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].invoiceId`)}
                  as={TextField}
                  variant="outlined"
                  size="small"
                  label="VC Disputed Invoice"
                />
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].invoiceNumber`)}
                  as={TextField}
                  variant="outlined"
                  size="small"
                  label="Invoice # for Payment"
                />
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].paymentId`)}
                  as={TextField}
                  variant="outlined"
                  size="small"
                  label="Payment ID"
                />
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].paidAmount`)}
                  as={TextField}
                  variant="outlined"
                  size="small"
                  label="Paid Amount"
                />
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].paymentDate`)}
                  component={DatePickerField}
                  label="Payment Date"
                />
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].c6InvoiceDate`)}
                  component={DatePickerField}
                  label="C6 Invoiced Date"
                />
                <Field
                  name={getField(`escalations[${i}].paymentDetails[${j}].customerInvoiceAmount`)}
                  as={TextField}
                  variant="outlined"
                  size="small"
                  label="Payout Type"
                />
              </FormFieldsGroup>
            ))}
            <Box style={{ gridColumn: "1 / -1", textAlign: "center" }} sx={{ mb: 2 }}>
              <Divider sx={{ mt: 0, mb: 2 }} />
              <Button
                variant="outlined"
                onClick={() => {
                  push(newPaymentDetail(escalation.itemId));
                }}
              >
                Add Another Payment Details
              </Button>
            </Box>
          </>
        )}
      />
    </FormFieldsGroup>
  ));
};
