import * as Yup from "yup";
import { SettlementCase } from "src/types/cases";
import { FC } from "react";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import {
  FormFieldsGroup,
  FormSection,
} from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/components";
import { CustomerInfo } from "./Settlement/CustomerInfo";
import { CaseInfo } from "./Settlement/CaseInfo";
import { SettlementInfo } from "./Settlement/SettlementInfo";
import { SettlementCaseItems } from "src/pages/UserDashboard/Cases/CaseDetail/Settlement/SettlementCaseItems";
import { FileUploadContext } from "../../WorkPackets/WorkPacketDetailsPopup/Summary/FileUploadContext";
import { FileUploadBox } from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/components/FileUploadBox";
import toast from "react-hot-toast";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { getUpdateParams } from "./hooks/getUpdateParams";
import { useCaseUpdate } from "./hooks/useCaseUpdate";
import { CaseFormActions } from "./components/CaseFormActions";
import { CaseWorkFlags } from "src/pages/UserDashboard/Cases/CaseDetail/CaseWorkFlags.tsx";
import { CaseDetailsKeys } from "../mappers/mapServerToLocalCase";

interface CaseSettlementDetailsProps {
  caseItem: SettlementCase;
  caseType: CaseType;
  isRefreshing: boolean;
}

const settlementValidationSchema = Yup.object().shape({
  [CaseDetailsKeys.AlignedBalAmount]: Yup.number()
    .test(
      "lessThanSubmission",
      "Aligned Balance Amount should be less than or equal to the Submission Amount",
      (value, ctx) => {
        const submissionAmount = ctx.parent[CaseDetailsKeys.SubmissionAmount];
        return !value || (!submissionAmount && submissionAmount != 0) || value <= submissionAmount;
      },
    )
    .test(
      "greaterThanPaidAmount",
      "Aligned Balance Amount should be greater than the Paid Amount",
      (value, ctx) => {
        const paidAmount = ctx.parent[CaseDetailsKeys.PaidAmount];
        const paidAmountBeforeAlignment = ctx.parent[CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment];

        if (paidAmountBeforeAlignment !== null) {
          return true;
        }

        return !value || (!paidAmount && paidAmount != 0) || value > paidAmount;
      }
    )
    .test(
      "greaterThanFirstOffer",
      "Aligned Balance Amount should be greater than the 1st offer amount",
      (value, ctx) => {
        const firstOfferAmount = ctx.parent[CaseDetailsKeys.FirstOfferAmount];
        const secondOfferAmount = ctx.parent[CaseDetailsKeys.SecondOfferAmount];
        const thirdOfferAmount = ctx.parent[CaseDetailsKeys.ThirdOfferAmount];

        if (secondOfferAmount !== 0 || thirdOfferAmount !== 0) {
          return true;
        }

        return !value || (!firstOfferAmount && firstOfferAmount != 0) || value > firstOfferAmount;
      }
    )
    .test(
      "greaterThanSecondOffer",
      "Aligned Balance Amount should be greater than the 2nd offer amount",
      (value, ctx) => {
        const secondOfferAmount = ctx.parent[CaseDetailsKeys.SecondOfferAmount];
        const thirdOfferAmount = ctx.parent[CaseDetailsKeys.ThirdOfferAmount];

        if (thirdOfferAmount !== 0) {
          return true;
        }

        return !value || (!secondOfferAmount && secondOfferAmount != 0) || value > secondOfferAmount;
      }
    )
    .test(
      "greaterThanThirdOffer",
      "Aligned Balance Amount should be greater than the 3rd offer amount",
      (value, ctx) => {
        const thirdOfferAmount = ctx.parent[CaseDetailsKeys.ThirdOfferAmount];
        return !value || (!thirdOfferAmount && thirdOfferAmount != 0) || value > thirdOfferAmount;
      }
    ),
  [CaseDetailsKeys.AlignedBalDate]: Yup.date().test(
    "afterSubmissionDate",
    "Aligned Balance Date should be greater than the Submission Date",
    (value, ctx) => {
      const submissionDate = ctx.parent[CaseDetailsKeys.SubmissionDate];
      if (!value || !submissionDate) return true;
      return value > submissionDate;
    }
  ),
});

export const CaseSettlementDetails: FC<CaseSettlementDetailsProps> = ({ caseItem, caseType, isRefreshing }) => {
  const { attachmentsUploadStore, attachmentUploadMutation, caseUpdateMutation, isSubmitting } = useCaseUpdate(
    caseItem.id,
  );

  const onSubmit = (data: SettlementCase) => {
    const updateParams = getUpdateParams(caseItem, data);
    return caseUpdateMutation.mutateAsync({ caseId: caseItem.id, caseType, updateParams });
  };

  const onSettlementSubmit = async (data: SettlementCase) => {
    try {
      const pendingUploads = attachmentsUploadStore.fileUploads.filter(upload => upload.status === "pending");

      if (pendingUploads.length > 0) {
        await attachmentUploadMutation.mutateAsync({
          caseItem,
          fileUploads: pendingUploads,
          setFileUploadStatus: attachmentsUploadStore.setFileUploadStatus,
        });
      }

      const updateParams = getUpdateParams(caseItem, data);
      return caseUpdateMutation.mutateAsync({ caseId: caseItem.id, caseType, updateParams });
    } catch (error) {
      toast.error("Failed to update case");
    }
  };

  return (
    <Box padding={4}>
      <FileUploadContext.Provider value={attachmentsUploadStore}>
        <Formik initialValues={caseItem} enableReinitialize={true} onSubmit={onSubmit}>
          <Form>
            <FormSection title="General Case Info" id="general-case-info">
              {isRefreshing && <Box paddingTop={2}>Refreshing...</Box>}
              <Box {...(isRefreshing && { sx: { opacity: 0.5 }, inert: "" })}>
                <CustomerInfo />
                <CaseInfo />
                <CaseWorkFlags />
                <CaseFormActions isSubmitting={isSubmitting} isRefetching={isRefreshing} />
              </Box>
            </FormSection>
          </Form>
        </Formik>

        <SettlementCaseItems caseId={caseItem.id} caseType={caseType} />

        <Formik
          initialValues={caseItem}
          enableReinitialize={true}
          onSubmit={onSettlementSubmit}
          validationSchema={settlementValidationSchema}
        >
          <Form>
            <FormSection title="Settlement" id="settlement-info">
              {isRefreshing && <Box paddingTop={2}>Refreshing...</Box>}
              <Box {...(isRefreshing && { sx: { opacity: 0.5 }, inert: "" })}>
                <SettlementInfo />
                <Box display="grid" gap={4} gridTemplateColumns="1fr 1fr">
                  <FormFieldsGroup withDivider title="Attachments" id="attachments">
                    <FileUploadBox accept="csv" acceptHint="CSV" />
                  </FormFieldsGroup>
                </Box>
              </Box>
              <CaseFormActions isSubmitting={isSubmitting} isRefetching={isRefreshing} />
            </FormSection>
          </Form>
        </Formik>
      </FileUploadContext.Provider>
    </Box>
  );
};
