import { type AxiosRequestConfig } from "axios";
import {
  type AccrualDispute,
  type ChangelogDate,
  ChargebackEscalation,
  ChargebackEscalationPaymentDetail,
  type CommandCenterGridRow,
  EscalationPaymentDetail,
  type ServerAccrualDispute,
  type ServerChangeLogEntry,
  type ServerModelAttachment,
  type ServerWorkPacketTypeMap,
  ShortageEscalation,
  StagefulAction,
  StagelessAction,
  StageName,
  type WorkPacket,
  type WorkPacketActionName,
  WorkPacketFilter,
  type WorkPacketFilters,
  type WorkPacketServerResponse,
  type WorkPacketsSummaryAggregated,
  type WorkPacketSummaryFilters,
  type WorkPacketTypeMap,
} from "src/types/work-packets";
import { parseDateRange } from "src/utils/date-range";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import FileDownloadServices from "../../../../Services/FileDownloadServices";
import { createStagefulAction, createStagelessAction, mapServerToLocalWorkPackets } from "../mappers";
import { mapServerChangeLog } from "../mappers/mapServerChangeLog";
import { mapCommandCenterData } from "../mappers/mapServerCommandCenterData";
import { WorkPacketType } from "../WorkPacketType";
import apiClient from "./apiClient";
import { computeSortOption } from "./computeSortOption";
import { mapRemittances, RemittanceTypeMap, ServerRemittanceTypeMap } from "../mappers/mapRemittances";
import { ShortagesEscalationsPaymentDetails } from "src/pages/UserDashboard/Cases/api/types.ts";
const { downloadFile } = FileDownloadServices;

export interface ServerResponse<T> {
  data: T;
  message: string;
  status: number;
}

interface PaginatedServerResponse {
  pages: number;
  total_records: number;
  success: boolean;
}

interface FetchUsersResponse extends PaginatedServerResponse {
  users: {
    ID: string;
    NICKNAME: string;
    PROFILE_PIC: string;
  }[];
}

interface FetchAccountResponse extends PaginatedServerResponse {
  stores: {
    "Account ID": number;
    "Account Name": string;
  }[];
}

interface FetchUserPacketsSummaryResponse {
  num_of_work_packets: number;
  work_packet_red_flag: number;
  work_packets: Record<WorkPacketActionName, number>;
}

interface FetchNewPacketsSummaryResponse {
  num_of_work_packets: number;
  total_stores: number;
  recoverable_amount: {
    total: string;
  };
}

interface FetchAllPacketsSummaryResponse {
  num_of_work_packets: number;
  total_stores: number;
  recoverable_amount: {
    total: string;
    approved: string;
    pending: string;
  };
  by_status?: {
    status: string;
    count: number;
    remaining_open_balance: number;
    financial_charges?: number;
    chargeback_create_date?: string;
  }[];
}

interface FetchChangeLogResponse {
  change_log: ServerChangeLogEntry[];
}

interface FetchCaseChangeLogResponse {
  case_change_log: ServerChangeLogEntry[];
}

type ServerCombinedSummary =
  | FetchUserPacketsSummaryResponse
  | FetchNewPacketsSummaryResponse
  | FetchAllPacketsSummaryResponse;

interface RecoveryStreamFiltersResponse {
  recovery_stream_type: string[];
  recovery_stream_subtype_1: string[];
  recovery_stream_subtype_2: string[];
}

export interface EscalationPaymentDetailWithAction
  extends Omit<EscalationPaymentDetail, "disputedInvoiceId">,
    ChargebackEscalationPaymentDetail {
  action?: "TO_CREATE" | "TO_UPDATE" | "TO_DELETE";
}

export interface UpdatableValues {
  currentAction?: string;
  currentPacketOwner?: string;
  notes?: string;
  isValidWorkPacket?: boolean;
  evidenceType?: string;
  evidenceAttachment?: string;
  disputes?: AccrualDispute[];
  originalDisputedId?: string;
  consolidatedDisputeId?: string;
  paymentDetails?: EscalationPaymentDetailWithAction[];
  escalations?: (ShortageEscalation | ChargebackEscalation)[];
}

export type UpdatablePacketKey = keyof UpdatableValues;
export type UpdatableValue<K extends keyof UpdatableValues> = Required<UpdatableValues>[K];

const localKeyToServerKeyMap: Record<UpdatablePacketKey, string> = {
  currentAction: "CURRENT_ACTION",
  currentPacketOwner: "PACKET_OWNER_ID",
  notes: "NOTES",
  isValidWorkPacket: "VALID",
  evidenceType: "EVIDENCE_TYPE",
  evidenceAttachment: "EVIDENCE_ATTACHMENT",
  disputes: "DISPUTES",
  originalDisputedId: "DISPUTE_ID",
  consolidatedDisputeId: "CONSOLIDATED_DISPUTE_ID",
  paymentDetails: "PAYMENT_DETAILS",
  escalations: "CASE_ITEMS",
};

type ServerMapper<In, Out = any> = (value: In) => Out;
type ServerValueMappers = { [K in UpdatablePacketKey]?: ServerMapper<UpdatableValue<K>> };

const escalationPaymentDetailToServer = (pd: EscalationPaymentDetail): Partial<ShortagesEscalationsPaymentDetails> => ({
  ID: pd.id,
  CASE_ITEM_ID: pd.caseItemId,
  DISPUTED_INVOICE_ID: pd.disputedInvoiceId,
  CUSTOMER_INVOICE_AMOUNT: pd.customerInvoiceAmount,
  CUSTOMER_INVOICED_AT: pd.c6InvoiceDate ? pd.c6InvoiceDate.format("YYYY-MM-DD") : "",
  INVOICE_NUMBER_FOR_PAYMENT: pd.invoiceNumber,
  PAYMENT_DATE: pd.paymentDate ? pd.paymentDate.format("YYYY-MM-DD") : "",
  PAYMENT_ID: pd.paymentId,
  PAID_AMOUNT: pd.paidAmount,
});

interface ServerPaymentDetailsChanges {
  TO_CREATE: Partial<ShortagesEscalationsPaymentDetails>[];
  TO_UPDATE: Partial<ShortagesEscalationsPaymentDetails>[];
  TO_DELETE: string[]; // Only ids are needed
}

const serverValueMappers: ServerValueMappers = {
  disputes: (disputes): Partial<ServerAccrualDispute>[] =>
    disputes.map(dispute => ({
      ID: dispute.id,
      DISPUTE_ID: dispute.originalDisputeId,
      CONSOLIDATED_DISPUTE_ID: dispute.consolidatedDisputeId,
      CUSTOMER_INVOICE_STATUS: dispute.invoiceStatus,
      DISPUTE_AMOUNT: dispute.disputeAmount?.toString(),
    })),
  paymentDetails: (paymentDetails): ServerPaymentDetailsChanges =>
    paymentDetails.reduce<ServerPaymentDetailsChanges>(
      (acc, paymentDetail) => {
        switch (paymentDetail.action) {
          case "TO_CREATE":
            acc.TO_CREATE.push(escalationPaymentDetailToServer(paymentDetail as EscalationPaymentDetail));
            break;
          case "TO_UPDATE":
            acc.TO_UPDATE.push(escalationPaymentDetailToServer(paymentDetail as EscalationPaymentDetail));
            break;
          case "TO_DELETE":
            acc.TO_DELETE.push(paymentDetail.id);
            break;
        }
        return acc;
      },
      { TO_CREATE: [], TO_UPDATE: [], TO_DELETE: [] },
    ),
  escalations: escalations =>
    escalations.map((escalation: ShortageEscalation | ChargebackEscalation) => ({
      CASE_ITEM_ID: escalation.itemId,
      APPROVED_AMOUNT: escalation.approvedAmount
        ? escalation.approvedAmount.toString().replace(/[^\d\.]+/g, "")
        : undefined,
      STATUS: escalation.status,
    })),
};

export type SortDirection = "asc" | "desc";

export interface SortOption {
  key: string;
  direction: SortDirection;
}

export interface FetchWorkPacketListParams<T extends WorkPacketType> {
  type: T;
  page: number;
  pageSize: number;
  filters: WorkPacketFilters;
  sorting?: SortOption;
}

export interface FetchWorkPacketListReturn<T extends WorkPacket> {
  workPackets: T[];
  totalRecords: number;
  totalPages: number;
}

const workPacketTypeToFilterKeysMap: Record<WorkPacketType, WorkPacketFilter[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    WorkPacketFilter.AsinId,
    WorkPacketFilter.RecoveryStream,
    WorkPacketFilter.RecoveryStreamType,
    WorkPacketFilter.RecoveryStreamSubtype1,
    WorkPacketFilter.RecoveryStreamSubtype2,
    WorkPacketFilter.PacketStage,
    WorkPacketFilter.StoreName,
    WorkPacketFilter.VcPoId,
    WorkPacketFilter.CurrentAction,
    WorkPacketFilter.CurrentPacketOwner,
    WorkPacketFilter.VendorName,
    WorkPacketFilter.ChargebackRedFlags,
    WorkPacketFilter.IssueId,
    WorkPacketFilter.ChargebackCreatedAt,
    WorkPacketFilter.CalculatedDisputeByDate,
    WorkPacketFilter.FreightTerms,
    WorkPacketFilter.PreCase,
    WorkPacketFilter.InboundShipmentDeliveryIsdId,
  ],

  [WorkPacketType.SHORTAGES]: [
    WorkPacketFilter.VendorName,
    WorkPacketFilter.StoreName,
    WorkPacketFilter.PacketStage,
    WorkPacketFilter.CurrentAction,
    WorkPacketFilter.ManualFilingUser,
    WorkPacketFilter.RecoveryStreamType,
    WorkPacketFilter.CurrentPacketOwner,
    WorkPacketFilter.RecoveryStream,
    WorkPacketFilter.DisputeCreatedAt,
    WorkPacketFilter.RedFlags,
    WorkPacketFilter.PreCase,
    WorkPacketFilter.ParentInvoiceId,
    WorkPacketFilter.DisputedInvoiceId,
    WorkPacketFilter.NumberOfEscalations,
    WorkPacketFilter.InvoiceDueDate,
  ],

  [WorkPacketType.ACCRUALS]: [
    WorkPacketFilter.VendorName,
    WorkPacketFilter.StoreName,
    WorkPacketFilter.ManualFilingUser,
    WorkPacketFilter.CurrentPacketOwner,
    WorkPacketFilter.ObarCreatedDate,
    WorkPacketFilter.RecoveryStreamSubtype,
    WorkPacketFilter.AgreementId,
    WorkPacketFilter.InvoiceDate,
    WorkPacketFilter.InvoiceNumber,
    WorkPacketFilter.DisputeCreatedAt,
    WorkPacketFilter.OriginalDisputeId,
    WorkPacketFilter.ConsolidatedDisputeId,
    WorkPacketFilter.CurrentAction,
    WorkPacketFilter.DisputeStatus,
    WorkPacketFilter.WithNotes,
  ],
};

const dateRangeFilters: WorkPacketFilter[] = [
  WorkPacketFilter.DisputeCreatedAt,
  WorkPacketFilter.ChargebackCreatedAt,
  WorkPacketFilter.CalculatedDisputeByDate,
  WorkPacketFilter.InvoiceDate,
  WorkPacketFilter.ObarCreatedDate,
  WorkPacketFilter.InvoiceDueDate,
];

export const computeFilters = (
  filters: WorkPacketFilters | WorkPacketSummaryFilters,
  workPacketType: WorkPacketType,
) => {
  const packetTypeFilters = workPacketTypeToFilterKeysMap[workPacketType];
  const computedFilters = Object.fromEntries(
    Object.entries(filters).filter(([key, val]) => {
      if (Array.isArray(val) && !val.length) return false;
      if (val === "" || val === false) return false;
      return packetTypeFilters.includes(key as WorkPacketFilter);
    }),
  );

  for (const dateRangeFilterName of dateRangeFilters) {
    if (dateRangeFilterName in computedFilters) {
      const dateRange = parseDateRange(computedFilters[dateRangeFilterName]);
      computedFilters[dateRangeFilterName] = dateRange?.map(date => date.format("YYYY-MM-DD")) ?? "";
    }
  }

  // add default current action filters in pre-case - server allows receiving an array of actions
  if (filters[WorkPacketFilter.PreCase] && !computedFilters[WorkPacketFilter.CurrentAction]) {
    if (workPacketType === WorkPacketType.CHARGEBACKS) {
      computedFilters[WorkPacketFilter.CurrentAction] = [
        createStagefulAction(StageName.Second, StagefulAction.DisputeDenied).value,
        createStagefulAction(StageName.Second, StagefulAction.DisputePartiallyApproved).value,
      ];
    } else if (workPacketType === WorkPacketType.SHORTAGES) {
      computedFilters[WorkPacketFilter.CurrentAction] = [
        createStagelessAction(StagelessAction.ReadyForReview).value,
        createStagelessAction(StagelessAction.NotPursuing).value,
      ];
    }
  }

  return computedFilters;
};

export async function fetchWorkPackets<T extends WorkPacketType>({
  type,
  page,
  pageSize,
  filters,
  sorting,
}: FetchWorkPacketListParams<T>): Promise<FetchWorkPacketListReturn<WorkPacketTypeMap[T]>> {
  type ServerResponse = WorkPacketServerResponse<ServerWorkPacketTypeMap[T]>;
  const computedSortOption = sorting && computeSortOption(sorting, type);
  const response = await apiClient.get<ServerResponse>(`/api/v2/work_packets/${type}`, {
    params: {
      page: page + 1,
      per_page: pageSize,
      filters: JSON.stringify(computeFilters(filters, type)),
      sorting: JSON.stringify(
        computedSortOption ? { [computedSortOption.key]: computedSortOption.direction } : EMPTY_OBJECT,
      ),
    },
    errorMessage: "Error while fetching work packets",
  });

  return {
    workPackets: mapServerToLocalWorkPackets(type, response.data.work_packets),
    totalRecords: response.data.total_records,
    totalPages: response.data.pages,
  };
}

export async function fetchWorkPacketById<T extends WorkPacketType>({
  workPacketType,
  workPacketId,
  signal,
}: {
  workPacketType: T;
  workPacketId: string;
  signal?: AbortSignal;
}): Promise<WorkPacketTypeMap[T]> {
  type ServerResponse = WorkPacketServerResponse<ServerWorkPacketTypeMap[T]>;
  const response = await apiClient.get<ServerResponse>(`/api/v2/work_packets/${workPacketId}/${workPacketType}`, {
    signal,
    errorMessage: "Error while fetching work packet by ID.",
  });
  return mapServerToLocalWorkPackets(workPacketType, response.data.work_packets)[0];
}


export async function fetchWorkPacketRemittances<T extends WorkPacketType>({
  workPacketType,
  workPacketId,
  signal,
}: {
  workPacketType: T;
  workPacketId: string;
  signal?: AbortSignal;
}): Promise<RemittanceTypeMap[T][]> {
  const response = await apiClient.get<ServerResponse<ServerRemittanceTypeMap[T][]>>(
    `/api/v2/work_packets/${workPacketId}/remittances`,
    {
      signal,
      errorMessage: "Error while fetching work packet remittance.",
    },
  );
  return mapRemittances(workPacketType, response.data.data);
}

export async function fetchAccounts(): Promise<{ value: string; title: string }[]> {
  const response = await apiClient.get("/api/v1/accounts", { errorMessage: "Error while fetching accounts." });
  const data = response.data as FetchAccountResponse;
  return data.stores.map(account => ({
    value: account["Account Name"],
    title: account["Account Name"],
  }));
}

export async function fetchUsers(): Promise<{ value: string; title: string; avatar: string }[]> {
  const response = await apiClient.get("/api/v2/users", { errorMessage: "Error while fetching users." });
  const data = response.data as FetchUsersResponse;

  return data.users.map(user => ({
    value: user.ID,
    title: user.NICKNAME,
    avatar: user.PROFILE_PIC,
  }));
}

const mapUpdatedLocalFieldsToServerParams = (updatedFields: UpdatableValues): Record<string, any> =>
  Object.fromEntries(
    Object.entries(updatedFields as Record<UpdatablePacketKey, UpdatableValues[UpdatablePacketKey]>).map(
      ([_key, val]: [string, UpdatableValues[UpdatablePacketKey]]) => {
        const key = _key as UpdatablePacketKey;
        const mapper = serverValueMappers[key] as ServerMapper<typeof val>;
        return [
          // map from local key to server (all caps) key
          localKeyToServerKeyMap[key] || key,
          val === "" ? null : mapper ? mapper(val) : val,
        ];
      },
    ),
  );

export async function updateWorkPacket<T extends WorkPacketType>({
  workPacketType,
  workPacketId,
  updatedFields,
}: {
  workPacketType: WorkPacketType;
  workPacketId: string;
  updatedFields: UpdatableValues;
}) {
  const serverUpdateParams = mapUpdatedLocalFieldsToServerParams(updatedFields);

  type ServerResponse = WorkPacketServerResponse<ServerWorkPacketTypeMap[T]>;

  const response = await apiClient.put<ServerResponse>(
    `/api/v2/work_packets/${workPacketType}`,
    { work_packet_id: workPacketId, update_params: serverUpdateParams },
    { errorMessage: "Error while updating work packet." },
  );
  return response.data;
}

const createFormDataPayload = (payload: Record<string, any>): FormData => {
  const data = new FormData();
  for (const [key, val] of Object.entries(payload)) {
    data.set(key, JSON.stringify(val));
  }
  return data;
};

export interface BulkUpdateWorkPacketsParams {
  workPacketType: WorkPacketType;
  workPacketIds: string[];
  excludeIds: string[];
  filters: WorkPacketFilters;
  fields: UpdatableValues;
  files?: File[];
  onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
}

export async function bulkUpdateWorkPackets({
  workPacketType,
  workPacketIds,
  excludeIds,
  filters,
  fields,
  files,
  onUploadProgress,
}: BulkUpdateWorkPacketsParams): Promise<any> {
  const updatedFields = Object.entries(fields).reduce((acc, [key, value]) => {
    // Add any other fields that should allow empty strings
    const allowEmptyFields = ["note"];

    if (value !== undefined && value !== null && (value !== "" || allowEmptyFields.includes(key))) {
      const serverKey = localKeyToServerKeyMap[key as UpdatablePacketKey] || key;
      acc[serverKey] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  const payload = createFormDataPayload({
    work_packet_ids: workPacketIds,
    excluded_work_packets: excludeIds,
    filters: computeFilters(filters, workPacketType),
    update_params: updatedFields,
  });

  if (files) {
    for (const file of files) {
      payload.append("files", file);
    }
  }

  const response = await apiClient.put(`/api/v2/work_packets/${workPacketType}/bulk_update`, payload, {
    adapter: "xhr",
    headers: { "Content-Type": "multipart/form-data" },
    errorMessage: "Error while bulk updating.",
    onUploadProgress,
  });

  return response.data;
}

/** Used internally to map the server response to the current view, based on the type of summary data. */
function mapServerSummaryToCurrentView(
  summaryData: ServerCombinedSummary,
  type: "user" | "new" | "all",
): WorkPacketsSummaryAggregated {
  let data;
  switch (type) {
    case "user":
      data = summaryData as FetchUserPacketsSummaryResponse;
      return {
        totalWorkPackets: data.num_of_work_packets,
        totalRedFlags: data.work_packet_red_flag,
        workPackets: Object.entries(data.work_packets).map(([action, amount]) => ({
          action: action as WorkPacketActionName,
          amount,
        })),
      };
    case "new":
      data = summaryData as FetchNewPacketsSummaryResponse;
      return {
        totalWorkPackets: data.num_of_work_packets,
        totalStores: data.total_stores,
        recoverableAmount: {
          total: data.recoverable_amount.total,
        },
      };
    case "all":
      data = summaryData as FetchAllPacketsSummaryResponse;
      return {
        totalWorkPackets: summaryData.num_of_work_packets,
        totalStores: data.total_stores,
        recoverableAmount: {
          total: data.recoverable_amount.total,
          approved: data.recoverable_amount.approved,
          pending: data.recoverable_amount.pending,
        },
        byStatus: data.by_status?.map(
          ({ status, count, remaining_open_balance, financial_charges, chargeback_create_date }) => ({
            status,
            count,
            remainingOpenBalance: remaining_open_balance || 0,
            financialCharge: financial_charges || 0,
            chargebackCreateDate: chargeback_create_date || "",
          }),
        ),
      };
  }
}

/** Fetches the summary of work packets for the current user. */
export async function fetchUserPacketsSummary(
  workPacketType: WorkPacketType,
  preCase?: boolean,
  _filters?: WorkPacketSummaryFilters,
): Promise<WorkPacketsSummaryAggregated> {
  const preCaseParam = preCase ? "?pre_case=true" : "";
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/summary/my${preCaseParam}`, {
    errorMessage: "Error while fetching user work packet summary.",
  });

  return mapServerSummaryToCurrentView(response.data.data, "user");
}

/** Fetches the summary of new work packets. */
export async function fetchNewPacketsSummary(
  workPacketType: WorkPacketType,
  preCase?: boolean,
  _filters?: WorkPacketSummaryFilters,
): Promise<WorkPacketsSummaryAggregated> {
  const preCaseParam = preCase ? "?pre_case=true" : "";
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/summary/new${preCaseParam}`, {
    errorMessage: "Error while fetching new packets summary.",
  });
  return mapServerSummaryToCurrentView(response.data.data, "new");
}

/** Fetches the summary of all work packets. */
export async function fetchAllPacketsSummary(
  workPacketType: WorkPacketType,
  preCase?: boolean,
  filters?: WorkPacketSummaryFilters,
): Promise<WorkPacketsSummaryAggregated> {
  const params: Record<string, string> = {};
  if (preCase) {
    params["pre_case"] = "true";
  }
  if (filters) {
    params["filters"] = JSON.stringify(computeFilters(filters, workPacketType));
  }

  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/summary/all`, {
    params,
    errorMessage: "Error while fetching new packets summary.",
  });

  return mapServerSummaryToCurrentView(response.data.data, "all");
}

/** Fetches the change logs for a specific work packet. */
export async function fetchChangeLogs(workPacketId: string, workPacketType: WorkPacketType): Promise<ChangelogDate[]> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketId}/change_logs`, {
    errorMessage: "Error while fetching change logs.",
  });

  const data = response.data as FetchChangeLogResponse;

  return mapServerChangeLog({ serverChangeLog: data.change_log, workPacketType });
}

export async function fetchCommandCenterData(
  workPacketType: WorkPacketType,
  signal?: AbortSignal,
): Promise<CommandCenterGridRow[]> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/command_centre`, {
    signal,
    errorMessage: "Error while fetching command centre data.",
  });

  return mapCommandCenterData(response.data);
}

export async function fetchRecoveryStreamFilters(workPacketType: WorkPacketType): Promise<any> {
  const response = await apiClient.get(`/api/v2/${workPacketType}/sub-types`, {
    errorMessage: "Error while fetching fetch recovery stream filters.",
  });
  const data = response.data as RecoveryStreamFiltersResponse;

  const mapToValueTitle = (arr: string[]) => arr.map(value => ({ value, title: value }));

  return {
    recoveryStreamType: mapToValueTitle(data.recovery_stream_type),
    recoveryStreamSubtype1: mapToValueTitle(data.recovery_stream_subtype_1),
    recoveryStreamSubtype2: mapToValueTitle(data.recovery_stream_subtype_2),
  };
}

/** Downloads a CSV file with work packets. */
export async function exportWorkPackets({
  workPacketType,
  userId,
  filters,
}: {
  workPacketType: WorkPacketType;
  userId: string;
  filters: WorkPacketFilters;
}): Promise<void> {
  const params = {
    user_id: userId,
    filters: JSON.stringify(computeFilters(filters, workPacketType)),
  };

  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/export`, {
    params,
    responseType: "blob",
    errorMessage: "Error while exporting work packets.",
  });

  downloadFile(response, "work_packets", "csv");
}

export async function getWorkPacketAttachments(
  workPacketId: string,
  signal?: AbortSignal,
): Promise<ServerModelAttachment[]> {
  return apiClient
    .get(`/api/v2/work_packets/${workPacketId}/attachments`, { signal })
    .then(res => res.data.model_attachments);
}

export async function uploadWorkPacketAttachment(
  workPacketId: string,
  file: File,
  onUploadProgress?: AxiosRequestConfig["onUploadProgress"],
): Promise<any> {
  const formData = new FormData();
  formData.set("file", file);
  formData.set("file_name", file.name);
  formData.set("model", "work_packets");
  formData.set("model_id", workPacketId);

  return apiClient.post(`/api/v2/attachments`, formData, {
    adapter: "xhr",
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  });
}

export async function getWorkPacketAttachmentLink(attachmentId: string): Promise<string> {
  const response = await apiClient.get(`/api/v2/attachments/${attachmentId}`);
  return response.data.attachment.url;
}

export async function createAccrualDispute(workPacketId: string): Promise<void> {
  const response = await apiClient.post(`/api/v2/work_packets/${workPacketId}/accruals/dispute`);
  return response.data;
}

/** Fetches the change logs for a specific case. */
export async function fetchCaseChangeLogs(caseId: string, workPacketType: WorkPacketType): Promise<ChangelogDate[]> {
  const response = await apiClient.get<FetchCaseChangeLogResponse>(`/api/v2/cases/${caseId}/case_change_logs`, {
    errorMessage: "Error while fetching change logs.",
  });

  const data = response.data;
  return mapServerChangeLog({ serverChangeLog: data.case_change_log, workPacketType, isCaseChangeLog: true });
}
