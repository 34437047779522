import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient, type UseMutationOptions } from "@tanstack/react-query";
import { AutocompleteField, SelectField, TextAreaField } from "components/UI/Form";
import { Field, Form, Formik } from "formik";
import { useCallback, type FC } from "react";
import toast from "react-hot-toast";
import usePermissions from "src/components/CustomHooks/usePermissions";
import { PermissionLevel, WorkPacketFilter, type WorkPacketFilters, type WorkPacketOwner } from "src/types/work-packets";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { bulkUpdateWorkPackets, type BulkUpdateWorkPacketsParams, type UpdatableValues } from "../api/workPacketsAPI";
import { useUserOptions } from "../useUserOptions";
import { useDialogActionStore } from "../WorkPacketDetailsPopup/DialogActionContext";
import { FileUploadBox } from "../WorkPacketDetailsPopup/Summary/components/FileUploadBox";
import { FileUploadContext, type FileUploadStatus } from "../WorkPacketDetailsPopup/Summary/FileUploadContext";
import { useFileUploadStore } from "../WorkPacketDetailsPopup/Summary/useFileUploadStore";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import { WorkPacketType } from "../WorkPacketType";
import {
  getEditWorkPacketActionOptions,
} from "./helpers/getStaticFilterOptions";
import { WORK_PACKET_SUPERVISOR_PERMISSIONS } from "src/pages/UserDashboard/WorkPackets/WorkPacketPermission";

interface BulkUpdatePopup {
  workPacketIds?: string[];
  excludeIds?: string[];
  filters: WorkPacketFilters;
  selectedAll?: boolean;
  open: boolean;
  allowEditPacketActionAndNotes: boolean;
  onClose: (success?: boolean) => void;
}

const bulkUpdateMutationOptions: UseMutationOptions<void, Error, BulkUpdateWorkPacketsParams> = {
  mutationFn: bulkUpdateWorkPackets,
  mutationKey: ["work-packets-bulk-update"],
  onError: err => {
    toast.error("Error updating work packets");
    console.error(err);
  },
};

export const BulkUpdatePopup: FC<BulkUpdatePopup> = ({
  workPacketIds,
  excludeIds,
  open,
  filters,
  selectedAll,
  allowEditPacketActionAndNotes,
  onClose,
}) => {
  const { user } = useAuth0();
  const { currentWorkPacketType, currentFilters } = useWorkPacketsContext();
  const queryClient = useQueryClient();

  const { isAdmin, hasPermission } = usePermissions();
  const isPreCaseView = !!currentFilters[WorkPacketFilter.PreCase];
  const isSupervisor = hasPermission(WORK_PACKET_SUPERVISOR_PERMISSIONS[currentWorkPacketType]);

  const permissionLevel = isAdmin
    ? PermissionLevel.Admin
    : isSupervisor
    ? PermissionLevel.Supervisor
    : PermissionLevel.Default;

  const actionOptions = getEditWorkPacketActionOptions(currentWorkPacketType, isPreCaseView, permissionLevel);

  const dialogActionStore = useDialogActionStore();

  const initialValues: UpdatableValues = {
    [WorkPacketFilter.CurrentAction]: "",
    [WorkPacketFilter.CurrentPacketOwner]: "",
    isValidWorkPacket: undefined,
    notes: "",
  };

  const bulkUpdateMutation = useMutation(bulkUpdateMutationOptions);
  const loading = bulkUpdateMutation.isPending;

  const fileUploadStore = useFileUploadStore({
    dialogActionStore,
    existingFiles: EMPTY_ARRAY,
    isLoadingExistingFiles: false,
    isUploading: bulkUpdateMutation.isPending,
  });

  const setUploadStatus = useCallback(
    (uploadStatus: FileUploadStatus) => {
      for (const fileUpload of fileUploadStore.fileUploads) {
        fileUploadStore.setFileUploadStatus(fileUpload.file.name, uploadStatus);
      }
    },
    [fileUploadStore],
  );

  const onFormSubmit = async (fields: typeof initialValues) => {
    if (!user?.sub) return;
    await bulkUpdateMutation
      .mutateAsync({
        workPacketType: currentWorkPacketType,
        workPacketIds: selectedAll ? [] : workPacketIds || [],
        excludeIds: selectedAll ? excludeIds || [] : [],
        filters,
        fields,
        files: fileUploadStore.fileUploads.map(u => u.file),
        onUploadProgress: uploadProgress => setUploadStatus({ status: "uploading", uploadProgress }),
      })
      .then(() => setUploadStatus({ status: "uploaded" }))
      .catch(error => setUploadStatus({ status: "failed", error }));
    queryClient.invalidateQueries({ queryKey: ["work-packets", currentWorkPacketType] });
    onClose(true);  // `true` invalidates work packet list data
  };

  const currentOwnerOptions = useUserOptions();

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>Bulk Actions</DialogTitle>

      <Formik initialValues={initialValues} onSubmit={onFormSubmit}>
        {({ setFieldValue, dirty }) => (
          <Form>
            <DialogContent>
              <Divider />

              <Box display="flex" flexDirection="column" mb={4} gap={2}>
                <Box
                  display="grid"
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                    },
                    gap: 2,
                    rowGap: 3,
                  }}
                >
                  {actionOptions && (
                    <Field
                      name={WorkPacketFilter.CurrentAction}
                      component={AutocompleteField}
                      label="Current Action"
                      options={actionOptions}
                      groupBy={(option: any) => option.category}
                      getOptionLabel={(option: any) => option.title}
                      placeholder="Current Action"
                      setFieldValue={setFieldValue}
                      disabled={loading || !allowEditPacketActionAndNotes}
                    />
                  )}

                  <Field
                    name={WorkPacketFilter.CurrentPacketOwner}
                    component={AutocompleteField}
                    label="Current Packet Owner"
                    options={currentOwnerOptions}
                    getOptionLabel={(option: WorkPacketOwner) => option.title}
                    placeholder="Current Packet Owner"
                    setFieldValue={setFieldValue}
                    disabled={loading}
                  />

                  {currentWorkPacketType === WorkPacketType.CHARGEBACKS && (
                    <Field
                      name="isValidWorkPacket"
                      value
                      component={SelectField}
                      label="Is Valid Chargeback?"
                      options={[
                        { value: null, label: "(unchanged)" },
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                    />
                  )}

                  {currentWorkPacketType === WorkPacketType.ACCRUALS && (
                    <Field
                      name={WorkPacketFilter.ConsolidatedDisputeId}
                      as={TextField}
                      variant="outlined"
                      label="Consolidated Dispute ID"
                      placeholder="Enter Consolidated Dispute ID"
                      size="small"
                      fullWidth
                    />
                  )}

                  {currentWorkPacketType === WorkPacketType.ACCRUALS && (
                    <Field
                      name={WorkPacketFilter.OriginalDisputeId}
                      as={TextField}
                      variant="outlined"
                      label="Original Dispute ID"
                      placeholder="Enter Original Dispute ID"
                      size="small"
                      fullWidth
                    />
                  )}
                </Box>

                <Field
                  name="notes"
                  component={TextAreaField}
                  label="Notes"
                  disabled={loading || !allowEditPacketActionAndNotes}
                />

                {currentWorkPacketType === WorkPacketType.ACCRUALS && (
                  <Box>
                    <Typography fontWeight={500} mb={2}>
                      Evidence Attachments
                    </Typography>
                    <FileUploadContext.Provider value={fileUploadStore}>
                      <FileUploadBox
                        accept=".doc, .docx, .xls, .xlsx, .xlsv, .csv, .png, .jpg, .jpeg"
                        acceptHint="DOC(X), XLS(X), CSV, PDF, PNG, JPG"
                      />
                    </FileUploadContext.Provider>
                  </Box>
                )}
              </Box>
            </DialogContent>

            <DialogActions>
              <Button className="contained-reversed" variant="contained" onClick={() => onClose()} disabled={loading}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!(dirty || fileUploadStore.hasPendingUploads) || loading}
                sx={{ paddingX: "24px" }}
              >
                <span>Save Changes</span>
                {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
