import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { type FC } from "react";
import { type AccrualWorkPacket } from "src/types/work-packets";
import { getAccrualRemittances } from "../../../queries/work-packets";
import { WorkPacketType } from "../../../WorkPacketType";
import { FormFieldsGroup } from "../components";
import { ReadOnlyTextField } from "src/components/UI/Form/ReadOnlyTextField";

export const AccrualRemittances: FC = () => {
  const { values: packet } = useFormikContext<AccrualWorkPacket>();
  const query = useQuery({
    queryKey: ["work-packets", WorkPacketType.ACCRUALS, "remittance", packet.packetId],
    queryFn: getAccrualRemittances,
  });

  if (query.isPending) {
    return <Typography paddingTop={3}>Loading remittance data...</Typography>;
  }

  if (query.isError) {
    return <Typography paddingTop={3}>Failed to load remittance data</Typography>;
  }

  if (!query.data.length) {
    return <Typography paddingTop={3}>No payment details associated with this work packet.</Typography>;
  }

  return (
    <>
      {query.data.map((remittance, index) => {
        const remittanceNumber = index + 1;
        const id = `remittance-${index}`;

        return (
          <FormFieldsGroup withDivider title={`Payment Details ${remittanceNumber}`} id={id} key={id}>
            <ReadOnlyTextField label="Paid Amount" value={remittance.paidAmount} />
            <ReadOnlyTextField label="Reversal Invoice ID" value={remittance.reversalInvoiceNumber} />
            <ReadOnlyTextField label="Payment ID" value={remittance.paymentId} />
            <ReadOnlyTextField label="Payment Date" value={remittance.paymentDate} />
          </FormFieldsGroup>
        );
      })}
    </>
  );
};
