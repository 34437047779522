import {
  BaseCase,
  Case, CaseFields,
  CaseType, ChargebackEscalationCase, EscalationCaseFields, SettlementCaseFields,
  ShortageEscalationCase,
  ShortageSettlementCase,
} from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { ServerCase } from "src/pages/UserDashboard/WorkPackets/Cases/casesApi";
import { formatDate } from "src/utils/date-parse";

const mapBaseCase = (serverCase: ServerCase): BaseCase => {
  return {
    [CaseFields.ChargeGuardCaseId]: serverCase.ID,
    [CaseFields.AmazonCaseId]: serverCase.AMAZON_CASE_ID,
    [CaseFields.NumOfPackets]: serverCase.NO_OF_ITEMS,
    [CaseFields.VendorName]: serverCase.ACCOUNT?.VENDOR?.VENDOR_NAME || "",
    [CaseFields.StoreName]: serverCase.ACCOUNT?.STORE_NAME || "",
    [CaseFields.CurrentCaseOwner]: serverCase.CASE_OWNER?.NAME || "",
    [CaseFields.CurrentAction]: serverCase.STATUS,
    [CaseFields.CreatedAt]: formatDate(serverCase.AMAZON_CASE_CREATED_DATE),
    [CaseFields.ManualFilingUser]: serverCase.ACCOUNT?.VC_FILING_USER || "",
    [CaseFields.RemainingOpenBalance]: serverCase.REMAINING_OPEN_BALANCE,
    [CaseFields.CurrentCaseNumber]: serverCase.AMAZON_CASE_ID || "",
    [CaseFields.InvoiceEndDueDate]: serverCase.INVOICE_END_DUE_DATE || "",
    [CaseFields.InvoiceStartDueDate]: serverCase.INVOICE_START_DUE_DATE || "",
    [CaseFields.Link]:
    "https://vendorcentral.amazon.com/cu/case-dashboard/view-case ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=" +
    serverCase.AMAZON_CASE_ID,
    [CaseFields.Payee]: serverCase.PAYEE || "",
    [CaseFields.SubmissionAmt]: serverCase.REMAINING_OPEN_BALANCE,
    [CaseFields.SubmissionDate]: serverCase.AMAZON_CASE_CREATED_DATE,
  };
};

export const mapServerChargebackEscalationsToLocalCase = (serverCases: ServerCase[]): ChargebackEscalationCase[] => {
  return serverCases.map((serverCase): ChargebackEscalationCase => {
    return {
      ...mapBaseCase(serverCase),
      [EscalationCaseFields.ApprovedAmount]: serverCase.ESCALATION_APPROVED_AMOUNT || 0,
      [EscalationCaseFields.ApprovedDate]: serverCase.CHARGEBACKS_ESCALATION_CASE_DETAILS?.APPROVED_DATE || "",
    };
  });
};

export const mapServerShortageEscalationsToLocalCase = (serverCases: ServerCase[]): ShortageEscalationCase[] => {
  return serverCases.map((serverCase): ShortageEscalationCase => {
    return {
      ...mapBaseCase(serverCase),
      [EscalationCaseFields.ApprovedAmount]: serverCase.ESCALATION_APPROVED_AMOUNT || 0,
      [EscalationCaseFields.ApprovedDate]: serverCase.SHORTAGES_ESCALATION_CASE_DETAILS?.APPROVED_DATE || "",
    };
  });
};

export const mapServerShortageSettlementsToLocalCase = (serverCases: ServerCase[]): ShortageSettlementCase[] => {
  return serverCases.map((serverCase): ShortageSettlementCase => {
    const currentAmount = serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.CURRENT_AMOUNT || 0;
    const alignedBalanceAmount = serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.ALIGNED_BALANCE_AMOUNT || 0;
    const validAmount = serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.VALID_AMOUNT || 0;
    const finalAmount = serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.FINAL_AMOUNT || 0;

    const amountCalc = alignedBalanceAmount - validAmount;
    const currentRRCalc = amountCalc > 0 ? currentAmount / amountCalc : 0;
    const finalRecoveryRateCalc = amountCalc > 0 ? finalAmount / amountCalc : 0;

    return {
      ...mapBaseCase(serverCase),
      [SettlementCaseFields.PresubmissionRequired]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.PRESUBMISSION_REQUIRED || false,
      [SettlementCaseFields.Business]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.BUSINESS || "",
      [SettlementCaseFields.Sprinter]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.SPRINTER || "",
      [SettlementCaseFields.SprinterViewedAt]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.SPRINTER_VIEWED_AT || "",
      [SettlementCaseFields.Processor]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.PROCESSOR || "",
      [SettlementCaseFields.ProcessorViewedAt]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.PROCESSOR_VIEWED_AT || "",
      [SettlementCaseFields.AlignedBalanceAmount]: alignedBalanceAmount,
      [SettlementCaseFields.CurrentAmount]: currentAmount,
      [SettlementCaseFields.CurrentRRCalc]: currentRRCalc,
      [SettlementCaseFields.FinalRecoveryRateCalc]: finalRecoveryRateCalc,
      [SettlementCaseFields.NumOfSettlements]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.SETTLEMENT_COUNT || 0,
      [SettlementCaseFields.Stage]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.STAGE || "",
      [SettlementCaseFields.SubmissionPath]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.SUBMISSION_PATH || "",
      [SettlementCaseFields.ValidAmount]: validAmount,
      [SettlementCaseFields.VendorCodes]: serverCase.SHORTAGES_SETTLEMENT_CASE_DETAILS?.VENDOR_CODES || "",
    };
  });
};

export const serverToLocalCasesMap: Record<CaseType, (_: ServerCase[]) => Case[]> = {
  [CaseType.CHARGEBACK_ESCALATION]: mapServerChargebackEscalationsToLocalCase,
  [CaseType.SHORTAGE_ESCALATION]: mapServerShortageEscalationsToLocalCase,
  [CaseType.SHORTAGE_SETTLEMENT]: mapServerShortageSettlementsToLocalCase,
};
