import React from "react";
import CreatableSelect from "react-select/creatable";
import { useField } from "formik";
import { Label } from "./Label";

export const FormikCreatableSelect = ({
  label,
  options,
  mandatory = false,
  showErrorWithOutTouch = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);

  const handleInputChange = newValue => {
    const value = newValue ? newValue.label : null;
    if (value !== field.value) {
      helpers.setValue(value);
    }
  };

  const handleCreateOption = inputValue => {
    const existingOption = options.find(option => option.label === inputValue);
    if (!existingOption) {
      const newOption = { label: inputValue, value: inputValue };
      const newOptions = [...options, newOption];
      props.setOptions(newOptions);
      helpers.setValue(inputValue);
    }
  };

  const formatCreateLabel = inputValue => (
    <>
      Create: <strong>{inputValue}</strong>
    </>
  );

  return (
    <div>
      <Label label={label} mandatory={mandatory} />
      <CreatableSelect
        id={props.name}
        options={options}
        onChange={handleInputChange}
        onCreateOption={handleCreateOption}
        formatCreateLabel={formatCreateLabel}
        value={field.value ? { label: field.value, value: field.value } : null}
        isClearable
        {...props}
      />
      {(meta.touched || showErrorWithOutTouch) && meta.error ? <div className="text-red">{meta.error}</div> : null}
    </div>
  );
};
