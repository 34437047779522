import { QueryClient, useMutation } from "@tanstack/react-query";
import { CommentData, ServerResponse, TogglePinArgs } from "src/types/comments";
import { ListModelCommentsKey } from "./useListModelComments";
import { patchIsPinned } from "../api/commentsApi";

export interface MutationContext {
  prevComments: CommentData[];
}

export function useTogglePinMutation(listCommentQueryKey: ListModelCommentsKey, queryClient: QueryClient) {
  return useMutation<ServerResponse, unknown, TogglePinArgs, MutationContext>({
    mutationFn: ({ commentId, newPin }) => patchIsPinned(commentId, newPin),
    onMutate: async variables => {
      // Cancel any outgoing queries to avoid conflicts
      await queryClient.cancelQueries({ queryKey: listCommentQueryKey });

      // Snapshot the previous value
      const prevComments = queryClient.getQueryData<CommentData[]>(listCommentQueryKey);

      // Optimistically update the query data
      queryClient.setQueryData(listCommentQueryKey, (old: CommentData[]) => {
        return old.map(comment =>
          comment.id === variables.commentId ? { ...comment, isPinned: variables.newPin } : comment,
        );
      });

      // Return a context object with the snapshot value for rollback
      return { prevComments: prevComments ?? [] };
    },
    onError: (err, _variables, context) => {
      console.log("Error during togglePinMutation");
      console.error(err);
      // Roll back to the previous value if the mutation fails
      if (context?.prevComments) {
        queryClient.setQueryData(listCommentQueryKey, context.prevComments);
      }
    },
    onSuccess: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: listCommentQueryKey });
    },
  });
}
