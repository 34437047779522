import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addVendor } from "../../Api/Vendors/Vendors";
import "../../Customers.css";
import useVendorReferrals from "../../hooks/useVendorReferrals";
import { Formik } from "formik";
import VendorForm from "../VendorForm/VendorForm";
import { vendorSchema } from "../../util/schema";
import { mapToCreateVendor } from "../../util/mapper";

const CreateVendor = ({ getData }) => {
  const [referralAll, setReferralAll] = useState([]);
  const { data: vendorReferrals, loading: loadingReferrals } = useVendorReferrals();

  useEffect(() => {
    setReferralAll(
      vendorReferrals?.map(referral => {
        return { label: referral.NAME, value: referral.ID.toString() };
      }),
    );
  }, [loadingReferrals, vendorReferrals]);

  const initialValues = {
    REFERRAL: "",
    REFERRAL_COMMISSION_RATE: "",
    REFERRAL_COMMISSION_DURATION: "",
    REFERRAL_PARTNER_ID: "",
    REFERRAL_PAYMENT_METHOD: "",
    REFERRAL_PARTNER_TYPE: "",
    REFERRAL_CUSTOM_COMMISSION_RATE: "",
    REFERRAL_2: "",
    REFERRAL_2_COMMISSION_RATE: "",
    REFERRAL_2_COMMISSION_DURATION: "",
    REFERRAL_2_PARTNER_ID: "",
    REFERRAL_2_PAYMENT_METHOD: "",
    REFERRAL_2_PARTNER_TYPE: "",
    REFERRAL_2_CUSTOM_COMMISSION_RATE: "",
    SPECIFIC_POINT_OF_CONTACT_NAME: "",
    SPECIFIC_POINT_OF_CONTACT_EMAIL: "",
    VENDOR_NAME: "",
    TIER: "",
    INITIAL_COMMISSION_RATE: "",
    FINAL_COMMISSION_RATE: "",
    ENGAGEMENT_FEE: "",
    CURRENT_RECOVERY: "",
    NOTES: "",
    RECOVERY_THRESHOLD: "",
    AGREEMENT_SIGNED_DATE: "",
    KICK_OFF: "",
    PHONE: "",
    BILLING_NAME: "",
    BILLING_EMAIL: "",
    BILLING_ENTITY: "",
    VENDOR_TYPE: "",
  };

  const handleVendorSubmission = async (values, { resetForm }) => {
    const mappedData = mapToCreateVendor(values);
    await addVendor(mappedData, toast, getData);
    resetForm();
  };

  return (
    <div className="container mt-4">
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={handleVendorSubmission}
        validationSchema={vendorSchema}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <VendorForm
            isSubmitting={isSubmitting}
            dirty={dirty}
            isValid={isValid}
            values={values}
            referralAll={referralAll}
            setReferralAll={setReferralAll}
            disableCurrentRecovery={false}
          />
        )}
      </Formik>
    </div>
  );
};

export default CreateVendor;
