import {
  GridEditInputCell,
  GridEditSingleSelectCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import { ComponentProps, FC } from "react";
import { GridBaseColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { CaseItemKeys } from "../../../../../Cases/mappers/mapServerToLocalCase";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import { Autocomplete, Box, Tooltip, TooltipProps } from "@mui/material";
import { GridColDef, useGridApiContext } from "@mui/x-data-grid";
import {
  shortagesCaseItemStatusMap,
  ShortagesEscalationCaseItemStatusOption,
} from "src/pages/UserDashboard/Cases/api/constants.ts";
import TextField from "@mui/material/TextField";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types.ts";
import { CaseItemsActionButtons } from "../../../columns/CaseItemActionButtons";
import theme from "src/styles/theme";

const ErrorTooltip: FC<TooltipProps> = ({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{
      tooltip: {
        sx: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.contrastText,
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      },
    }}
  />
);

  /**
 * MUI Grid `renderCell` function cannot directly call any hooks. This function wraps a `renderCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderCell =
  (
    Component: FC<GridRenderCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderCell"] =>
    props => <Component {...props} {...componentProps} />;

/**
 * MUI Grid `renderEditCell` function cannot directly call any hooks. This function wraps a `renderEditCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderEditCell =
  (
    Component: FC<GridRenderEditCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderEditCell"] =>
    props => <Component {...props} {...componentProps} />;

export const CaseItemColumnHeaders: Partial<Record<CaseItemKeys, string>> = {
  [CaseItemKeys.ChargeBackIssueId]: "Chargeback Issue ID",
  [CaseItemKeys.CaseItemStatus]: "Case Item Status",
  [CaseItemKeys.FinancialCharge]: "Financial Charge",
  [CaseItemKeys.PaidAmount]: "Paid Amount",
  [CaseItemKeys.ApprovedAmount]: "Approved Amount",
  [CaseItemKeys.WorkPacketId]: "Packet ID",
  [CaseItemKeys.VcDisputedInvoiceId]: "VC Disputed Invoice ID",
  [CaseItemKeys.VcParentInvoiceId]: "VC Parent Invoice ID",
  [CaseItemKeys.PoNumber]: "PO Number",
  [CaseItemKeys.ARN]: "ARN",
  [CaseItemKeys.ASN]: "ASN",
  [CaseItemKeys.ASIN]: "ASIN",
  [CaseItemKeys.RemainingOpenBalance]: "Remaining Open Balance",
  [CaseItemKeys.LastInvoiceNumberForPayment]: "Invoice # for Payment",
  [CaseItemKeys.LastInvoicePaymentId]: "Payment ID",
  [CaseItemKeys.LastInvoiceClientDate]: "C6 Invoices Client Date",
  [CaseItemKeys.LastInvoicePaymentDate]: "Payment Date",
  [CaseItemKeys.LastInvoiceClientAmount]: "C6 Invoices Client Amount",
  [CaseItemKeys.PaymentId]: "Payment ID",
  [CaseItemKeys.PaymentDate]: "Payment Date",
  [CaseItemKeys.RecoveredAmount]: "Recovered Amount",
  [CaseItemKeys.InvoiceForPayment]: "Invoice for Payment",
} as const;

type GridColOptions = Omit<GridBaseColDef, "field" | "headerName">;

function column(field: CaseItemKeys, options: GridColOptions = EMPTY_OBJECT): GridBaseColDef {
  if (options.renderCell) {
    options.renderCell = wrapRenderCell(options.renderCell);
  }

  if (options.renderEditCell) {
    options.renderEditCell = wrapRenderEditCell(options.renderEditCell);
  }

  return {
    field,
    headerName: CaseItemColumnHeaders[field],
    width: 200,
    sortable: false,
    filterable: false,
    editable: options.editable || Boolean(options.renderEditCell),
    ...options,
  };
}

function actionsColumn(): GridBaseColDef {
  return {
    field: "_actions",
    headerName: "",
    width: 200,
    display: "flex",
    sortable: false,
    filterable: false,
    renderCell: wrapRenderCell(CaseItemsActionButtons),
  };
}

function renderItemStatus(params: GridRenderEditCellParams) {
  const { color, title } = shortagesCaseItemStatusMap.find(action => action.value === params.value) || {
    color: "#EAECF0",
    title: params.value,
  };

  return (
    <Box display="flex" alignItems="center">
      {!!color && <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />}
      <Box ml={1}>{title}&nbsp;</Box>
    </Box>
  );
}

const renderMoneyCell = (params: GridRenderCellParams<any, number>) =>
  params.value != null
    ? new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(params.value)
    : "";

function GridInputCell(params: GridRenderEditCellParams) {
  const { error } = params;

  return (
    <ErrorTooltip open={!!error} title={error}>
      <GridEditInputCell {...params} />
    </ErrorTooltip>
  );
}

const renderCaseItemStatusEditColumn = (props: GridEditSingleSelectCellProps) => {
  const apiRef = useGridApiContext();

  const handleValueChange = async (_event: unknown, value: any) => {
    await apiRef.current.setEditCellValue({
      id: props.id,
      field: props.field,
      value: value.value,
    });
  };

  const value = props.value ? shortagesCaseItemStatusMap.find(option => option.value === props.value) : null;
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement> & {
      key: any;
    },
    option: ShortagesEscalationCaseItemStatusOption,
  ) => {
    return (
      <li className={`autocomplete-menu-item ${props.className}`} {...props} key={props.key}>
        <span className="autocomplete-menu-item__color-indicator" style={{ background: option.color }} />
        <span className="autocomplete-menu-item__text">{option.title}</span>
      </li>
    );
  };

  return (
    <Autocomplete
      options={shortagesCaseItemStatusMap}
      onChange={handleValueChange}
      value={value}
      getOptionLabel={option => option.title}
      renderInput={params => <TextField {...params} variant="outlined" />}
      renderOption={renderOption}
    />
  );
};

const chargebackEscalationItemColumns: GridColDef[] = [
  column(CaseItemKeys.ChargeBackIssueId, { flex: 1, minWidth: 200, sortable: true }),
  column(CaseItemKeys.CaseItemStatus, {
    renderCell: renderItemStatus,
    renderEditCell: renderCaseItemStatusEditColumn,
    width: 250,
    sortable: true,
  }),
  column(CaseItemKeys.FinancialCharge, { sortable: true, renderCell: renderMoneyCell, type: "number" }),
  column(CaseItemKeys.ApprovedAmount, { sortable: true, renderCell: renderMoneyCell, editable: true, type: "number" }),
  column(CaseItemKeys.PaidAmount, { sortable: true, renderCell: renderMoneyCell, type: "number" }),
  column(CaseItemKeys.ASIN, { sortable: false, type: "string" }),
  column(CaseItemKeys.PoNumber, { sortable: false, type: "string" }),
  column(CaseItemKeys.ARN, { sortable: true, type: "string", editable: true }),
  column(CaseItemKeys.ASN, { sortable: true, type: "string", editable: true }),
  actionsColumn(),
];

const escalationItemColumns: GridColDef[] = [
  column(CaseItemKeys.WorkPacketId, { flex: 1, minWidth: 200, sortable: true }),
  column(CaseItemKeys.VcParentInvoiceId, { sortable: true }),
  column(CaseItemKeys.VcDisputedInvoiceId, { sortable: true }),
  column(CaseItemKeys.CaseItemStatus, {
    renderCell: renderItemStatus,
    renderEditCell: renderCaseItemStatusEditColumn,
    width: 250,
    sortable: true,
  }),
  column(CaseItemKeys.PoNumber, { sortable: true, editable: true, type: "number" }),
  column(CaseItemKeys.ASN, { sortable: true, editable: true, type: "string" }), // what format this should be?
  column(CaseItemKeys.ARN, { sortable: true, editable: true, type: "string" }), // what format this should be?
  column(CaseItemKeys.ApprovedAmount, {
    renderCell: renderMoneyCell,
    sortable: false,
    editable: true,
    type: "number",
    renderEditCell: GridInputCell,
    preProcessEditCellProps: (params) => {
      if (params.props.value >= params.row.remainingOpenBalance) {
        return { ...params.props, error: "Approved amount should be less than or equal to Remaining Open Balance" };
      }
      return { ...params.props, error: false };
    },
  }),
  column(CaseItemKeys.RemainingOpenBalance, {
    sortable: true,
    editable: true,
    type: "number",
    renderCell: renderMoneyCell,
  }),
  column(CaseItemKeys.LastInvoiceNumberForPayment, { sortable: true, editable: true, type: "string" }),
  column(CaseItemKeys.LastInvoicePaymentId, { sortable: true }),
  column(CaseItemKeys.LastInvoicePaymentDate, { sortable: true, editable: true, type: "date" }),
  column(CaseItemKeys.LastInvoiceClientAmount, {
    sortable: true,
    editable: true,
    type: "number",
    renderCell: renderMoneyCell,
  }),
  column(CaseItemKeys.LastInvoiceClientDate, { sortable: true, editable: true, type: "date" }),
  actionsColumn(),
];

const settlementItemColumns: GridColDef[] = [
  column(CaseItemKeys.WorkPacketId, { flex: 1, minWidth: 200, sortable: true }),
  column(CaseItemKeys.ASN, { sortable: true, editable: true, type: "number" }),
  column(CaseItemKeys.ARN, { sortable: true, editable: true, type: "number" }),
  column(CaseItemKeys.PoNumber, { sortable: true, editable: true, type: "string" }),
  column(CaseItemKeys.RecoveredAmount, { sortable: true, renderCell: renderMoneyCell }),
  column(CaseItemKeys.InvoiceForPayment, { sortable: true, editable: true, type: "string" }),
  column(CaseItemKeys.PaymentId, { sortable: true }),
  column(CaseItemKeys.PaymentDate, { sortable: true, editable: true, type: "date" }),
  actionsColumn(),
];

export const columnsMap: Record<CaseType, GridColDef[]> = {
  [CaseType.SHORTAGE_ESCALATION]: escalationItemColumns,
  [CaseType.CHARGEBACK_ESCALATION]: chargebackEscalationItemColumns,
  [CaseType.SHORTAGE_SETTLEMENT]: settlementItemColumns,
};
