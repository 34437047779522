import { type GridRenderCellParams } from "@mui/x-data-grid";
import { type FC } from "react";

import { ArrowForwardRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const DetailsButton: FC<GridRenderCellParams<{ chargeGuardCaseId: string }>> = params => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      endIcon={<ArrowForwardRounded />}
      onClick={() => {
        const url = new URL(window.location.href);
        url.search = "";
        url.pathname = url.pathname + "/";
        return navigate(params.row.chargeGuardCaseId);
      }}
    >
      Details
    </Button>
  );
};
