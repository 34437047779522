import {
  type AccrualRemittance,
  type ChargebackRemittance,
  type ServerAccrualRemittance,
  type ServerChargebackRemittance,
  type ServerShortageRemittance,
  type ShortageRemittance,
} from "src/types/work-packets";
import { WorkPacketType } from "../WorkPacketType";
import { formatDate } from "src/utils/date-parse";

export type ServerRemittanceTypeMap = {
  [WorkPacketType.CHARGEBACKS]: ServerChargebackRemittance;
  [WorkPacketType.SHORTAGES]: ServerShortageRemittance;
  [WorkPacketType.ACCRUALS]: ServerAccrualRemittance;
};

export type RemittanceTypeMap = {
  [WorkPacketType.CHARGEBACKS]: ChargebackRemittance;
  [WorkPacketType.SHORTAGES]: ShortageRemittance;
  [WorkPacketType.ACCRUALS]: AccrualRemittance;
};

export function mapRemittances<T extends WorkPacketType>(
  workPacketType: WorkPacketType,
  serverRemittances: ServerRemittanceTypeMap[T][],
): RemittanceTypeMap[T][] {
  if (!serverRemittances.length) return [];

  const currency = serverRemittances[0].CURRENCY ?? "USD";
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency,
    style: "currency",
    currencyDisplay: "symbol",
    currencySign: "accounting",
    signDisplay: "never",
  });

  if (workPacketType === WorkPacketType.CHARGEBACKS) {
    const remittances = serverRemittances as ServerChargebackRemittance[];
    return remittances.map<ChargebackRemittance>(remittance => ({
      paymentStatus: remittance.STATUS,
      stage: remittance.STAGE,
      remittanceInvoiceId: remittance.CHARGE_INVOICE_NUMBER,
      paymentId: remittance.PAYMENT_ID,
      paymentAmount: currencyFormatter.format(remittance.FINANCIAL_CHARGE),
      paymentDate: remittance.PAYMENT_DATE,
      c6InvoicesClientAmount: "", // TODO: implement this field once it's available from backend
      c6InvoicedDate: remittance.INVOICED_AT,
    })) as RemittanceTypeMap[T][];
  }
  if (workPacketType === WorkPacketType.SHORTAGES) {
    const remittances = serverRemittances as ServerShortageRemittance[];
    return remittances.map<ShortageRemittance>(remittance => ({
      paymentStatus: remittance.STATUS,
      stage: remittance.STAGE,
      remittanceInvoiceId: remittance.INVOICE_NUMBER,
      paymentId: remittance.PAYMENT_NUMBER ?? "",
      paymentAmount: remittance.PAID_AMOUNT != null ? currencyFormatter.format(remittance.PAID_AMOUNT) : "",
      paymentDate: remittance?.PAYMENT_DATE ? formatDate(remittance.PAYMENT_DATE) : "",
      c6InvoicesClientAmount: "", // TODO: implement this field once it's available from backend
      c6InvoicedDate: remittance.INVOICED_AT,
    })) as RemittanceTypeMap[T][];
  }
  if (workPacketType === WorkPacketType.ACCRUALS) {
    const remittances = serverRemittances as ServerAccrualRemittance[];
    return remittances.map<AccrualRemittance>(remittance => ({
      paidAmount: currencyFormatter.format(remittance.RECOVERED_AMOUNT),
      reversalInvoiceNumber: remittance.REPAID_INVOICE_NUMBER,
      paymentId: remittance.PAYMENT_NUMBER,
      paymentDate: remittance.PAYMENT_DATE,
    })) as RemittanceTypeMap[T][];
  }
  console.error("unknown work packet type", workPacketType);
  return [];
}
