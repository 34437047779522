import { WorkPacketType } from "../WorkPacketType";
import { fetchWorkPacketById, fetchWorkPacketRemittances } from "../api/workPacketsAPI";
import type { QueryFunctionContext } from "@tanstack/react-query";

export const getWorkPacket = async <T extends WorkPacketType>({
  queryKey: [, workPacketType, , workPacketId],
  signal,
}: QueryFunctionContext<["work-packets", type: T, "details", packetId: string]>) =>
  fetchWorkPacketById({ workPacketId, workPacketType, signal });

export const getChargebackRemittances = async ({
  queryKey: [, , , workPacketId],
  signal,
}: QueryFunctionContext<["work-packets", WorkPacketType.CHARGEBACKS, "remittance", packetId: string]>) =>
  fetchWorkPacketRemittances({ workPacketId, workPacketType: WorkPacketType.CHARGEBACKS, signal });

export const getShortageRemittances = async ({
  queryKey: [, , , workPacketId],
  signal,
}: QueryFunctionContext<["work-packets", WorkPacketType.SHORTAGES, "remittance", packetId: string]>) =>
  fetchWorkPacketRemittances({ workPacketId, workPacketType: WorkPacketType.SHORTAGES, signal });

export const getAccrualRemittances = async ({
  queryKey: [, , , workPacketId],
  signal,
}: QueryFunctionContext<["work-packets", WorkPacketType.ACCRUALS, "remittance", packetId: string]>) =>
  fetchWorkPacketRemittances({ workPacketId, workPacketType: WorkPacketType.ACCRUALS, signal });
