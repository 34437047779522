import { Box, Divider, Typography } from "@mui/material";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { DataGridPro, type DataGridProProps } from "@mui/x-data-grid-pro";
import { type QueryFunction, useQuery } from "@tanstack/react-query";
import { type FC } from "react";
import { attempt } from "src/utils/attempt";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { fetchCommandCenterData } from "../casesApi";
import type { CommandCenterGridRow } from "src/types/work-packets.ts";
import { CaseType } from "../case-types";

const renderCurrencyCell = (params: GridRenderCellParams<any, string>) =>
  attempt(
    () =>
      params.value ? Number.parseFloat(params.value).toLocaleString("en", { style: "currency", currency: "USD" }) : "",
    params.value,
    "Error parsing currency or rendering currency cell",
  );

const getCommandCenterData: QueryFunction<CommandCenterGridRow[], ["cases", CaseType, "command-center"]> = async ({
  queryKey: [, caseType],
  signal,
}) => fetchCommandCenterData(caseType, signal);

const baseColumns: GridColDef[] = [
  {
    field: "openCount",
    headerName: "Open Count",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
  },
  {
    field: "openAmount",
    headerName: "Open Amount",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
    renderCell: renderCurrencyCell,
  },
];

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = row => row.hierarchy;

const CommandCenter: FC<{ caseType: CaseType }> = ({ caseType }) => {
  const { data: rows, isPending } = useQuery({
    queryFn: getCommandCenterData,
    queryKey: ["cases", caseType, "command-center"],
    staleTime: 10000,
  });

  return (
    <>
      <Box paddingTop={4.5}>
        <Typography variant="h6" marginBottom={2}>
          Command Center
        </Typography>
        <Box sx={{ mt: 2, overflow: "auto" }}>
          <Box bgcolor="#FFF" minWidth={800}>
            <DataGridPro
              loading={isPending}
              treeData
              rows={rows ?? EMPTY_ARRAY}
              columns={baseColumns}
              getTreeDataPath={getTreeDataPath}
              groupingColDef={{
                flex: 2,
                headerName: "Recovery stream",
              }}
              autoHeight
              hideFooter
              disableColumnFilter
              disableColumnMenu
            />
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default CommandCenter;
