import { HttpStatusCode } from "axios";
import { FormikHelpers } from "formik";
import { ServerUser } from "src/types/user";

export interface CommentData {
  id: string;
  createdAt: string;
  isPinned: boolean;
  comment: string;
  userData: UserData;
  attachment?: CommentAttachment | null;
}

export interface CommentAttachment {
  id: string;
  name: string;
  url: string;
  type: string; // MIME type of the attachment
}

export interface UserData {
  name?: string;
  picture?: string | null;
}

type ToggelPinFn = (id: string, prevPin: boolean) => void;

export interface TogglePinProps {
  togglePin: ToggelPinFn;
}

export interface TogglePinArgs {
  commentId: string;
  newPin: boolean;
}

export interface CommentCardProps {
  commentData: CommentData;
  togglePin: ToggelPinFn;
}

export enum CommentFormFieldKeys {
  newComment = "newComment",
  attachment = "attachment",
}

export interface NewCommentFormikValues {
  [CommentFormFieldKeys.newComment]: string;
  [CommentFormFieldKeys.attachment]: File | null;
}

export type NewCommentFormikActions = FormikHelpers<NewCommentFormikValues>;

export type HandleNewComment = (values: NewCommentFormikValues, actions: NewCommentFormikActions) => void;

export interface ServerGetCommentsResponse extends ServerResponse {
  comments: ServerModelComment[];
}

export interface ServerResponse {
  message: string;
  status: HttpStatusCode;
  success: boolean;
}

export interface ServerModelComment {
  COMMENT: ServerComment;
  COMMENT_ID: string;
  CREATED_AT: string;
  ID: string;
  MODEL: string;
  MODEL_ID: string;
}

export interface ServerComment {
  ATTACHMENT: ServerAttachment | null;
  COMMENT: string;
  CREATED_AT: string;
  CREATED_BY: ServerUser;
  ID: string;
  IS_PINNED: boolean;
}

export interface ServerAttachment {
  CREATED_AT: string;
  FILE_NAME: string;
  ID: string;
  OBJECT_KEY: string;
  TYPE: string;
}
