import { type QueryFunctionContext, useQuery } from "@tanstack/react-query";
import type { FC } from "react";
import { getEscalationCaseById, getSettlementCaseById } from "src/pages/UserDashboard/Cases/api/casesApi";
import { CaseSettlementDetails } from "src/pages/UserDashboard/Cases/CaseDetail/CaseSettlementDetail";
import { CaseEscalationDetails } from "src/pages/UserDashboard/Cases/CaseDetail/CaseEscalationDetail";
import { EscalationCase, SettlementCase } from "src/types/cases";
import { CaseType, isEscalationCaseType } from "../../case-types";
import { PulseLoader } from "react-spinners";
import { Box } from "@mui/material";

export interface CaseDetailsProps {
  caseId: string;
  caseType: CaseType;
}

const fetchSettlementCaseById = async ({
  queryKey: [, caseId],
}: QueryFunctionContext<["cases", caseId: string, "details"]>): Promise<SettlementCase> =>
  getSettlementCaseById(caseId);

const fetchEscalationCaseById = async ({
  queryKey: [, caseId],
}: QueryFunctionContext<["cases", caseId: string, "details"]>): Promise<EscalationCase> =>
  getEscalationCaseById(caseId);

const SettlementCaseDetails: FC<{ caseId: string; caseType: CaseType }> = ({ caseId, caseType }) => {
  const caseQuery = useQuery({
    queryFn: fetchSettlementCaseById,
    queryKey: ["cases", caseId, "details"],
  });

  if (caseQuery.isPending) return <span>Loading...</span>;
  if (caseQuery.isError) return <span>Error</span>;
  return <CaseSettlementDetails caseItem={caseQuery.data} caseType={caseType} isRefreshing={caseQuery.isRefetching} />;
};

const EscalationCaseDetails: FC<{ caseId: string; caseType: CaseType }> = ({ caseId, caseType }) => {
  const caseQuery = useQuery({
    queryFn: fetchEscalationCaseById,
    queryKey: ["cases", caseId, "details"],
  });

  if (caseQuery.isPending)
    return (
      <Box padding={3}>
        <PulseLoader size="8px" color="#EF1442" />
      </Box>
    );
  if (caseQuery.isError) return <span>Error</span>;
  return <CaseEscalationDetails caseItem={caseQuery.data} caseType={caseType} isRefreshing={caseQuery.isRefetching} />;
};

export const CaseDetails: FC<CaseDetailsProps> = ({ caseId, caseType }) => {
  if (isEscalationCaseType(caseType)) {
    return <EscalationCaseDetails caseId={caseId} caseType={caseType} />;
  } else {
    return <SettlementCaseDetails caseId={caseId} caseType={caseType} />;
  }
};
