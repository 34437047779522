import dayjs from "dayjs";
import {
  type AccrualWorkPacket,
  ChargebackEscalation,
  ChargebackEscalationPaymentDetail,
  type ChargebackWorkPacket,
  EscalationPaymentDetail,
  RecoveryStream,
  type ServerAccrualWorkPacket,
  type ServerChangeLogEntry,
  type ServerChargebackWorkPacket,
  type ServerShortageWorkPacket,
  type ServerWorkPacket,
  type ServerWorkPacketDisputeIdProperty,
  type ServerWorkPacketTypeMap,
  ShortageEscalation,
  type ShortageWorkPacket,
  StagefulAction,
  StageName,
  type WorkPacketActionName,
  type WorkPacketBase,
  type WorkPacketTypeMap,
} from "src/types/work-packets";
import { attempt } from "src/utils/attempt";
import { dateOrUndefined, formatDate, parseDate } from "src/utils/date-parse";
import { WorkPacketType } from "../WorkPacketType";
import { parseAction, type ParsedAction } from "../util/parseAction";
import { parseMaybeCurrency, formatMaybeCurrency, valueOrUndefined, valueOrZero } from "src/pages/UserDashboard/Cases/mappers/utils";
import type { ServerUser } from "src/types/user";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { SettlementCase } from "src/types/cases";

const marketplaceCountryRegex = /(.+) - .+/;
const getMarketplaceCountry = (packet: ServerWorkPacket) =>
  marketplaceCountryRegex.exec(packet.ACCOUNT.STORE_NAME)?.[1] ?? "Unknown";

export const mapOwnerObject = (owner?: ServerUser | null) =>
  owner
    ? {
        id: owner.ID,
        avatar: owner.PROFILE_PIC ?? "",
        title: owner.NICKNAME,
      }
    : {
        id: "",
        avatar: "",
        title: "Unassigned",
      };

/**
 * There are properties that are common to all work packets, but that are computed differently for each type.
 * This type represents all the work packet properties that are computed way the same across all work packet types
 * (by omitting those common types that are computed differently).
 *
 * TODO (daniel): this is internal, but should probably figure out a better name than this
 */
type WorkPacketBaseBase = Omit<
  WorkPacketBase,
  "workPacketType" | "recoveryStream" | "vendorName" | "disputeByDate" | "disputeApprovedAmount"
>;

const findChangeLog = (logs: ServerChangeLogEntry[], predicate: (parsed: ParsedAction) => unknown) =>
  logs.find(log => log.UPDATES.CURRENT_ACTION && predicate(parseAction(log.UPDATES.CURRENT_ACTION)));

const mapServerResponseToWorkPacketBase = (packet: ServerWorkPacket): WorkPacketBaseBase => {
  const latestChangelogEntry: ServerChangeLogEntry | undefined = packet.CHANGELOGS[0];

  const evidenceTypeCsv = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "EVIDENCE_TYPE")?.VALUE ?? "";
  const evidenceType = evidenceTypeCsv
    .split(",")
    .map(x => x.trim())
    .filter(x => x.length > 0);

  const evidenceAttachment =
    packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "EVIDENCE_ATTACHMENT")?.VALUE ?? "";

  return {
    createdAt: formatDate(packet.CREATED_AT),
    packetId: packet.PACKET_ID,
    packetStage: packet.PACKET_STAGE,
    storeName: packet.ACCOUNT.STORE_NAME,
    currentPacketOwner: mapOwnerObject(packet.PACKET_OWNER),
    currentAction: packet.CURRENT_ACTION as WorkPacketActionName,
    manualFilingUser: packet.ACCOUNT.VC_FILING_USER ?? "",
    techUser: packet.ACCOUNT.TECH_USER,
    vendorId: packet.ACCOUNT.VENDOR_ID,
    storeId: packet.ACCOUNT.ID,
    packetDate: parseDate(packet.CREATED_AT),
    recoveryStreamServer: packet.RECOVERY_STREAM,
    recoveryStreamActivationDate: parseDate(packet.ACCOUNT.ACTIVATION_DATE),
    vcFilingUser: packet.ACCOUNT.VC_FILING_USER ?? "",
    notes: packet.NOTES,
    systemNotes: packet.SYSTEM_NOTES,
    isValidWorkPacket: packet.VALID,
    marketplaceCountry: getMarketplaceCountry(packet),
    modifiedAt: latestChangelogEntry ? formatDate(latestChangelogEntry.RECORDED_AT) : "",
    lastModifiedBy: mapOwnerObject(latestChangelogEntry?.USER),
    evidenceType,
    evidenceAttachment,

    // fake columns
    _details: undefined,
  };
};

export const mapServerResponseToChargebackWorkPacket = (packet: ServerChargebackWorkPacket): ChargebackWorkPacket => {
  const disputeIdProperty = packet.WORK_PACKET_PROPERTIES.find(
    (property): property is ServerWorkPacketDisputeIdProperty => property.KEY === "DISPUTE_ID",
  );
  const amazonDispute = disputeIdProperty?.AMAZON_DISPUTE;

  const firstDisputeCreatedAt = findChangeLog(
    packet.CHANGELOGS,
    action => "stage" in action && action.stage === StageName.First && action.action === StagefulAction.DisputeCreated,
  )?.RECORDED_AT;

  const secondDisputeCreatedAt = findChangeLog(
    packet.CHANGELOGS,
    action => "stage" in action && action.stage === StageName.Second && action.action === StagefulAction.DisputeCreated,
  )?.RECORDED_AT;

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    workPacketType: WorkPacketType.CHARGEBACKS,
    recoveryStream: RecoveryStream.Chargebacks,
    vendorName: packet.RESOURCE.VENDOR_NAME,
    disputeByDate: packet.RESOURCE.DISPUTE_BY_DATE ? formatDate(packet.RESOURCE.DISPUTE_BY_DATE) : undefined,
    calculatedDisputeByDate: attempt(
      () => dayjs(packet.RESOURCE.CREATE_DATE).add(30, "days").format("YYYY-MM-DD"),
      undefined,
      "Error calculating chargeback packet dispute by date",
    ),
    recoveryStreamSubtype: packet.RESOURCE.RECOVERY_STREAM_SUB_TYPE,
    vcPoId: packet.RESOURCE.VC_PO_ID,
    asinId: packet.RESOURCE.ASIN_ID,
    chargebackIssueId: packet.RESOURCE.CHARGEBACK_ISSUE_ID,
    recoveryStreamSubtype1: packet.RESOURCE.ISSUE_SUB_TYPE_DESC,
    recoveryStreamSubtype2: packet.RESOURCE.NOTES,
    financialCharge: packet.RESOURCE.FINANCIAL_CHARGE,
    reversedAmount: packet.RESOURCE.REVERSED_AMOUNT,
    firstDisputeCreatedAt: firstDisputeCreatedAt && formatDate(firstDisputeCreatedAt),
    secondDisputeCreatedAt: secondDisputeCreatedAt && formatDate(secondDisputeCreatedAt),
    chargebackCreateDate: formatDate(packet.RESOURCE.CREATE_DATE),
    vendorCode: packet.RESOURCE.VENDOR_CODE,
    disputeApprovedAmount: amazonDispute?.APPROVED_AMOUNT ?? "",
    freightTerms: packet.RESOURCE.FREIGHT_TERMS,
    inboundShipmentDeliveryIsdId: packet.RESOURCE.INBOUND_SHIPMENT_DELIVERY_ISD_ID,
    escalations: mapServerWorkPacketToChargebackEscalations(packet),
  };
};

const mapServerWorkPacketToChargebackEscalations = (packet: ServerWorkPacket): ChargebackEscalation[] => {
  const items = packet.CASE_ITEMS?.filter(item => item.CASE?.TYPE === CaseType.CHARGEBACK_ESCALATION);
  if (!items) {
    return [];
  }

  return Object.values(
    items.reduce<Record<string, ChargebackEscalation>>((cases, item) => {
      const caseId = item.CASE_ID;

      // Initialize case object if not already present
      if (!cases[caseId]) {
        cases[caseId] = {
          id: item.CASE?.AMAZON_CASE_ID,
          itemId: item.ID,
          createdAt: dateOrUndefined(item.CASE?.AMAZON_CASE_CREATED_DATE),
          status: item.CASE?.STATUS,
          remainingOpenBalance: 0, // is set later (sum of all remaining balances)
          monitoringDate: dateOrUndefined(item.CASE?.CHARGEBACKS_ESCALATION_CASE_DETAILS?.MONITORED_DATE),
          approvedDate: dateOrUndefined(item.CASE?.CHARGEBACKS_ESCALATION_CASE_DETAILS?.APPROVED_DATE),
          approvedAmount: item.CASE?.CHARGEBACKS_ESCALATION_CASE_DETAILS?.APPROVED_AMOUNT || undefined,
          disputedAmount: 0,
          paidAmount: 0,
          paymentDetails: [],
        };
      }

      // Add new payments to the case
      const newPayments: ChargebackEscalationPaymentDetail[] = item.CHARGEBACKS_ESCALATION_PAYMENT_DETAILS.filter(
        // Only add payments that are not already present in the case
        payment => !cases[caseId].paymentDetails.some(e => e.id === payment.ID),
      ).map(
        (payment): ChargebackEscalationPaymentDetail => ({
          id: payment.ID,
          caseItemId: payment.CASE_ITEM_ID,
          invoiceId: payment.INVOICE_NUMBER_FOR_PAYMENT || "",
          invoiceNumber: payment.INVOICE_NUMBER_FOR_PAYMENT || "",
          paidAmount: payment.PAID_AMOUNT || 0,
          paymentDate: dateOrUndefined(payment.PAYMENT_DATE),
          c6InvoiceDate: dateOrUndefined(payment.CUSTOMER_INVOICED_AT),
          // TODO: figure out details on Payout Type
          // payoutType: payment.DISPUTED_INVOICE_ID || "",
          customerInvoiceAmount: payment.CUSTOMER_INVOICE_AMOUNT,
          paymentId: payment.PAYMENT_ID,
          createdAt: payment.CREATED_AT,
        }),
      );

      cases[caseId].paymentDetails.push(...newPayments);

      // Update  paid amount of current item from all payment details
      cases[caseId].paidAmount = newPayments.reduce(
        (previousValue, currentValue) => previousValue + currentValue.paidAmount,
        0,
      );

      return cases;
    }, {}),
  );
};

const parentInvoiceIdFromChildInvoiceIdRegex = /(.*?)(SCR)*/;
export const getVcParentInvoiceId = (packet: ServerShortageWorkPacket): string | undefined => {
  const parentInvoiceProperty = packet.WORK_PACKET_PROPERTIES.find(
    property => property.KEY === "PARENT_INVOICE_NUMBER",
  );
  if (parentInvoiceProperty) return parentInvoiceProperty.VALUE;

  const childInvoiceProperty = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "CHILD_INVOICE_NUMBER");
  if (childInvoiceProperty) return parentInvoiceIdFromChildInvoiceIdRegex.exec(childInvoiceProperty.VALUE)?.[1];
};

const parseShortageLag = (serverShortageLag: string | null): number => {
  if (!serverShortageLag || !serverShortageLag.trim()) return 0;
  const parsedNumber = Number.parseInt(serverShortageLag);
  if (!Number.isInteger(parsedNumber)) return 0;
  return parsedNumber;
};

const mapServerWorkPacketToShortageSettlements = (packet: ServerWorkPacket): SettlementCase | undefined => {
  const item = packet.CASE_ITEMS?.find(item => item.CASE?.TYPE === CaseType.SHORTAGE_SETTLEMENT);
  if (!item) {
    return undefined;
  }

  const caseInstance = item.CASE;
  const settlementDetails = caseInstance.SHORTAGES_SETTLEMENT_CASE_DETAILS;

  return {
    id: caseInstance.ID,
    status: caseInstance.STATUS,
    preSubmissionRequired: settlementDetails?.PRESUBMISSION_REQUIRED ?? undefined,
    business: settlementDetails?.BUSINESS ?? "",
    payee: caseInstance.PAYEE ?? "",
    vendorCodes: settlementDetails?.VENDOR_CODES ?? "",
    directImport: settlementDetails?.DIRECT_IMPORT ?? undefined,
    category: settlementDetails?.CATEGORY ?? "",
    vendorName: caseInstance.ACCOUNT?.VENDOR?.VENDOR_NAME ?? "",
    vendorId: caseInstance.ACCOUNT?.VENDOR_ID ?? "",
    storeName: caseInstance.ACCOUNT?.STORE_NAME ?? "",
    storeId: caseInstance.ACCOUNT?.ID ?? 0,

    invoiceStartDate: dateOrUndefined(caseInstance.PAYMENT_DATES?.INVOICE_DATE_START),
    invoiceEndDate: dateOrUndefined(caseInstance.PAYMENT_DATES?.INVOICE_DATE_END),
    invoiceStartDueDate: dateOrUndefined(caseInstance.INVOICE_START_DUE_DATE),
    invoiceEndDueDate: dateOrUndefined(caseInstance.INVOICE_END_DUE_DATE),

    lastModifiedBy: valueOrUndefined(caseInstance.LAST_MODIFIED_BY),
    caseOwner: mapOwnerObject(caseInstance.CASE_OWNER) ?? "",
    sprinter: settlementDetails?.SPRINTER ?? "",
    sprinterDate: dateOrUndefined(settlementDetails?.SPRINTER_VIEWED_AT),
    opsExternalCommunication: settlementDetails?.OPS_EXTERNAL_COMMUNICATION ?? undefined,
    processor: settlementDetails?.PROCESSOR ?? "",
    processorDate: dateOrUndefined(settlementDetails?.PROCESSOR_VIEWED_AT),
    submissionPath: settlementDetails?.SUBMISSION_PATH ?? "",
    manualFilingUser: caseInstance.ACCOUNT?.VC_FILING_USER ?? "",
    ccUser: settlementDetails?.CC_USER ?? "",
    amazonCaseId: caseInstance.AMAZON_CASE_ID,
    link: "",
    prevCaseIds: settlementDetails?.PREVIOUS_CASE_IDS ?? "",
    amazonLastRespondedAt: dateOrUndefined(settlementDetails?.AMAZON_LAST_RESPONDED_AT),
    cgLastRespondedAt: dateOrUndefined(settlementDetails?.LAST_RESPONDED_AT),
    amazonCaseManager: settlementDetails?.AMAZON_CASE_MANAGER ?? "",
    stage: settlementDetails?.STAGE ?? "",
    submissionAmount: caseInstance.REMAINING_OPEN_BALANCE ?? undefined,
    submissionDate: dateOrUndefined(caseInstance.AMAZON_CASE_CREATED_DATE),
    submissionCount: settlementDetails?.SETTLEMENT_COUNT ?? 0,
    alignedBalAmount: settlementDetails?.ALIGNED_BALANCE_AMOUNT ?? undefined,
    alignedBalDate: dateOrUndefined(settlementDetails?.ALIGNED_BALANCE_DATE),
    rcaEndDate: dateOrUndefined(settlementDetails?.RCA_ENDED_DATE),
    paidAmountBeforeOpenBalanceAlignment: settlementDetails?.PAID_AMOUNT_DURING_OPEN_SHORTAGE ?? undefined,
    validAmount: settlementDetails?.VALID_AMOUNT ?? undefined,
    settlementNo: valueOrUndefined(settlementDetails?.SETTLEMENT_COUNT),
    currentRRCalc: 0,
    currentAmount: valueOrZero(settlementDetails?.CURRENT_AMOUNT),
    paidAmount: valueOrZero(caseInstance.PAID_AMOUNT),
    vcDisputedIds: caseInstance.ITEMS?.[0]?.SHORTAGES_OSSR_WORK_PACKET?.DISPUTED_INVOICE_ID,

    firstOfferID: caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[0]?.ID ?? undefined,
    firstOfferAmount: caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[0]?.OFFER_AMOUNT ?? undefined,
    firstOfferDate: dateOrUndefined(caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[0]?.OFFER_DATE),
    secondOfferID: caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[1]?.ID ?? undefined,
    secondOfferAmount: caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[1]?.OFFER_AMOUNT ?? undefined,
    secondOfferDate: dateOrUndefined(caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[1]?.OFFER_DATE),
    thirdOfferID: caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[2]?.ID ?? undefined,
    thirdOfferAmount: caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[2]?.OFFER_AMOUNT ?? undefined,
    thirdOfferDate: dateOrUndefined(caseInstance.SHORTAGES_SETTLEMENT_OFFERS?.[2]?.OFFER_DATE),

    settlementAcceptanceDateToAmazon: dateOrUndefined(settlementDetails?.SETTLEMENT_ACCEPTED_AT),
    finalAmount: settlementDetails?.FINAL_AMOUNT ?? undefined,
    finalAmountDate: dateOrUndefined(settlementDetails?.FINAL_AMOUNT_RECEIVED_AT),
    finalRecoveryRateCalc: caseInstance.FINAL_RR_CALC ?? undefined,
    paymentIds: item.SHORTAGES_SETTLEMENT_PAYMENT_IDS ?? "",
    payoutDate: dateOrUndefined(item.SHORTAGES_SETTLEMENT_PAYMENT_DATES) ?? undefined,
    notes: "",

    // unused items:
    recoveryStreamSubType: undefined,
    recoveryStreamType: undefined,
  };
};

const mapServerWorkPacketToShortageEscalations = (packet: ServerWorkPacket): ShortageEscalation[] => {
  const items = packet.CASE_ITEMS?.filter(item => item.CASE?.TYPE === CaseType.SHORTAGE_ESCALATION);
  if (!items) {
    return [];
  }

  // holds the ids of the items that have already been processed
  const itemIds = new Set<string>();

  return Object.values(
    items.reduce<Record<string, ShortageEscalation>>((cases, item) => {
      const caseId = item.CASE_ID;

      // Initialize case object if not already present
      if (!cases[caseId]) {
        cases[caseId] = {
          id: item.CASE?.AMAZON_CASE_ID,
          itemId: item.ID,
          createdAt: dateOrUndefined(item.CASE?.AMAZON_CASE_CREATED_DATE),
          status: item.SHORTAGES_ESCALATION_CASE_ITEM?.STATUS || "",
          remainingOpenBalance: 0, // is set later (sum of all remaining balances)
          monitoringDate: dateOrUndefined(item.CASE?.SHORTAGES_ESCALATION_CASE_DETAILS?.MONITORED_DATE),
          approvedDate: dateOrUndefined(item.CASE?.SHORTAGES_ESCALATION_CASE_DETAILS?.APPROVED_DATE),
          approvedAmount: formatMaybeCurrency(item.SHORTAGES_ESCALATION_CASE_ITEM?.APPROVED_AMOUNT) || undefined,
          paymentDetails: [],
        };
      }

      // Add new payments to the case
      const newPayments: EscalationPaymentDetail[] = item.SHORTAGES_ESCALATION_PAYMENT_DETAILS.filter(
        // Only add payments that are not already present in the case
        payment => !cases[caseId].paymentDetails.some(e => e.id === payment.ID),
      ).map(
        (payment): EscalationPaymentDetail => ({
          id: payment.ID,
          caseItemId: payment.CASE_ITEM_ID,
          invoiceId: payment.DISPUTED_INVOICE_ID || "",
          invoiceNumber: payment.INVOICE_NUMBER_FOR_PAYMENT || "",
          disputedInvoiceId: payment.DISPUTED_INVOICE_ID || "",
          paidAmount: payment.PAID_AMOUNT || 0,
          paymentDate: dateOrUndefined(payment.PAYMENT_DATE),
          c6InvoiceDate: dateOrUndefined(payment.CUSTOMER_INVOICED_AT),
          // TODO: figure out details on Payout Type
          // payoutType: payment.DISPUTED_INVOICE_ID || "",
          customerInvoiceAmount: payment.CUSTOMER_INVOICE_AMOUNT,
          paymentId: payment.PAYMENT_ID,
          createdAt: payment.CREATED_AT,
        }),
      );

      cases[caseId].paymentDetails.push(...newPayments);

      // Update the remaining balance if current item has not been processed yet
      if (!itemIds.has(caseId) && item.SHORTAGES_OSSR_WORK_PACKET?.REMAINING_BALANCE_OPEN) {
        cases[caseId].remainingOpenBalance += item.SHORTAGES_OSSR_WORK_PACKET.REMAINING_BALANCE_OPEN;
        itemIds.add(caseId);
      }

      return cases;
    }, {}),
  );
};

/** A shortage work packet's dispute is considered resolved if any of its changelog entries is one of these actions.  */
const shortageDisputeResolvingActions: ParsedAction["action"][] = [
  StagefulAction.DisputeDenied,
  StagefulAction.DisputeApproved,
  StagefulAction.DisputePartiallyApproved,
];

export const mapServerResponseToShortageWorkPacket = (packet: ServerShortageWorkPacket): ShortageWorkPacket => {
  const disputeResolvedAt = findChangeLog(packet.CHANGELOGS, ({ action }) =>
    shortageDisputeResolvingActions.includes(action),
  )?.RECORDED_AT;

  const disputeIdProperty = packet.WORK_PACKET_PROPERTIES.find(
    (property): property is ServerWorkPacketDisputeIdProperty => property.KEY === "DISPUTE_ID",
  );
  const amazonDispute = disputeIdProperty?.AMAZON_DISPUTE;

  const disputeAmountProperty = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "DISPUTE_AMOUNT");

  const remainingOpenBalanceValue = packet.RESOURCE.SHORTAGES_OSSR_WORK_PACKETS?.[0]?.REMAINING_BALANCE_OPEN;
  const remainingOpenBalance =
    remainingOpenBalanceValue !== undefined ? formatMaybeCurrency(remainingOpenBalanceValue) : "";

  const disputeAmount = disputeAmountProperty?.VALUE
    ? formatMaybeCurrency(disputeAmountProperty.VALUE as `${number}`)
    : "";

  const shortageLag = parseShortageLag(packet.ACCOUNT.SHORTAGE_LAG);
  const disputeByDate = attempt(
    () => dayjs(packet.RESOURCE.DUE_DATE).add(shortageLag, "days").format("YYYY-MM-DD"),
    "",
    "Error calculating shortage packet dispute by date",
  );

  const vcDisputedInvoiceId =
    packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "DISPUTED_INVOICE_NUMBER")?.VALUE ?? "";

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    workPacketType: WorkPacketType.SHORTAGES,
    disputeByDate,
    recoveryStream: RecoveryStream.InventoryShortages,
    vendorName: packet.RESOURCE.VENDOR,
    disputeId: disputeIdProperty?.VALUE ?? "",
    disputeAmount,
    disputeCreatedAt: amazonDispute?.DISPUTE_DATE ? formatDate(amazonDispute.DISPUTE_DATE) : "",
    invoiceDate: formatDate(packet.RESOURCE.INVOICE_DATE),
    invoiceDueDate: formatDate(packet.RESOURCE.DUE_DATE),
    vcParentInvoiceId: getVcParentInvoiceId(packet) ?? "",
    vcDisputedInvoiceId,
    vcPayeeCode: packet.RESOURCE.PAYEE,
    shortageLag: packet.ACCOUNT.SHORTAGE_LAG,
    disputeApprovedAmount: amazonDispute?.APPROVED_AMOUNT ?? "",
    disputeResolvedAt: disputeResolvedAt ?? "",
    escalationCount: packet.CASE_ITEM_COUNT ?? "",
    escalations: mapServerWorkPacketToShortageEscalations(packet),
    settlement: mapServerWorkPacketToShortageSettlements(packet),
    remainingOpenBalance,
  };
};

export const mapServerResponseToAccrualWorkPacket = (packet: ServerAccrualWorkPacket): AccrualWorkPacket => {
  const subType = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "SUB_TYPE")?.VALUE ?? "";
  const obarOption = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "OBAR_OPTION")?.VALUE ?? "";
  const remittance = packet.RESOURCE.INVOICE_PAYMENTS_OSSR;
  const currency = remittance?.INVOICECURRENCY ?? "USD";
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency,
    style: "currency",
    currencyDisplay: "symbol",
    currencySign: "accounting",
    signDisplay: "never",
  });

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    workPacketType: WorkPacketType.ACCRUALS,
    recoveryStream: RecoveryStream.Accruals,
    vendorName: packet.ACCOUNT.VENDOR.VENDOR_NAME,
    disputeByDate: "",
    disputeApprovedAmount: "",
    obarOption,
    disputes: packet.RESOURCE.DISPUTES.map((dispute, index) => {
      const disputeAmount =
        dispute.DISPUTE_AMOUNT !== null ? parseMaybeCurrency(dispute.DISPUTE_AMOUNT as `${number}`) : undefined;

      const disputeDate = formatDate(dispute.CREATED_AT);

      // FIXME: this is a hack to get the remittance for the dispute
      // It should be removed as soon as possible
      const remittance = packet.REMITTANCES?.[index]
      return {
        id: dispute.ID,
        disputeAmount,
        disputeDate,
        originalDisputeId: dispute.DISPUTE_ID ?? "",
        consolidatedDisputeId: dispute.CONSOLIDATED_DISPUTE_ID ?? "",
        disputeStatus: dispute.AMAZON_DISPUTES?.DISPUTE_STATUS ?? "",
        invoiceStatus: dispute.CUSTOMER_INVOICE_STATUS ?? "",
        // approvedAmount,
        approvedAmount: remittance ? currencyFormatter.format(remittance.RECOVERED_AMOUNT) : "",

        // dispute resolution - work in progress
        disputeResolutionDate: remittance?.PAYMENT_DATE ? formatDate(remittance.PAYMENT_DATE) : "",
        paidAmount: remittance?.RECOVERED_AMOUNT ? currencyFormatter.format(remittance.RECOVERED_AMOUNT) : "",
        reversalInvoiceNumber: remittance?.REPAID_INVOICE_NUMBER ?? "",
      };
    }),
    remittances: (packet.REMITTANCES || []).map(r => {
      return {
        paidAmount: currencyFormatter.format(r.RECOVERED_AMOUNT),
        reversalInvoiceNumber: r.REPAID_INVOICE_NUMBER,
        paymentId: r.PAYMENT_NUMBER,
        paymentDate: r.PAYMENT_DATE,
      };
    }),
    subType,
    agreementId: packet.RESOURCE.AGREEMENT_ID,
    invoiceId: packet.RESOURCE.INVOICE_ID,
    invoiceDate: formatDate(packet.RESOURCE.INVOICE_DATE),
    currency,
    invoiceAmount: remittance ? currencyFormatter.format(remittance.INVOICEAMOUNT as `${number}`) : "",
    obarSuggestedDisputeAmount: currencyFormatter.format(packet.RESOURCE.TOTAL_AMOUNT_OWED),
    sumOfAllReversalPayments: "",
    recoveryRate: "",
  };
};

export function mapServerToLocalWorkPackets<T extends WorkPacketType>(
  workPacketType: T,
  serverItems: ServerWorkPacketTypeMap[T][],
): WorkPacketTypeMap[T][];

export function mapServerToLocalWorkPackets(workPacketType: WorkPacketType, serverItems: ServerWorkPacket[]) {
  if (workPacketType === WorkPacketType.CHARGEBACKS) {
    return (serverItems as ServerChargebackWorkPacket[]).map(mapServerResponseToChargebackWorkPacket);
  }
  if (workPacketType === WorkPacketType.SHORTAGES) {
    return (serverItems as ServerShortageWorkPacket[]).map(mapServerResponseToShortageWorkPacket);
  }
  if (workPacketType === WorkPacketType.ACCRUALS) {
    return (serverItems as ServerAccrualWorkPacket[]).map(mapServerResponseToAccrualWorkPacket);
  }
}
