import { Box, Skeleton, Typography } from "@mui/material";
import { type FC, type ReactNode } from "react";
import { PulseLoader } from "react-spinners";

export const SummaryDisplay: FC<{ children: ReactNode; loading: boolean }> = ({ children, loading }) => (
  <Box bgcolor="#fff">
    <Typography variant="h6" marginBottom={2}>
      Summary
    </Typography>
    <Box display="grid" gap={1.5} sx={{ gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" } }}>
      {loading ? <PulseLoader size="8px" color="#EF1442" /> : children}
    </Box>
  </Box>
);

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);

export const SummaryBox: FC<{ title: string; amount?: number }> = ({ title, amount }) => (
  <Box
    boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
    paddingX={3}
    paddingY={3}
    borderRadius={2}
    border="1px solid #D0D5DD"
    width="100%"
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", md: "center" }}
    >
      <Typography color="secondary.text" fontWeight={500}>{title}</Typography>
      {amount !== undefined ? (
        <Typography variant="h3">{formatCurrency(amount)}</Typography>
      ) : (
        <Skeleton variant="text" sx={{ fontSize: "25px", flex: 0.5 }} />
      )}
    </Box>
  </Box>
);
