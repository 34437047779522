import { Box } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import {
  AutocompleteField,
  CheckboxField,
  MultiAutocompleteField,
  StringDateRangePickerField,
} from "src/components/UI/Form";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { useUserNickname } from "../../useUserNickname";
import { useUserOptions } from "../../useUserOptions";
import { filterableWorkPacketActions, staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useAccrualSubtypes } from "./hooks/useAccrualSubtypes";
import { useAuditAccountOptions } from "./hooks/useAuditAccountOptions";
import { useVendorOptions } from "./hooks/userVendorOptions";
import { useAccountOptions } from "./hooks/useAccountOptions";
import { EMPTY_ARRAY } from "src/utils/empty-values";

export const AccrualFiltersForm: FC = () => {
  const { setFieldValue } = useFormikContext();
  const { currentView, currentWorkPacketType } = useWorkPacketsContext();
  const userNickname = useUserNickname();

  const userOptions = useUserOptions();
  const accountOptions = useAccountOptions();
  const vendorOptions = useVendorOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const accrualSubtypes = useAccrualSubtypes();

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(5, 1fr)",
        },
        gap: 2,
        rowGap: 3,
      }}
    >
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={accountOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field name={WorkPacketFilter.ObarCreatedDate} component={StringDateRangePickerField} label="OBAR Created Date" />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype}
        component={AutocompleteField}
        label="Recovery Stream Subtype"
        options={accrualSubtypes}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype"
        setFieldValue={setFieldValue}
      />

      <Field
          name={WorkPacketFilter.AgreementId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="Agreement ID"
          setFieldValue={setFieldValue}
          disableSuggestions
          fullWidth
        />

      <Field name={WorkPacketFilter.InvoiceDate} component={StringDateRangePickerField} label="Invoice Date" />

      <Field
          name={WorkPacketFilter.InvoiceNumber}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="Invoice Number"
          setFieldValue={setFieldValue}
          disableSuggestions
          fullWidth
        />

      <Field name={WorkPacketFilter.DisputeCreatedAt} component={StringDateRangePickerField} label="Dispute Date" />

      <Field
          name={WorkPacketFilter.OriginalDisputeId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="Original Dispute ID"
          setFieldValue={setFieldValue}
          disableSuggestions
          fullWidth
        />

      <Field
          name={WorkPacketFilter.ConsolidatedDisputeId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="Consolidated Dispute ID"
          setFieldValue={setFieldValue}
          disableSuggestions
          fullWidth
        />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={filterableWorkPacketActions[WorkPacketType.ACCRUALS]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.DisputeStatus}
        component={AutocompleteField}
        label="Dispute Status"
        options={staticFilterOptions[WorkPacketFilter.DisputeStatus]}
        getOptionLabel={(option: any) => option.title}
        placeholder="Dispute Status"
        setFieldValue={setFieldValue}
      />

      <Field type="checkbox" name={WorkPacketFilter.WithNotes} component={CheckboxField} label="With Notes" />
    </Box>
  );
};
