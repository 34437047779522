import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import usePermissions from "components/CustomHooks/usePermissions";
import { useMemo } from "react";
import { getUsersWithPermissions, type User } from "src/queries/users";
import type { ConditionalPick } from "type-fest";

type StringUserFieldKey = keyof ConditionalPick<User, string>;

const createUserOption = (user: User, valueField: StringUserFieldKey) => ({
  id: user.ID,
  value: user[valueField],
  title: user.NICKNAME,
  avatar: user.PROFILE_PIC,
});

export const useUserOptions = (showAll?: boolean, valueField: StringUserFieldKey = "ID") => {
  const { user } = useAuth0();
  const { isAdmin } = usePermissions();

  const { data: usersList } = useQuery({
    queryKey: ["users"],
    queryFn: getUsersWithPermissions,
    staleTime: 30000,
  });

  const userOptions = useMemo(() => {
    if (!user?.sub || !usersList) return [];
    if (!isAdmin && !showAll) {
      return usersList
        .filter(u => u.ID === user.sub)
        .map(u => createUserOption(u, valueField));
    }
    return usersList.map(u => createUserOption(u, valueField));
  }, [isAdmin, showAll, user?.sub, usersList, valueField]);

  return userOptions;
};
