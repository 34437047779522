import { Box, Divider, Typography } from "@mui/material";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { type DataGridProProps, DataGridPro } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";
import { attempt } from "src/utils/attempt";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { getCommandCenterData } from "../queries/command-center";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import { WorkPacketType } from "../WorkPacketType";

const renderCurrencyCell = (params: GridRenderCellParams<any, string>) =>
  attempt(
    () =>
      params.value ? Number.parseFloat(params.value).toLocaleString("en", { style: "currency", currency: "USD" }) : "",
    params.value,
    "Error parsing currency or rendering currency cell",
  );

const baseColumns: GridColDef[] = [
  {
    field: "openCount",
    headerName: "Open Count",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
  },
  {
    field: "openAmount",
    headerName: "Open Amount",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
    renderCell: renderCurrencyCell,
  },
  {
    field: "redFlags",
    headerName: "Red Flags",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
  },
];

const workPacketTypeColumnMap: Record<WorkPacketType, GridColDef[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    ...baseColumns,
    {
      field: "expiredCount",
      headerName: "Expired Count",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "expiredAmount",
      headerName: "Expired Amount",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: renderCurrencyCell,
    },
  ],

  [WorkPacketType.SHORTAGES]: baseColumns,
  [WorkPacketType.ACCRUALS]: baseColumns,
};

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = row => row.hierarchy;

const CommandCenter: FC = () => {
  const { currentWorkPacketType } = useWorkPacketsContext();

  const { data: rows, isFetching } = useQuery({
    queryFn: getCommandCenterData,
    queryKey: ["work-packets", currentWorkPacketType, "command-center"],
    staleTime: 10000,
  });

  return (
    <>
      <Box padding={3}>
        <Typography variant="h6" marginBottom={2}>
          Command Center
        </Typography>
        <Box sx={{ mt: 2, overflow: "auto" }}>
          <Box bgcolor="#FFF" minWidth={800}>
            <DataGridPro
              loading={isFetching}
              treeData
              rows={rows ?? EMPTY_ARRAY}
              columns={workPacketTypeColumnMap[currentWorkPacketType]}
              getTreeDataPath={getTreeDataPath}
              groupingColDef={{
                flex: 2,
                headerName: "Recovery stream",
              }}
              autoHeight
              hideFooter
              disableColumnFilter
              disableColumnMenu
            />
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default CommandCenter;
