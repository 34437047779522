import { SettlementCase } from "src/types/cases";
import { isNotNullish } from "src/utils/isDefined";
import { Dayjs } from "dayjs";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";

export interface UpdateParams {
  SHORTAGES_SETTLEMENT_CASE_DETAILS?: ServerCaseDetailsUpdate;
  SHORTAGES_SETTLEMENT_OFFERS?: ServerCaseOffersUpdate[];
}

export interface ServerCaseDetailsUpdate {
  SUBMISSION_PATH?: string;
  STAGE?: string;
  VENDOR_CODES?: string;
  SETTLEMENT_COUNT?: number;
  ALIGNED_BALANCE_AMOUNT?: number;
  ALIGNED_BALANCE_DATE?: string;
  RCA_ENDED_DATE?: string;
  PAID_AMOUNT_DURING_OPEN_SHORTAGE?: string;
  CURRENT_AMOUNT?: string;
  VALID_AMOUNT?: string;
  FINAL_AMOUNT?: string;
  FINAL_AMOUNT_RECEIVED_AT?: string;
  SETTLEMENT_ACCEPTED_AT?: string;
  PRESUBMISSION_REQUIRED?: boolean;
  BUSINESS?: string;
  PAYEE?: string;
  DIRECT_IMPORT?: boolean;
  CATEGORY?: string;
  VC_DISPUTED_IDS?: string;
  INVOICE_START_DUE_DATE?: string;
  INVOICE_END_DUE_DATE?: string;
  SUBMISSION_AMOUNT?: string;
  SUBMISSION_DATE?: string;
  SUBMISSION_COUNT?: string;
  FIRST_OFFER_ID?: string;
  FIRST_OFFER_AMOUNT?: string;
  FIRST_OFFER_DATE?: string;
  SECOND_OFFER_ID?: string;
  SECOND_OFFER_AMOUNT?: string;
  SECOND_OFFER_DATE?: string;
  THIRD_OFFER_ID?: string;
  THIRD_OFFER_AMOUNT?: string;
  THIRD_OFFER_DATE?: string;
  FINAL_RECOVERY_RATE_CALC?: string;
  SPRINTER?: string;
  SPRINTER_VIEWED_AT?: string;
  PROCESSOR?: string;
  PROCESSOR_VIEWED_AT?: string;
  OPS_EXTERNAL_COMMUNICATION?: string;
  CC_USER?: string;
  AMAZON_LAST_RESPONDED_AT?: string;
  LAST_RESPONDED_AT?: string;
  AMAZON_CASE_MANAGER?: string;
  LINK?: string;
}

interface ServerCaseOffersUpdate {
  ID?: string;
  CASE_ID?: string;
  OFFER_AMOUNT?: number;
  OFFER_DATE?: string;
}

export const serverToLocalKeys = {
  SUBMISSION_PATH: CaseDetailsKeys.SubmissionPath,
  STAGE: CaseDetailsKeys.Stage,
  VENDOR_CODES: CaseDetailsKeys.VendorCodes,
  SETTLEMENT_COUNT: CaseDetailsKeys.SettlementNo,
  ALIGNED_BALANCE_AMOUNT: CaseDetailsKeys.AlignedBalAmount,
  ALIGNED_BALANCE_DATE: CaseDetailsKeys.AlignedBalDate,
  RCA_ENDED_DATE: CaseDetailsKeys.RcaEndDate,
  PAID_AMOUNT_DURING_OPEN_SHORTAGE: CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment,
  CURRENT_AMOUNT: CaseDetailsKeys.CurrentAmount,
  VALID_AMOUNT: CaseDetailsKeys.ValidAmount,
  FINAL_AMOUNT: CaseDetailsKeys.FinalAmount,
  FINAL_AMOUNT_RECEIVED_AT: CaseDetailsKeys.FinalAmountDate,
  SETTLEMENT_ACCEPTED_AT: CaseDetailsKeys.SettlementAcceptanceDateToAmazon,
  PRESUBMISSION_REQUIRED: CaseDetailsKeys.PreSubmissionRequired,
  BUSINESS: CaseDetailsKeys.Business,
  PAYEE: CaseDetailsKeys.Payee,
  DIRECT_IMPORT: CaseDetailsKeys.DirectImport,
  CATEGORY: CaseDetailsKeys.Category,
  VC_DISPUTED_IDS: CaseDetailsKeys.VcDisputedIds,
  INVOICE_START_DUE_DATE: CaseDetailsKeys.InvoiceStartDueDate,
  INVOICE_END_DUE_DATE: CaseDetailsKeys.InvoiceEndDueDate,
  SUBMISSION_AMOUNT: CaseDetailsKeys.SubmissionAmount,
  SUBMISSION_DATE: CaseDetailsKeys.SubmissionDate,
  SUBMISSION_COUNT: CaseDetailsKeys.SubmissionCount,
  FIRST_OFFER_ID: CaseDetailsKeys.FirstOfferID,
  FIRST_OFFER_AMOUNT: CaseDetailsKeys.FirstOfferAmount,
  FIRST_OFFER_DATE: CaseDetailsKeys.FirstOfferDate,
  SECOND_OFFER_ID: CaseDetailsKeys.SecondOfferID,
  SECOND_OFFER_AMOUNT: CaseDetailsKeys.SecondOfferAmount,
  SECOND_OFFER_DATE: CaseDetailsKeys.SecondOfferDate,
  THIRD_OFFER_ID: CaseDetailsKeys.ThirdOfferID,
  THIRD_OFFER_AMOUNT: CaseDetailsKeys.ThirdOfferAmount,
  THIRD_OFFER_DATE: CaseDetailsKeys.ThirdOfferDate,
  FINAL_RECOVERY_RATE_CALC: CaseDetailsKeys.FinalRecoveryRateCalc,
  SPRINTER: CaseDetailsKeys.Sprinter,
  SPRINTER_VIEWED_AT: CaseDetailsKeys.SprinterDate,
  PROCESSOR: CaseDetailsKeys.Processor,
  PROCESSOR_VIEWED_AT: CaseDetailsKeys.ProcessorDate,
  OPS_EXTERNAL_COMMUNICATION: CaseDetailsKeys.OpsExternalCommunication,
  CC_USER: CaseDetailsKeys.CcUser,
  AMAZON_LAST_RESPONDED_AT: CaseDetailsKeys.AmazonLastRespondedAt,
  LAST_RESPONDED_AT: CaseDetailsKeys.CgLastRespondedAt,
  AMAZON_CASE_MANAGER: CaseDetailsKeys.AmazonCaseManager,
  LINK: CaseDetailsKeys.Link,
} as const;

export type ServerKey = keyof ServerCaseDetailsUpdate;
type LocalKey<K extends ServerKey> = (typeof serverToLocalKeys)[K];
type FieldMappers = { [K in ServerKey]: (value: SettlementCase[LocalKey<K>]) => ServerCaseDetailsUpdate[K] };

const identityMapper = (value: any) => value;
const dateMapper = (value: Dayjs | undefined) => value?.format("YYYY-MM-DD");
const stringMapper = (value: string | number | undefined) => value?.toString();

export const fieldMappers: FieldMappers = {
  SUBMISSION_PATH: identityMapper,
  STAGE: identityMapper,
  VENDOR_CODES: identityMapper,
  SETTLEMENT_COUNT: identityMapper,
  ALIGNED_BALANCE_AMOUNT: identityMapper,
  ALIGNED_BALANCE_DATE: dateMapper,
  RCA_ENDED_DATE: dateMapper,
  PAID_AMOUNT_DURING_OPEN_SHORTAGE: stringMapper,
  CURRENT_AMOUNT: stringMapper,
  VALID_AMOUNT: stringMapper,
  FINAL_AMOUNT: stringMapper,
  FINAL_AMOUNT_RECEIVED_AT: dateMapper,
  SETTLEMENT_ACCEPTED_AT: dateMapper,
  PRESUBMISSION_REQUIRED: identityMapper,
  BUSINESS: identityMapper,
  PAYEE: identityMapper,
  DIRECT_IMPORT: identityMapper,
  CATEGORY: identityMapper,
  VC_DISPUTED_IDS: identityMapper,
  INVOICE_START_DUE_DATE: dateMapper,
  INVOICE_END_DUE_DATE: dateMapper,
  SUBMISSION_AMOUNT: stringMapper,
  SUBMISSION_DATE: dateMapper,
  SUBMISSION_COUNT: stringMapper,
  FIRST_OFFER_ID: identityMapper,
  FIRST_OFFER_AMOUNT: stringMapper,
  FIRST_OFFER_DATE: dateMapper,
  SECOND_OFFER_ID: identityMapper,
  SECOND_OFFER_AMOUNT: stringMapper,
  SECOND_OFFER_DATE: dateMapper,
  THIRD_OFFER_ID: identityMapper,
  THIRD_OFFER_AMOUNT: stringMapper,
  THIRD_OFFER_DATE: dateMapper,
  FINAL_RECOVERY_RATE_CALC: stringMapper,
  SPRINTER: identityMapper,
  SPRINTER_VIEWED_AT: dateMapper,
  PROCESSOR: identityMapper,
  PROCESSOR_VIEWED_AT: dateMapper,
  OPS_EXTERNAL_COMMUNICATION: identityMapper,
  CC_USER: identityMapper,
  AMAZON_LAST_RESPONDED_AT: dateMapper,
  LAST_RESPONDED_AT: dateMapper,
  AMAZON_CASE_MANAGER: identityMapper,
  LINK: identityMapper,
};

export const offerRelatedFields = [
  "FIRST_OFFER_ID",
  "FIRST_OFFER_AMOUNT",
  "FIRST_OFFER_DATE",
  "SECOND_OFFER_ID",
  "SECOND_OFFER_AMOUNT",
  "SECOND_OFFER_DATE",
  "THIRD_OFFER_ID",
  "THIRD_OFFER_AMOUNT",
  "THIRD_OFFER_DATE",
] as const;

export const getUpdateParams = (
  oldValues: SettlementCase,
  newValues: SettlementCase,
  work_packet_update?: boolean,
): UpdateParams => {
  const params: ServerCaseDetailsUpdate = {};

  for (const [serverKey, localKey] of Object.entries(serverToLocalKeys)) {
    if (offerRelatedFields.includes(serverKey as any)) continue;

    const oldValue = oldValues[localKey];
    const newValue = newValues[localKey];
    if (oldValue?.valueOf() === newValue?.valueOf()) continue;

    const mapper = fieldMappers[serverKey as ServerKey];
    const mappedValue = mapper(newValue as any);
    params[serverKey as ServerKey] = mappedValue as any;
  }

  return {
    SHORTAGES_SETTLEMENT_CASE_DETAILS:
      Object.keys(params).length === 0
        ? undefined
        : {
            ...params,
            ...(work_packet_update && { CASE_ID: oldValues.id }),
          },
    SHORTAGES_SETTLEMENT_OFFERS: [
      createOfferUpdate(
        newValues.firstOfferID,
        newValues.firstOfferAmount,
        newValues.firstOfferDate,
        oldValues.firstOfferAmount,
        oldValues.firstOfferDate,
        work_packet_update ? oldValues.id : undefined,
      ),
      createOfferUpdate(
        newValues.secondOfferID,
        newValues.secondOfferAmount,
        newValues.secondOfferDate,
        oldValues.secondOfferAmount,
        oldValues.secondOfferDate,
        work_packet_update ? oldValues.id : undefined,
      ),
      createOfferUpdate(
        newValues.thirdOfferID,
        newValues.thirdOfferAmount,
        newValues.thirdOfferDate,
        oldValues.thirdOfferAmount,
        oldValues.thirdOfferDate,
        work_packet_update ? oldValues.id : undefined,
      ),
    ].filter(isNotNullish),
  };
};

export const createOfferUpdate = (
  id: string | undefined,
  newAmount: number | undefined,
  newDate: Dayjs | undefined,
  oldAmount: number | undefined,
  oldDate: Dayjs | undefined,
  caseId: string | undefined,
): { ID: string; OFFER_AMOUNT?: number; OFFER_DATE?: string; CASE_ID?: string } | null => {
  if ((newAmount === undefined && newDate === undefined) || !id) {
    return null;
  }

  const amountUnchanged = newAmount === oldAmount;
  const dateUnchanged = newDate?.isSame(oldDate) ?? newDate === oldDate;
  if (amountUnchanged && dateUnchanged) {
    return null;
  }

  return {
    ID: id,
    ...(amountUnchanged ? {} : { OFFER_AMOUNT: newAmount }),
    ...(dateUnchanged ? {} : { OFFER_DATE: newDate?.format("YYYY-MM-DD") }),
    ...(caseId && { CASE_ID: caseId }),
  };
};
