import { createContext, useCallback, useMemo, useState } from "react";

export interface PendingDialogAction {
  description: string;
}

export interface DialogActionStore {
  pendingDialogActions: Map<string, PendingDialogAction>;
  setPendingDialogAction(key: string, action: PendingDialogAction): void;
  clearPendingDialogAction(key: string): void;
}

export const DialogActionContext = createContext<DialogActionStore>(undefined!);

export const useDialogActionStore = (): DialogActionStore => {
  const [pendingDialogActions, setPendingDialogActions] = useState<Map<string, PendingDialogAction>>(() => new Map());

  const setPendingDialogAction = useCallback(
    (key: string, action: PendingDialogAction) => setPendingDialogActions(prev => new Map(prev).set(key, action)),
    [],
  );

  const clearPendingDialogAction = useCallback(
    (key: string) =>
      setPendingDialogActions(prev => {
        const newMap = new Map(prev);
        newMap.delete(key);
        return newMap;
      }),
    [],
  );

  const dialogActionStore: DialogActionStore = useMemo(
    () => ({
      pendingDialogActions,
      setPendingDialogAction,
      clearPendingDialogAction,
    }),
    [pendingDialogActions, clearPendingDialogAction, setPendingDialogAction],
  );

  return dialogActionStore;
};
