import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      throwOnError(error, query) {
        console.error("Query failed", query, error);
        return false;
      },
    },
  },
});
