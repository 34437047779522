import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { GridColDef, GridPaginationModel, GridRowModel, GridSortModel } from "@mui/x-data-grid-pro";
import { useMemo, useState } from "react";
import { FetchCaseItemsArgs, GetCaseItemsResponse } from "src/pages/UserDashboard/Cases/api/types";
import {
  QueryClient,
  QueryFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type { SortOption } from "src/pages/UserDashboard/WorkPackets/api/workPacketsAPI";
import { getCaseItems, updateCase } from "src/pages/UserDashboard/Cases/api/casesApi";
import { CaseItemUpdateModel, toServerMappers } from "src/pages/UserDashboard/Cases/mappers/mapLocalToServerCaseItem";
import { columnsMap } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid/columns";
import { CaseItem } from "src/types/case-items";
import type { GridRowSelectionModel } from "@mui/x-data-grid";

interface UpdateCaseItemParams {
  caseType: CaseType;
  caseId: string;
  updateParams: Partial<CaseItemUpdateModel>;
  queryClient: QueryClient;
}

const getSortOption = (sortModel: GridSortModel): SortOption | undefined => {
  if (!sortModel[0]) return;
  const { field: key, sort: direction } = sortModel[0];
  if (!direction) return;
  return { key, direction };
};

const getCaseItemsData: QueryFunction<
  GetCaseItemsResponse,
  ["cases", caseType: CaseType, caseId: string, "items", FetchCaseItemsArgs]
> = async ({ queryKey: [, caseType, caseId, , fetchCaseArgs], signal }) =>
  getCaseItems(caseId, caseType, fetchCaseArgs, signal);

const updateCaseItemMutationOptions: UseMutationOptions<void, Error, UpdateCaseItemParams> = {
  mutationFn: async ({ caseType, caseId, updateParams }) => void updateCase(caseId, caseType, updateParams),
  mutationKey: ["update-case"],

  onSuccess: async (_data, { caseType, caseId, queryClient }) => {
    await queryClient.invalidateQueries({ queryKey: ["cases", caseType] });
    await queryClient.invalidateQueries({ queryKey: ["cases", caseId] });
  },
};

export const useCaseItemsTable = (caseId: string, caseType: CaseType) => {
  const queryClient = useQueryClient();

  const [sortingModel, setSortModel] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });

  const fetchCaseItemsParams = useMemo<FetchCaseItemsArgs>(() => {
    const sorting = getSortOption(sortingModel);
    return { ...paginationModel, sorting };
  }, [paginationModel, sortingModel]);

  const dataQuery = useQuery({
    queryFn: getCaseItemsData,
    queryKey: ["cases", caseType, caseId, "items", fetchCaseItemsParams],
    staleTime: 30000,
  });
  const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);
  const [excludedFromSelection, setExcludedFromSelection] = useState<string[]>([]);

  const [selectAllMode, setSelectAllMode] = useState<
    "select-all" | null
  >(null);
  const columns = useMemo<GridColDef[]>(() => columnsMap[caseType], [caseType]);
  const { mutateAsync } = useMutation(updateCaseItemMutationOptions);

  const processRowUpdate = async (updatedRow: GridRowModel<CaseItem>, originalRow: GridRowModel<CaseItem>) => {
    const updates = Object.fromEntries(
      Object.entries(updatedRow).filter(([key, value]) => value !== originalRow[key as keyof CaseItem]),
    ) as Partial<CaseItem>;
    const serverUpdates = toServerMappers[caseType](originalRow, updates);
    try {
      // todo: handle error - show Snackbar or something?
      await mutateAsync({
        caseType,
        caseId,
        updateParams: serverUpdates,
        queryClient: queryClient,
      });
    } catch (error) {
      console.error("Error updating row", error);
      return originalRow;
    }
    return updatedRow;
  };

  const onDelete = () => queryClient.invalidateQueries({ queryKey: ["cases", caseId] });

  const handleRowSelection = (newSelection: GridRowSelectionModel) => {
    const totalChanges = Math.abs(newSelection.length - rowSelection.length);
    const isBulkSelection = totalChanges > 1;
    if (isBulkSelection) {
      const isSelectAll = newSelection.length > rowSelection.length;
      setSelectAllMode(isSelectAll ? "select-all" : null);
      if (!isSelectAll) {
        setSelectAllMode(null);
        setExcludedFromSelection([]);
      }
    }

    if (selectAllMode == "select-all") {
      const excludedIds = dataQuery.data?.items.filter(row => !newSelection.includes(row.id)).map(row => row.id);

      // Include unique IDs to be added to exclude list
      setExcludedFromSelection(prev => {
        const uniqueIds = Array.from(new Set([...prev, ...(excludedIds ?? [])]));
        return uniqueIds;
      });
    }

    setRowSelection(newSelection);
  };
  return {
    sortingModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
    dataQuery,
    columns,
    processRowUpdate,
    onDelete,
    handleRowSelection,
    rowSelection,
    selectAllMode,
    setSelectAllMode,
    excludedFromSelection,
  };
};
