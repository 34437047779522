import { FC } from "react";
import { DatePickerField } from "components/UI/Form";
import { Field } from "formik";
import { Box, TextField } from "@mui/material";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";

// NOTE: to display formatted currency strings, the Field's component needs to as
// eg <Field as={TextField} ... /> NOT <Field component={TextField} ... />
// Don't know why

export const ShortageEscalationsInfo: FC = () => {
  return (
    <Box
      display="grid"
      rowGap={3}
      columnGap={2}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
      }}
    >
      <Field
        name={CaseDetailsKeys.CaseCreationDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Case Created Date"
        size="small"
        label="Case Created Date"
        disabled
      />
      <Field
        name={CaseDetailsKeys.MonitoringDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Monitoring Date"
        size="small"
        label="Monitoring Date"
      />
      <Field
        name={CaseDetailsKeys.RemainingOpenBalance}
        as={TextField}
        variant="outlined"
        placeholder="Remaining Open Balance"
        size="small"
        label="Remaining Open Balance"
        disabled
      />
      <Field
        name={CaseDetailsKeys.ApprovedDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Approved Date in Case"
        size="small"
        label="Approved Date in Case"
      />
      <Field
        name={CaseDetailsKeys.ApprovedAmount}
        as={TextField}
        variant="outlined"
        placeholder="Approved Amount"
        size="small"
        label="Approved Amount"
        disabled
      />
    </Box>
  );
};
