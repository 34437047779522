import * as Yup from "yup";
import { CaseDetailsKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import { ShortagesEscalationCaseItemStatus } from "src/pages/UserDashboard/Cases/api/constants";

export const ShortageEscalationSchema = Yup.object({
  [CaseDetailsKeys.Status]: Yup.mixed<ShortagesEscalationCaseItemStatus>()
    .oneOf(Object.values(ShortagesEscalationCaseItemStatus))
    .notRequired(),

  [CaseDetailsKeys.ApprovedDate]: Yup.object()
    .notRequired()
    .test(
      `${CaseDetailsKeys.ApprovedDate}-required`,
      "Approved Date must be filled in if case is approved or partially approved.",
      (value, ctx) => {
        const status = ctx.parent[CaseDetailsKeys.Status];
        const approvedStatuses = [
          ShortagesEscalationCaseItemStatus.APPROVED,
          ShortagesEscalationCaseItemStatus.PARTIALLY_APPROVED,
        ];
        if (!approvedStatuses.includes(status)) return true;
        return !!value;
      },
    ),
});
