import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import theme from "src/styles/theme";
import { WorkPacketType } from "../WorkPacketType";
import { CaseType, isValidCaseType } from "./case-types";
import { CaseChangeLog } from "./tabs/CaseChangeLog/CaseChangeLog";
import { CaseCorrespondence } from "./tabs/CaseCorrespondence/CaseCorrespondence";
import { CaseDetails } from "./tabs/CaseDetails/CaseDetails";
import { Comments } from "../../Comments/Comments";

enum CaseTab {
  Details = "details",
  Comments = "comments",
  Changelog = "changelog",
  Correspondence = "correspondence",
}

const getCasePageUrl = (workPacketType: string, caseType: string, caseId: string, caseTab: CaseTab) =>
  `/user-dashboard/work-packets/${workPacketType}/${caseType}/${caseId}/${caseTab}`;

export const SingleCasePage: FC = () => {
  const navigate = useNavigate();
  const {
    workPacketType,
    caseType: urlCaseType,
    caseId,
    tab: urlTab,
  } = useParams<"workPacketType" | "caseType" | "caseId" | "tab">();

  const caseType = useMemo(() => {
    return `${workPacketType?.slice(0, -1)}_${urlCaseType?.slice(0, -1)}`.toUpperCase() as CaseType;
  }, [workPacketType, urlCaseType]);

  const currentTab = useMemo(() => {
    if (!Object.values(CaseTab).includes(urlTab as CaseTab)) return CaseTab.Details;
    return urlTab as CaseTab;
  }, [urlTab]);

  const validWorkPacket = workPacketType && Object.values(WorkPacketType).find(type => type === workPacketType);

  const paramsAreValid = isValidCaseType(caseType) && caseId && validWorkPacket && urlCaseType;

  const setCurrentTab = useCallback(
    (caseTab: CaseTab) => {
      if (!paramsAreValid) return;
      navigate(getCasePageUrl(validWorkPacket, urlCaseType, caseId, caseTab));
    },
    [caseId, urlCaseType, navigate, paramsAreValid, validWorkPacket],
  );

  useEffect(() => {
    if (paramsAreValid && urlTab !== currentTab) {
      navigate(getCasePageUrl(validWorkPacket, urlCaseType, caseId, CaseTab.Details), { replace: true });
    }
  }, [caseId, urlCaseType, currentTab, navigate, paramsAreValid, urlTab, validWorkPacket]);

  if (!paramsAreValid) {
    console.dir({ caseType, caseId, workPacketType });
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="row" margin={1}>
        <Link to={`/user-dashboard/work-packets/${validWorkPacket}/${urlCaseType}`}>
          {<ArrowBackIcon width={16} style={{ color: "var(--primary)", margin: "12px", marginRight: "6px" }} />}
        </Link>
        <Box display="flex" flexDirection="column" gap={0.25} margin={0.5}>
          <Typography fontSize="1.5rem">Case Details</Typography>
          <Typography>
            CG Case ID: <span style={{ fontWeight: "bold" }}>{caseId}</span>
          </Typography>
        </Box>
      </Box>
      <Tabs value={currentTab} onChange={(_event, tab: CaseTab) => setCurrentTab(tab)} sx={{ paddingTop: 3.5 }}>
        <Tab value={CaseTab.Details} label="Summary" />
        <Tab value={CaseTab.Comments} label="Comments" />
        <Tab value={CaseTab.Changelog} label="Change Log" />
        <Tab value={CaseTab.Correspondence} label="Correspondence" />
      </Tabs>
      <Box>
        {currentTab === CaseTab.Details ? (
          <CaseDetails caseId={caseId} caseType={caseType} />
        ) : currentTab === CaseTab.Comments ? (
          <Comments modelType={"cases"} modelId={caseId} />
        ) : currentTab === CaseTab.Changelog ? (
          <CaseChangeLog caseId={caseId} caseType={caseType} />
        ) : currentTab === CaseTab.Correspondence ? (
          <CaseCorrespondence caseId={caseId} />
        ) : null}
      </Box>
    </ThemeProvider>
  );
};
