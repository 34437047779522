import { Avatar, Grid } from "@mui/material";
import { FC, ReactNode } from "react";
import { UserData } from "src/types/comments";

export interface UserAvatarProps {
  userData: UserData;
}

export interface UserAvatarWithContentGridProps extends UserAvatarProps {
  children: ReactNode; // Child content to be rendered on the right
}

export const UserAvatarWithContentGrid: FC<UserAvatarWithContentGridProps> = ({ userData, children }) => {
  return (
    <Grid container spacing={2} alignItems="flex-start">
      {/* Avatar Section ,*/}
      <Grid
        className="comment-card-user-avatar"
        item
        sm="auto"
        alignSelf="flex-start"
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <UserAvatar name={userData.name} picture={userData.picture} />
      </Grid>

      {/* Content Section */}
      <Grid item xs={12} sm={10} container flexGrow={1}>
        {children}
      </Grid>
    </Grid>
  );
};

const UserAvatar: FC<UserData> = ({ name, picture }) => {
  const stringToColor = (input: string | undefined): string => {
    if (!input || typeof input !== "string") {
      return "#F7104D";
    }
    // Step 1: Generate a hash from the input string
    let hash = 0;
    for (const char of input) {
      hash = char.charCodeAt(0) + hash * 31; // Using 31 as a multiplier for better hash distribution
      hash |= 0; // Ensure the hash stays in the 32-bit integer range
    }

    // Step 2: Extract RGB components from the hash
    const red = (hash & 0xff0000) >> 16; // Extract the first 8 bits
    const green = (hash & 0x00ff00) >> 8; // Extract the next 8 bits
    const blue = hash & 0x0000ff; // Extract the last 8 bits

    // Step 3: Convert RGB components to a hex color string
    const color = `#${red.toString(16).padStart(2, "0")}${green.toString(16).padStart(2, "0")}${blue
      .toString(16)
      .padStart(2, "0")}`;

    return color;
  };

  const getInitials = (name?: string): string => {
    if (typeof name !== "string" || !name) {
      return "Anon";
    }
    const nameParts = name.trim().split(" ");
    if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();
    return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}`;
  };

  const backgroundColor = stringToColor(name);
  const initials = getInitials(name);

  return (
    <Avatar sx={{ backgroundColor: backgroundColor }} aria-label="user-avatar" src={picture ? picture : undefined}>
      {initials}
    </Avatar>
  );
};
