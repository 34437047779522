import { FC } from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup";

export const CustomerInfo: FC = () => (
  <FormFieldsGroup title="Customer Info" id={"customer-info"}>
    <Field
      name="vendorName"
      as={TextField}
      variant="outlined"
      placeholder="Vendor Name"
      size="small"
      label="Vendor Name"
      disabled
    />
    <Field
      name="vendorId"
      as={TextField}
      variant="outlined"
      placeholder="Vendor ID"
      size="small"
      label="Vendor Id"
      disabled
    />
    <Field
      name="storeName"
      as={TextField}
      variant="outlined"
      placeholder="Store Name"
      size="small"
      label="Store Name"
      disabled
    />
    <Field
      name="storeId"
      as={TextField}
      variant="outlined"
      placeholder="Store ID"
      size="small"
      label="Store ID"
      disabled
    />
  </FormFieldsGroup>
);
