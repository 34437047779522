import { FC, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import prettyBytes from "pretty-bytes";
import { Box, Button, ButtonBase, Divider, TextField, Typography } from "@mui/material";
import { HighlightOff, AddPhotoAlternateOutlined, InsertDriveFileOutlined } from "@mui/icons-material";

import { commentPalette } from "src/styles/palette";
import { CommentFormFieldKeys, HandleNewComment, NewCommentFormikActions } from "src/types/comments";
import { UserAvatarProps, UserAvatarWithContentGrid } from "./UserAvatar";

const acceptedImageTypes = new Set(["image/jpeg", "image/png"]);

const PreviewFile: FC<{ file: File; removeAttachment: () => void }> = ({ file, removeAttachment }) => {
  const { type, name, size } = file;

  const isAcceptedImage = acceptedImageTypes.has(type);

  const ImageFilePreview = () => (
    <>
      <Box sx={{ display: "inline-flex" }} alignItems="center">
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
      <Box>
        <img src={URL.createObjectURL(file)} alt={name} height="80px" />
      </Box>
      <Typography variant="caption" alignSelf="center">
        {prettyBytes(size)}
      </Typography>
    </>
  );

  const FileIconPreview = () => (
    <Box sx={{ display: "inline-flex" }} alignItems="center">
      <Typography variant="subtitle2" paddingInlineEnd={1}>
        {name}
      </Typography>
      <InsertDriveFileOutlined />
      <Typography variant="caption" alignSelf="center">
        {prettyBytes(size)}
      </Typography>
    </Box>
  );

  return (
    <Box p={2} key={`${type}-${name}-${new Date().toISOString()}`}>
      {isAcceptedImage ? <ImageFilePreview /> : <FileIconPreview />}
      <Button onClick={() => removeAttachment()} sx={{ minWidth: "0" }}>
        <HighlightOff />
      </Button>
    </Box>
  );
};

export const NewCommentFormWithUserAvatar: FC<UserAvatarProps & { handleNewComment: HandleNewComment }> = ({
  userData,
  handleNewComment,
}) => (
  <UserAvatarWithContentGrid userData={userData}>
    <NewCommentForm handleNewComment={handleNewComment} />
  </UserAvatarWithContentGrid>
);

export const NewCommentForm: FC<{
  handleNewComment: HandleNewComment;
}> = ({ handleNewComment }) => {
  const onFilePickButtonClick = useCallback((setFieldValue: NewCommentFormikActions["setFieldValue"]) => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.multiple = false;
    inputElement.accept = ".jpg, .png, .jpeg, .pdf, .csv, .xls, .xlsx";

    inputElement.addEventListener("change", (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (target?.files && target.files.length > 0) {
        const file = target.files[0];
        setFieldValue(CommentFormFieldKeys.attachment, file);
      }
    });
    inputElement.click();
  }, []);

  const removeAttachment = (setFieldValue: NewCommentFormikActions["setFieldValue"]) => {
    setFieldValue(CommentFormFieldKeys.attachment, null);
  };

  return (
    <Box borderRadius={2} sx={{ border: "1px solid", borderColor: commentPalette.lightBorder, width: "100%" }}>
      <Formik
        initialValues={{
          [CommentFormFieldKeys.newComment]: "",
          [CommentFormFieldKeys.attachment]: null as File | null,
        }}
        onSubmit={handleNewComment}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Field
              name={CommentFormFieldKeys.newComment}
              as={TextField}
              placeholder="Enter your comment"
              multiline
              rows={3}
              variant="standard"
              fullWidth
              sx={{
                border: 0,
                padding: "1rem",
                "& .MuiInput-underline:before": {
                  borderBottom: "none", // Remove the bottom border (normal state)
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none", // Remove the boldened border (focused state)
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none", // Remove the border on hover
                },
              }}
            />
            {values[CommentFormFieldKeys.attachment] ? (
              <>
                <Divider />
                <PreviewFile
                  file={values[CommentFormFieldKeys.attachment]}
                  removeAttachment={() => removeAttachment(setFieldValue)}
                />
              </>
            ) : null}

            <Box
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
              padding={1}
              bgcolor={commentPalette.commentBackground}
              borderRadius={2}
            >
              <Box>
                <ButtonBase onClick={() => onFilePickButtonClick(setFieldValue)}>
                  <AddPhotoAlternateOutlined sx={{ color: commentPalette.iconColor, marginLeft: "16px" }} />
                </ButtonBase>
                {/* <AlternateEmailOutlined sx={{ color: commentPalette.iconColor, marginLeft: "16px" }} /> */}
              </Box>
              <Button type="submit" variant="contained">
                Leave A Comment
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
