import { FC } from "react";
import { Box, ButtonBase, Grid, IconButton, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { parseDate } from "src/utils/date-parse";
import { commentPalette } from "src/styles/palette";
import { CommentAttachment, CommentCardProps } from "src/types/comments";
import { UserAvatarWithContentGrid } from "./UserAvatar";
import { getAttachmentLink } from "../api/commentsApi";

export const CommentCard: FC<CommentCardProps> = ({ commentData, togglePin }) => {
  const { id, createdAt, isPinned, comment, attachment, userData } = commentData;
  return (
    <Paper key={id} elevation={0} sx={{ background: "none" }}>
      <UserAvatarWithContentGrid userData={userData}>
        {/** Comment Header */}
        <Grid item container justifyContent="space-between" alignItems="center">
          {/** User Name */}
          <Grid item className="comment-card-user-name-header">
            <Typography variant="subtitle1" fontWeight={500}>
              {userData.name}
            </Typography>
          </Grid>
          {/** Date of comment */}
          <Grid item className="comment-card-comment-date">
            <Typography sx={{ color: commentPalette.timestamp, fontSize: "0.8rem" }}>
              {parseDate(createdAt).format("ddd MMM D hh:mma")}
            </Typography>
          </Grid>
        </Grid>
        {/** Comment Body */}
        <Grid
          item
          container
          padding={1}
          paddingInlineStart={3}
          alignItems="center"
          className="comment-card-body"
          borderRadius="8px"
          sx={{ backgroundColor: commentPalette.commentBackground }}
        >
          <Grid container item alignItems="center">
            {/** Comment Text */}
            <Grid item xs className="comment-card-comment-body">
              <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                {comment}
              </Typography>
            </Grid>
            {/* Pin Icon */}
            <Grid item className="comment-card-pin-icon">
              <IconButton size="small" onClick={() => togglePin(id, isPinned)}>
                {commentData.isPinned ? (
                  <PushPinIcon sx={{ color: commentPalette.pinned, transform: "rotate(40deg)" }} />
                ) : (
                  <PushPinOutlinedIcon sx={{ color: commentPalette.unPinned }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
          {/** Attachment */}
          {attachment ? <CommentAttachmentPreview attachment={attachment} /> : null}
        </Grid>
      </UserAvatarWithContentGrid>
    </Paper>
  );
};

const CommentAttachmentPreview: FC<{ attachment: CommentAttachment }> = ({ attachment }) => {
  const getDownloadLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const attachmentLink = await getAttachmentLink(attachment.id);

      const link = document.createElement("a");
      link.href = attachmentLink;
      // The 'download' property specifies the filename to be used when saving the file
      link.setAttribute("download", attachment.name);
      link.setAttribute("target", "_blank"); // Open in new tab
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("There was a problem with the download:");
      console.error(error);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid item sx={{maxWidth: '100%'}}>
      <Tooltip title={attachment.name} placement="top" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: 1,
            backgroundColor: "#f9f9f9",
            overflow: "hidden", // Ensure the box handles overflow properly
          }}
        >
          <ButtonBase onClick={getDownloadLink} sx={{maxWidth: '100%'}}>
            <InsertDriveFileIcon style={{ marginRight: 8 }} />

            {!isSmallScreen ? (
              <Typography
                variant="body2"
                sx={{
                  overflow: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                {attachment.name}
              </Typography>
            ) : null}
          </ButtonBase>
        </Box>
      </Tooltip>
    </Grid>
  );
};
