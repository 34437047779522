import { FC } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { AutocompleteField, CheckboxField } from "components/UI/Form";
import { QueryClient, useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { bulkDeleteCaseItems, bulkUpdateCaseItems } from "src/pages/UserDashboard/Cases/api/casesApi.ts";
import {
  shortagesCaseItemStatusMap,
  ShortagesEscalationCaseItemStatus,
} from "src/pages/UserDashboard/Cases/api/constants.ts";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types.ts";
import * as Yup from "yup";
import toast from "react-hot-toast";

interface BulkActionPopupProps {
  selectedItems: string[];
  excludedItems: string[];
  allSelected: boolean;
  caseId: string;
  caseType: CaseType;
  onClose: () => void;
  open: boolean;
}

interface BulkUpdateParams {
  selectedItems: string[];
  excludedItems: string[];
  allSelected: boolean;
  caseId: string;
  caseType: string;
  updateParams: Record<string, any>;
  queryClient: QueryClient;
}

const updateCaseItemsMutationOptions: UseMutationOptions<void, Error, BulkUpdateParams> = {
  mutationFn: async (params) => {
    if (params.updateParams.DELETE) {
      await bulkDeleteCaseItems(params);
    } else {
      await bulkUpdateCaseItems(params);
    }
  },
  mutationKey: ["update-case-items"],

  onSuccess: async (_data, { caseType, caseId, queryClient }) => {
    await queryClient.invalidateQueries({ queryKey: ["cases", caseType] });
    await queryClient.invalidateQueries({ queryKey: ["cases", caseType, caseId, "items"] });
  },
};

const CaseItemStatusOptions = {
  [CaseType.SHORTAGE_ESCALATION]: shortagesCaseItemStatusMap.filter(
    item => item.value != ShortagesEscalationCaseItemStatus.PAID_OUT,
  ),
  [CaseType.SHORTAGE_SETTLEMENT]: [],
  [CaseType.CHARGEBACK_ESCALATION]: [],
};

interface UpdateParams {
  STATUS: string;
  DELETE: boolean;
}

const validationSchema = Yup.object<UpdateParams>().shape({
  STATUS: Yup.string().when("DELETE", {
    is: false,
    then: schema => schema.required("Case Item status is required"),
    otherwise: schema => schema.notRequired(),
  }),
  DELETE: Yup.boolean(),
});

export const BulkActionPopup: FC<BulkActionPopupProps> = ({
  selectedItems,
  excludedItems,
  caseId,
  caseType,
  allSelected,
  onClose,
  open,
}) => {
  const queryClient = useQueryClient();
  const initialValues: UpdateParams = {
    STATUS: "",
    DELETE: false,
  };
  const { mutateAsync, isPending } = useMutation(updateCaseItemsMutationOptions);
  const onSubmit = async (updateParams: UpdateParams) => {
    const data: BulkUpdateParams = {
      caseId,
      excludedItems,
      selectedItems,
      allSelected,
      caseType,
      updateParams,
      queryClient,
    };
    await mutateAsync(data);
    toast.success("Case items updated successfully");
    onClose();
  };
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>Bulk Actions</DialogTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, dirty, isValid }) => (
          <Form>
            <DialogContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}  {...values.DELETE && { inert: '', style: { opacity: 0.5 }}}>
                  <Field
                    name={"STATUS"}
                    label="Case Item Status"
                    component={AutocompleteField}
                    placeholder="Select a Status"
                    getOptionLabel={(option: any) => option.title}
                    options={CaseItemStatusOptions[caseType]}
                  />
                </Box>
                <Field type="checkbox" name="DELETE" component={CheckboxField} label="Delete selected work packets" />
              </Box>
            </DialogContent>
            <DialogActions style={{ background: "transparent" }}>
              <Button variant="outlined" onClick={() => onClose()} disabled={isPending}>
                Cancel {isValid}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid || isPending}
                sx={{ paddingX: "24px" }}
              >
                <span>Save Changes</span>
                {isPending && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
