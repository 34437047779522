import { Checkbox, FormControl, InputLabel, ListSubheader, MenuItem, Select } from "@mui/material";
import { FC, ReactNode, useMemo } from "react";
import { FieldProps } from "formik";

// TODO (daniel): refactor Option definitions across the codebase into a single definition
export interface Option {
  label: string;
  value: string;
}

interface SelectFieldProps extends FieldProps {
  name: string;
  label: string;
  options: Option[];
  disabled?: boolean;
  multiple?: boolean;
  checkmarks?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  renderValue?: (value: any) => ReactNode;
  groupBy?: (option: Option) => string;
}

/**
 * SelectField component integrates Material-UI's Select component with Formik.
 *
 * @param field Formik field prop, automatically injected by Formik.
 * @param label The label for the select field.
 * @param label The label for the select field.
 * @param disabled Whether the select field is disabled.
 * @param options The options for the select dropdown.
 */
const SelectField: FC<SelectFieldProps> = ({
  field,
  label,
  disabled,
  multiple,
  checkmarks,
  options,
  onChange,
  renderValue,
  groupBy,
}) => {
  const optionGroups = useMemo(() => {
    if (!groupBy) return;
    return options.reduce<Record<string, Option[]>>((acc, option) => {
      const groupLabel = groupBy(option);
      const group = acc[groupLabel];
      if (group) {
        group.push(option);
      } else {
        acc[groupLabel] = [option];
      }
      return acc;
    }, {});
  }, [groupBy, options]);

  return (
    <FormControl size="small" fullWidth disabled={disabled}>
      <InputLabel id={`${field.name}-label`}>{label}</InputLabel>
      <Select
        variant="outlined"
        {...field}
        label={label}
        labelId={`${field.name}-label`}
        onChange={event => {
          field.onChange(event);
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        multiple={multiple}
        renderValue={renderValue}
      >
        {optionGroups
          ? Object.entries(optionGroups).map(([groupLabel, groupOptions]) => (
              <>
                <ListSubheader>{groupLabel}</ListSubheader>
                {groupOptions.map(option => (
                  <MenuOption option={option} showCheckmark={checkmarks} checked={field.value?.includes?.(option.value)} />
                ))}
              </>
            ))
          : options.map(option => (
              <MenuOption option={option} showCheckmark={checkmarks} checked={field.value?.includes?.(option.value)} />
            ))}
      </Select>
    </FormControl>
  );
};

interface MenuOptionProps {
  option: Option;
  showCheckmark?: boolean;
  checked?: boolean;
}

const MenuOption: FC<MenuOptionProps> = ({ option, showCheckmark, checked }) => (
  <MenuItem key={option.value} value={option.value}>
    {showCheckmark && <Checkbox checked={checked} />}
    {option.label}
  </MenuItem>
);

export default SelectField;
