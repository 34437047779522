import type { Option } from "./case-types";

export const redFlagOptions: Option[] = [
  {
    title: "Today’s Date to Monitoring Date > 3 days",
    value: "todays_date_to_monitoring_date_gt_3_days",
  },
  {
    title: "Current Action hasn’t changed for 10 days",
    value: "current_action_not_changed_for_10_days",
  },
];

export const settlementsRedFlagOptions = [
  //No change in case status field
  {
    title: "No change in any Case Status > 10 biz days",
    value: "current_status_not_changed_for_10_days",
    group: "No change in case status field",
  },
  {
    title: "Waiting on Amazon > 10 biz days",
    value: "waiting_on_amazon_status_for_10_days",
    group: "No change in case status field",
  },
  {
    title: "Waiting on Client > 10 biz days",
    value: "waiting_on_client_status_for_10_days",
    group: "No change in case status field",
  },
  {
    title: "Waiting on CG > 10 biz days",
    value: "waiting_on_cg_status_for_10_days",
    group: "No change in case status field",
  },
  {
    title: "Waiting on VM/AM > 10 biz days",
    value: "waiting_on_vmam_status_for_10_days",
    group: "No change in case status field",
  },
  {
    title: "Waiting on Payout > 30 biz days",
    value: "waiting_on_payout_status_for_10_days",
    group: "No change in case status field",
  },
  //no change in stage field
  {
    title: "Pushing for Advanced Offer > 30 biz days",
    value: "pushing_for_advanced_offer_stage_for_30_days",
    group: "No change in stage field",
  },
  //other red flags
  {
    title: "No change in Sprinter Date for > 4 biz days",
    value: "sprinter_date_not_changed_for_4_days",
    group: "Other red flags",
  },
  {
    title: "No change in Processor Date for > 7 biz days",
    value: "processor_date_not_changed_for_7_days",
    group: "Other red flags",
  },
  {
    title: "No change in Case Status for > 20 biz days",
    value: "no_status_change_for_20_days",
    group: "Other red flags",
  },
  {
    title: "No change in CG Last Response > 10 biz days",
    value: "cg_last_response_not_changed_for_10_days",
    group: "Other red flags",
  },
];
