import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { type ShortageWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<ShortageWorkPacket>();

export const DisputeInformation: FC = () => (
  <FormFieldsGroup
    title="Dispute Information"
    withDivider
    id="dispute-information"
  >
    <Field
      name={getField("disputeId")}
      as={TextField}
      variant="outlined"
      placeholder="Dispute ID"
      size="small"
      label="Dispute ID"
      disabled />

    <Field
      name={getField("vcParentInvoiceId")}
      as={TextField}
      variant="outlined"
      placeholder="VC Parent Invoice ID"
      size="small"
      label="VC Parent Invoice ID"
      disabled />

    <Field
      name={getField("vcDisputedInvoiceId")}
      as={TextField}
      variant="outlined"
      placeholder="VC Disputed Invoice ID"
      size="small"
      label="VC Disputed Invoice ID"
      disabled />

    <Field
      name={getField("invoiceDate")}
      as={TextField}
      variant="outlined"
      placeholder="Invoice Date"
      size="small"
      label="Invoice Date"
      disabled />

    <Field
      name={getField("invoiceDueDate")}
      as={TextField}
      variant="outlined"
      placeholder="Invoice Due Date"
      size="small"
      label="Invoice Due Date"
      disabled />

    <Field
      name={getField("shortageLag")}
      as={TextField}
      variant="outlined"
      placeholder="Shortage Lag"
      size="small"
      label="Shortage Lag"
      disabled />

    <Field
      name={getField("disputeByDate")}
      as={TextField}
      variant="outlined"
      placeholder="Dispute by Date"
      size="small"
      label="Dispute by Date"
      disabled />

    <Field
      name={getField("disputeAmount")}
      as={TextField}
      variant="outlined"
      placeholder="Dispute Amount"
      size="small"
      label="Dispute Amount"
      disabled />

    <Field
      name={getField("disputeApprovedAmount")}
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Approved Amount"
      size="small"
      label="Dispute - Approved Amount"
      disabled />

    <Field
      name={getField("disputeCreatedAt")}
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Created At"
      size="small"
      label="Dispute - Created At"
      disabled />

    <Field
      name={getField("disputeResolvedAt")}
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Resolved At"
      size="small"
      label="Dispute - Resolved At"
      disabled />
  </FormFieldsGroup>
);
