export enum ShortagesEscalationCaseItemStatus {
  WAITING_ON_AMAZON = "WAITING_ON_AMAZON",
  WAITING_ON_CLIENT = "WAITING_ON_CLIENT",
  WAITING_ON_RETEMPLATE_SUBMISSION = "WAITING_ON_RETEMPLATE_SUBMISSION",
  WAITING_ON_CG = "WAITING_ON_CG",
  WAITING_ON_VM_AM = "WAITING_ON_VM_AM",
  WAITING_ON_PROPOSED_OFFER_APPROVAL = "WAITING_ON_PROPOSED_OFFER_APPROVAL",
  REANALYSIS = "REANALYSIS",
  WAITING_ON_EVIDENCE = "WAITING_ON_EVIDENCE",
  PAID_OUT = "PAID_OUT",
  APPROVED = "APPROVED",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  DENIED = "DENIED",
  CLOSED_WITHOUT_RESOLUTION = "CLOSED_WITHOUT_RESOLUTION",
  HOLD = "HOLD",
}

export enum ShortagesSettlementsCaseStage {
  PUSHING_FOR_RCA_LINE_LEVEL_DETAILS = "PUSHING_FOR_RCA_LINE_LEVEL_DETAILS",
  PUSHING_FOR_ADVANCED_OFFER = "PUSHING_FOR_ADVANCED_OFFER",
  PUSHING_FOR_ALIGNED_BALANCE = "PUSHING_FOR_ALIGNED_BALANCE",
  PUSHING_FOR_RCA_TO_COMPLETE = "PUSHING_FOR_RCA_TO_COMPLETE",
  PUSHING_FOR_RCA_RESULTS = "PUSHING_FOR_RCA_RESULTS",
  AUDIT_RETAIL_VM_APPROVAL = "AUDIT_RETAIL_VM_APPROVAL",
  SUBMITTED = "SUBMITTED",
  PAID_OUT = "PAID_OUT",
  SETTLEMENT_CLOSED_WITHOUT_RESOLUTION = "SETTLEMENT_CLOSED_WITHOUT_RESOLUTION",
  SETTLEMENT_TURNED_DISPUTE = "SETTLEMENT_TURNED_DISPUTE",
}

export type ShortagesEscalationCaseItemStatusOption = (typeof shortagesCaseItemStatusMap)[0];
export const shortagesCaseItemStatusMap = [
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_AMAZON, title: "Waiting on Amazon", color: "#FFCD4D" },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_CG, title: "Waiting on CG", color: "#FF820F" },
  { value: ShortagesEscalationCaseItemStatus.PAID_OUT, title: "Paid Out", color: "#318FFF" },
  { value: ShortagesEscalationCaseItemStatus.APPROVED, title: "Approved", color: "#39D4A5" },
  { value: ShortagesEscalationCaseItemStatus.PARTIALLY_APPROVED, title: "Partially Approved", color: "#318FFF" },
  { value: ShortagesEscalationCaseItemStatus.DENIED, title: "Denied", color: "#E03741" },
  {
    value: ShortagesEscalationCaseItemStatus.CLOSED_WITHOUT_RESOLUTION,
    title: "Closed w/o Resolution",
    color: "#FECDD6",
  },
];

export const settlementCaseStatusMap = [
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_AMAZON, title: "Waiting on Amazon", color: "#FFCD4D" },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_CLIENT, title: "Waiting on Client", color: "#FF820F" },
  {
    value: ShortagesEscalationCaseItemStatus.WAITING_ON_RETEMPLATE_SUBMISSION,
    title: "Waiting on Re Template Submission",
    color: "#318FFF",
  },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_CG, title: "Waiting on CG", color: "#E03741" },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_VM_AM, title: "Waiting on VM/AM", color: "#7B3DFF" },
  {
    value: ShortagesEscalationCaseItemStatus.WAITING_ON_PROPOSED_OFFER_APPROVAL,
    title: "Waiting on Proposed Offer Approval",
    color: "#F155FF",
  },
  {
    value: ShortagesEscalationCaseItemStatus.REANALYSIS,
    title: "Re-Analysis",
    color: "#FECDD6",
  },
  {
    value: ShortagesEscalationCaseItemStatus.WAITING_ON_EVIDENCE,
    title: "Waiting on Evidence",
    color: "#FF820F",
  },
  {
    value: ShortagesEscalationCaseItemStatus.PAID_OUT,
    title: "Paid Out",
    color: "#318FFF",
  },
  {
    value: ShortagesEscalationCaseItemStatus.HOLD,
    title: "Hold",
    color: "#EAECF0",
  },
];

export const settlementCaseStagesMap = [
  {
    value: ShortagesSettlementsCaseStage.SUBMITTED,
    title: "Submitted",
    color: "#318FFF",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_ALIGNED_BALANCE,
    title: "Pushing for Aligned Balance",
    color: "#F155FF",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_TO_COMPLETE,
    title: "Pushing for RCA to Complete",
    color: "#7B3DFF",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_RESULTS,
    title: "Pushing for RCA Results: Audit/Retail/VM Approval",
    color: "#F63D68",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_LINE_LEVEL_DETAILS,
    title: "Pushing for RCA Line Level Details",
    color: "#FFCD4D",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_ADVANCED_OFFER,
    title: "Pushing for Advanced Offer",
    color: "#FF820F",
  },
  {
    value: ShortagesSettlementsCaseStage.PAID_OUT,
    title: "Paid Out",
    color: "#39D4A5",
  },
  {
    value: ShortagesSettlementsCaseStage.SETTLEMENT_TURNED_DISPUTE,
    title: "Settlement Turned Dispute",
    color: "#FECDD6",
  },
  {
    value: ShortagesSettlementsCaseStage.SETTLEMENT_CLOSED_WITHOUT_RESOLUTION,
    title: "Settlement Closed Without Resolution",
    color: "#EAECF0",
  },
];
