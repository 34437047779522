import { FC, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

import { EMPTY_ARRAY } from "src/utils/empty-values";
import { WorkPacketPopupContext } from "src/pages/UserDashboard/WorkPackets/WorkPacketsGrid/WorkPacketPopupContext";
import { WorkPacketDetailsPopup } from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup";
import { WorkPacketsStore, WorkPacketsStoreContext } from "src/pages/UserDashboard/WorkPackets/WorkPacketsContext";
import { WorkPacketView } from "src/pages/UserDashboard/WorkPackets/WorkPacketView";
import { CaseType, CaseWorkPacketTypeMap } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { useCaseItemsTable } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid/useCaseItemsTable.ts";
import { CaseItem } from "src/types/case-items.ts";
import { CaseItemDeletePopupContext } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid/CaseItemDeletePopupContext.ts";
import { CaseItemDeletePopup } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid/CaseItemDeletePopup";
import { BulkActionPopup } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid/BulkActionPopup";

export const CaseItemsTable: FC<{ caseId: string; caseType: CaseType }> = ({ caseId, caseType }) => {
  const {
    sortingModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
    dataQuery,
    columns,
    processRowUpdate,
    onDelete,
    handleRowSelection,
    rowSelection,
    selectAllMode,
    excludedFromSelection,
  } = useCaseItemsTable(caseId, caseType);

  const [selectedWorkPacketId, setSelectedWorkPacketId] = useState<string | null>(null);
  const [selectedCaseItemForDelete, setSelectedCaseItemForDelete] = useState<CaseItem | null>(null);
  const [showBulkActionPopup, setShowBulkActionPopup] = useState<boolean>(false);
  const workPacketStore = useMemo<WorkPacketsStore>(
    () => ({
      clearFilters: () => {},
      currentFilters: {} as any,
      currentView: WorkPacketView.AllPackets,
      currentWorkPacketType: CaseWorkPacketTypeMap[caseType],
      setFilters: () => {},
      setView: () => {},
    }),
    [caseType],
  );

  return (
    <Box>
      <Box paddingY={2} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h6" marginBottom={2}>
          Work Packets in this Case
        </Typography>
        <Button variant="outlined" disabled={rowSelection.length === 0} onClick={() => setShowBulkActionPopup(true)}>
          Bulk Actions
        </Button>
      </Box>
      <WorkPacketsStoreContext.Provider value={workPacketStore}>
        <CaseItemDeletePopupContext.Provider value={setSelectedCaseItemForDelete}>
          <WorkPacketPopupContext.Provider value={setSelectedWorkPacketId}>
            {selectedWorkPacketId && (
              <WorkPacketDetailsPopup
                open={!!selectedWorkPacketId}
                onClose={() => setSelectedWorkPacketId(null)}
                workPacketId={selectedWorkPacketId}
              />
            )}
            {selectedCaseItemForDelete && (
              <CaseItemDeletePopup
                caseItem={selectedCaseItemForDelete}
                onClose={() => setSelectedCaseItemForDelete(null)}
                open={!!selectedCaseItemForDelete}
                onDeleted={onDelete}
              />
            )}
            {showBulkActionPopup && (
              <BulkActionPopup
                open={showBulkActionPopup}
                selectedItems={rowSelection as string[]}
                caseType={caseType}
                excludedItems={excludedFromSelection}
                allSelected={selectAllMode != null}
                caseId={caseId}
                onClose={() => setShowBulkActionPopup(false)}
              />
            )}
            <div style={{ height: 600, width: "100%", borderRadius: "8px", contain: "paint" }}>
              <DataGridPro<CaseItem>
                rows={dataQuery.data?.items || EMPTY_ARRAY}
                rowCount={dataQuery.data?.total_records ?? 0}
                getRowId={row => row.id}
                loading={dataQuery.isPending}
                columns={columns}
                sortingMode="server"
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowSelectionModel={rowSelection}
                onRowSelectionModelChange={handleRowSelection}
                onPaginationModelChange={setPaginationModel}
                onSortModelChange={setSortModel}
                sortModel={sortingModel}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field], right: ["_details", "_actions"] }}
                rowHeight={70}
                disableRowSelectionOnClick
                keepNonExistentRowsSelected
                checkboxSelection={caseType === CaseType.SHORTAGE_ESCALATION}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={error => console.error("Error updating row", error)}
              />
            </div>
          </WorkPacketPopupContext.Provider>
        </CaseItemDeletePopupContext.Provider>
      </WorkPacketsStoreContext.Provider>
    </Box>
  );
};
