const currencyFormatter = new Intl.NumberFormat(undefined, {
  currency: "USD",
  style: "currency",
  currencyDisplay: "symbol",
  currencySign: "accounting",
  signDisplay: "never",
});

const formatTitleCase = (value: string): string => {
  return value
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const parseMaybeCurrency = (value: string | number | null | undefined): number | undefined => {
  if (typeof value === "string") return parseMaybeCurrency(parseFloat(value));
  if (value === null || value === undefined) return undefined;
  if (Number.isNaN(value)) return undefined;
  return Math.abs(value);
};

const formatMaybeCurrency = (value: string | number | null | undefined): string => {
  if (value === null || value == undefined) return "";
  if (typeof value === "string") return formatMaybeCurrency(parseFloat(value));
  return currencyFormatter.format(value);
};

function formatDateToISO(date?: Date): string | undefined {
  return date?.toISOString().split("T")[0] ?? undefined;
}

function valueOrUndefined<T>(value: T): NonNullable<T> | undefined {
  if (value == null) {
    return undefined;
  }
  return value;
}

function valueOrBlankString<T>(value: T): T | string {
  if (value) {
    return value;
  }
  return "";
}

function valueOrZero(value?: number | null): number {
  if (value) {
    return value;
  }
  return 0;
}

export { formatMaybeCurrency, formatTitleCase, valueOrUndefined, valueOrBlankString, valueOrZero, formatDateToISO };
