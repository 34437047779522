export enum CorrespondenceWorkFlag {
  AMAZON_RESPONDED = "amazon_responded",
  CORRESPONDENCE_STATUS_CHANGED_BY_AMAZON = "correspondence_status_changed_by_amazon",
  NO_RESPONSE_OR_STATUS_CHANGE_GT_3_DAYS = "no_response_or_status_change_gt_3_days",
  NO_MONITORING_DATE_UPDATE_GT_3_DAYS = "no_monitoring_date_update_gt_3_days",
  NO_SPRINTED_DATE_UPDATE_GT_3_DAYS = "no_sprinted_date_update_gt_3_days",
}

export const correspondenceWorkFlagLabels: Record<CorrespondenceWorkFlag, string> = {
  [CorrespondenceWorkFlag.AMAZON_RESPONDED]: "Amazon Responded",
  [CorrespondenceWorkFlag.CORRESPONDENCE_STATUS_CHANGED_BY_AMAZON]: "Correspondence Status changed by Amazon",
  [CorrespondenceWorkFlag.NO_RESPONSE_OR_STATUS_CHANGE_GT_3_DAYS]: "No response or status change > 3 day",
  [CorrespondenceWorkFlag.NO_MONITORING_DATE_UPDATE_GT_3_DAYS]: "No Monitoring Date update > 3 days",
  [CorrespondenceWorkFlag.NO_SPRINTED_DATE_UPDATE_GT_3_DAYS]: "No sprinter update > 3 days",
}
