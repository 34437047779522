import { FC } from "react";
import { FileUploadBox } from "../components/FileUploadBox";
import { FormFieldsGroup } from "../components";

export const EvidenceAttachments: FC = () => {
  return (
    <FormFieldsGroup withDivider title="Attachments" id="attachments">
      <FileUploadBox
        accept=".doc, .docx, .xls, .xlsx, .xlsv, .csv, .png, .jpg, .jpeg"
        acceptHint="DOC(X), XLS(X), CSV, PDF, PNG, JPG"
      />
    </FormFieldsGroup>
  );
};
