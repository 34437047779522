import { produce } from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { type ServerModelAttachment } from "src/types/work-packets";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { type DialogActionStore } from "../DialogActionContext";
import { type FileUpload, FileUploadStore } from "./FileUploadContext";

export interface useFileUploadConfig {
  existingFiles: ServerModelAttachment[];
  isLoadingExistingFiles: boolean;
  isUploading: boolean;
  dialogActionStore?: DialogActionStore,
}

export const useFileUploadStore = ({
  existingFiles,
  isLoadingExistingFiles,
  isUploading,
  dialogActionStore,
}: useFileUploadConfig): FileUploadStore => {
  const setPendingDialogAction = dialogActionStore?.setPendingDialogAction;
  const clearPendingDialogAction = dialogActionStore?.clearPendingDialogAction;

  const [fileUploads, setFileUploads] = useState<FileUpload[]>(EMPTY_ARRAY);

  const enqueueFileUpload: FileUploadStore["enqueueFileUpload"] = useCallback(
    newFiles => {
      if (isLoadingExistingFiles) return;
      const validNewFiles = newFiles.filter(
        newFile =>
          !(
            existingFiles.some(attachment => attachment.ATTACHMENT.FILE_NAME === newFile.name) ||
            fileUploads.some(upload => upload.file.name === newFile.name)
          ),
      );
      const invalidFileCount = newFiles.length - validNewFiles.length;
      if (invalidFileCount > 0) {
        toast.error(
          invalidFileCount === 1
            ? "A file already exists with that file name."
            : `${invalidFileCount} files already exist with that file name.`,
        );
      }

      if (validNewFiles.length > 0) {
        setFileUploads(existingFileUploads => [
          ...existingFileUploads,
          ...validNewFiles.map((newFile): FileUpload => ({ status: "pending", file: newFile })),
        ]);

        setPendingDialogAction?.("attachment-upload", {
          description: "pending file uploads",
        });
      }
    },
    [isLoadingExistingFiles, existingFiles, fileUploads, setPendingDialogAction],
  );

  const removeFileUpload: FileUploadStore["removeFileUpload"] = useCallback(
    filenameToRemove =>
      setFileUploads(fileUploads => fileUploads.filter(upload => upload.file.name !== filenameToRemove)),
    [setFileUploads],
  );

  const setFileUploadStatus: FileUploadStore["setFileUploadStatus"] = useCallback((filename, status) => {
    setFileUploads(
      produce(draft => {
        const index = draft.findIndex(u => u.file.name === filename);
        if (index < 0) return;
        draft[index] = { file: draft[0].file, ...status };
      }),
    );
  }, []);

  const hasPendingUploads = useMemo(() => fileUploads.some(upload => upload.status === "pending"), [fileUploads]);

  useEffect(() => {
    if (clearPendingDialogAction && fileUploads.length === 0) {
      clearPendingDialogAction("attachment-upload");
    }
  }, [clearPendingDialogAction, fileUploads.length]);

  const fileUploadStore: FileUploadStore = useMemo(
    () => ({
      existingFiles,
      isLoadingExistingFiles,
      fileUploads: fileUploads,
      isUploading,
      hasPendingUploads,
      setFileUploadStatus,
      enqueueFileUpload,
      removeFileUpload,
    }),
    [
      existingFiles,
      isLoadingExistingFiles,
      fileUploads,
      isUploading,
      hasPendingUploads,
      setFileUploadStatus,
      enqueueFileUpload,
      removeFileUpload,
    ],
  );

  return fileUploadStore;
};
