import { EscalationCase, ServerCase, SettlementCase } from "src/types/cases";
import { mapOwnerObject } from "../../WorkPackets/mappers";
import {
  formatMaybeCurrency,
  formatTitleCase,
  valueOrBlankString,
  valueOrUndefined,
  valueOrZero,
} from "src/pages/UserDashboard/Cases/mappers/utils";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { dateOrUndefined } from "src/utils/date-parse";

export enum CaseDetailsKeys {
  CaseId = "id",
  AmazonCaseId = "amazonCaseId",
  CaseOwner = "caseOwner",
  VendorId = "vendorId",
  VendorName = "vendorName",
  StoreId = "storeId",
  StoreName = "storeName",
  Status = "status",
  RecoveryStream = "recoveryStream",
  RecoveryStreamType = "recoveryStreamType", // TODO: map this [resource.ISSUE_TYPE]
  RecoveryStreamActivationDate = "recoveryStreamActivationDate",
  RecoveryStreamSubType = "recoveryStreamSubType",
  RemainingOpenBalance = "remainingOpenBalance",
  IssueSubTypeDesc = "issueSubTypeDesc",
  Notes = "notes", // TODO: map this
  LastModifiedBy = "lastModifiedBy",
  ManualFilingUser = "manualFilingUser",
  ApprovedAmount = "approvedAmount",
  PaidAmount = "paidAmount",
  CaseCreationDate = "caseCreatedDate",
  ApprovedDate = "approvedDate",
  MonitoringDate = "monitoringDate",
  DisputedAmount = "disputedAmount",
  SubmissionPath = "submissionPath",
  Stage = "stage",
  VendorCodes = "vendorCodes",
  SettlementNo = "settlementNo",
  AlignedBalAmount = "alignedBalAmount",
  AlignedBalDate = "alignedBalDate",
  RcaEndDate = "rcaEndDate",
  PaidAmountBeforeOpenBalanceAlignment = "paidAmountBeforeOpenBalanceAlignment",
  CurrentAmount = "currentAmount",
  ValidAmount = "validAmount",
  FinalAmount = "finalAmount",
  FinalAmountDate = "finalAmountDate",
  SettlementAcceptanceDateToAmazon = "settlementAcceptanceDateToAmazon",
  PreSubmissionRequired = "preSubmissionRequired",
  Business = "business",
  Payee = "payee",
  DirectImport = "directImport",
  Category = "category",
  VcDisputedIds = "vcDisputedIds",
  InvoiceStartDate = "invoiceStartDate",
  InvoiceEndDate = "invoiceEndDate",
  InvoiceStartDueDate = "invoiceStartDueDate",
  InvoiceEndDueDate = "invoiceEndDueDate",
  SubmissionAmount = "submissionAmount",
  SubmissionDate = "submissionDate",
  SubmissionCount = "submissionCount",
  FirstOfferID = "firstOfferID",
  FirstOfferAmount = "firstOfferAmount",
  FirstOfferDate = "firstOfferDate",
  SecondOfferID = "secondOfferID",
  SecondOfferAmount = "secondOfferAmount",
  SecondOfferDate = "secondOfferDate",
  ThirdOfferID = "thirdOfferID",
  ThirdOfferAmount = "thirdOfferAmount",
  ThirdOfferDate = "thirdOfferDate",
  FinalRecoveryRateCalc = "finalRecoveryRateCalc",
  Sprinter = "sprinter",
  SprinterDate = "sprinterDate",
  Processor = "processor",
  ProcessorDate = "processorDate",
  OpsExternalCommunication = "opsExternalCommunication",
  CcUser = "ccUser",
  PrevCaseIds = "prevCaseIds",
  AmazonLastRespondedAt = "amazonLastRespondedAt",
  CgLastRespondedAt = "cgLastRespondedAt",
  Link = "link",
  PaymentIds = "paymentIds",
  PayoutDate = "payoutDate",
  AmazonCaseManager = "amazonCaseManager",
  CurrentRRCalc = "currentRRCalc",
  VcLink = "vcLink",
  CorrespondenceWorkFlags = "correspondenceWorkFlags",
}

export enum CaseItemKeys {
  CaseItemId = "id",
  CaseId = "case_id",
  ChargeBackIssueId = "chargeBackIssueId",
  CaseItemStatus = "caseItemStatus",
  FinancialCharge = "financialCharge",
  ResolutionDate = "resolutionDate",
  PaidAmount = "paidAmount",
  ApprovedAmount = "approvedAmount",
  ASIN = "asin",
  ARN = "arn",
  ASN = "asn",
  WorkPacketId = "packetId",
  VcParentInvoiceId = "vcParentInvoiceId",
  VcDisputedInvoiceId = "vcDisputedInvoiceId",
  RemainingOpenBalance = "remainingOpenBalance",
  RecoveredAmount = "recoveredAmount",
  InvoiceForPayment = "invoiceForPayment",
  PaymentId = "paymentId",
  OSSRWorkPacketId = "ossrWorkPacketId",
  PaymentDetailsId = "paymentDetailsId",
  PoNumber = "poNumber",
  LastInvoiceNumberForPayment = "lastInvoiceNumberForPayment",
  LastInvoicePaymentId = "lastInvoicePaymentId",
  LastInvoiceClientAmount = "lastInvoiceClientAmount",
  LastInvoicePaymentDate = "lastInvoicePaymentDate",
  LastInvoiceClientDate = "lastInvoiceClientDate",
  PaymentDate = "paymentDate",
  ShortagesEscalationCaseDetailsId = "ShortagesEscalationCaseDetailsId",
  ChargebacksEscalationCaseItemDetailId = "chargebacksEscalationCaseItemDetailId",
  ShortagesEscalationCaseItemDetailId = "shortagesEscalationCaseItemDetailId",
}

export function mapServerSettlementToLocalCase(instance: ServerCase): SettlementCase {
  const settlement = instance.SHORTAGES_SETTLEMENT_CASE_DETAILS;
  const caseInstance: SettlementCase = {
    [CaseDetailsKeys.CaseId]: instance.ID,
    [CaseDetailsKeys.AmazonCaseId]: instance.AMAZON_CASE_ID,
    [CaseDetailsKeys.CaseOwner]: mapOwnerObject(instance.CASE_OWNER),
    [CaseDetailsKeys.VendorId]: instance.ACCOUNT.VENDOR_ID,
    [CaseDetailsKeys.VendorName]: instance.ACCOUNT.VENDOR.VENDOR_NAME,
    [CaseDetailsKeys.StoreId]: instance.ACCOUNT.ID,
    [CaseDetailsKeys.StoreName]: instance.ACCOUNT.STORE_NAME,
    [CaseDetailsKeys.Status]: instance.STATUS,
    [CaseDetailsKeys.RecoveryStream]: instance.FIRST_WORK_PACKET.RECOVERY_STREAM,
    [CaseDetailsKeys.RecoveryStreamSubType]: instance?.FIRST_WORK_PACKET?.RESOURCE?.RECOVERY_STREAM_SUB_TYPE,
    [CaseDetailsKeys.RecoveryStreamActivationDate]: undefined,
    [CaseDetailsKeys.PaidAmount]: valueOrZero(instance.PAID_AMOUNT),
    [CaseDetailsKeys.LastModifiedBy]: valueOrUndefined(instance.LAST_MODIFIED_BY),
    [CaseDetailsKeys.ManualFilingUser]: valueOrUndefined(instance.ACCOUNT.VC_FILING_USER),
    [CaseDetailsKeys.PaymentIds]: instance.SHORTAGES_SETTLEMENT_PAYMENT_IDS,
    [CaseDetailsKeys.PayoutDate]: dateOrUndefined(instance.SHORTAGES_SETTLEMENT_PAYMENT_DATES),
    [CaseDetailsKeys.Sprinter]: undefined,
    [CaseDetailsKeys.SprinterDate]: undefined,
    [CaseDetailsKeys.Processor]: undefined,
    [CaseDetailsKeys.ProcessorDate]: undefined,
    [CaseDetailsKeys.OpsExternalCommunication]: undefined,
    [CaseDetailsKeys.SubmissionPath]: undefined,
    [CaseDetailsKeys.Stage]: undefined,
    [CaseDetailsKeys.CcUser]: undefined,
    [CaseDetailsKeys.PrevCaseIds]: undefined,
    [CaseDetailsKeys.AmazonLastRespondedAt]: undefined,
    [CaseDetailsKeys
      .CgLastRespondedAt]: undefined,
    [CaseDetailsKeys.Link]: undefined,
    [CaseDetailsKeys.PreSubmissionRequired]: undefined,
    [CaseDetailsKeys.Business]: undefined,
    [CaseDetailsKeys.Payee]: undefined,
    [CaseDetailsKeys.VendorCodes]: undefined,
    [CaseDetailsKeys.DirectImport]: undefined,
    [CaseDetailsKeys.Category]: undefined,
    [CaseDetailsKeys.SettlementNo]: undefined,
    [CaseDetailsKeys.InvoiceStartDueDate]: undefined,
    [CaseDetailsKeys.InvoiceEndDueDate]: undefined,
    [CaseDetailsKeys.SubmissionAmount]: undefined,
    [CaseDetailsKeys.SubmissionDate]: undefined,
    [CaseDetailsKeys.SubmissionCount]: undefined,
    [CaseDetailsKeys.AlignedBalAmount]: undefined,
    [CaseDetailsKeys.AlignedBalDate]: undefined,
    [CaseDetailsKeys.RcaEndDate]: undefined,
    [CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment]: undefined,
    [CaseDetailsKeys.CurrentRRCalc]: undefined,
    [CaseDetailsKeys.CurrentAmount]: undefined,
    [CaseDetailsKeys.ValidAmount]: undefined,
    [CaseDetailsKeys.FirstOfferID]: undefined,
    [CaseDetailsKeys.FirstOfferAmount]: undefined,
    [CaseDetailsKeys.FirstOfferDate]: undefined,
    [CaseDetailsKeys.SecondOfferID]: undefined,
    [CaseDetailsKeys.SecondOfferAmount]: undefined,
    [CaseDetailsKeys.SecondOfferDate]: undefined,
    [CaseDetailsKeys.ThirdOfferID]: undefined,
    [CaseDetailsKeys.ThirdOfferAmount]: undefined,
    [CaseDetailsKeys.ThirdOfferDate]: undefined,
    [CaseDetailsKeys.SettlementAcceptanceDateToAmazon]: undefined,
    [CaseDetailsKeys.FinalAmount]: undefined,
    [CaseDetailsKeys.FinalAmountDate]: undefined,
    [CaseDetailsKeys.FinalRecoveryRateCalc]: undefined,
    [CaseDetailsKeys.AmazonCaseManager]: undefined,
    [CaseDetailsKeys.VcDisputedIds]: undefined,
    [CaseDetailsKeys.InvoiceStartDate]: undefined,
    [CaseDetailsKeys.InvoiceEndDate]: undefined,
    [CaseDetailsKeys.VcLink]: undefined,
    [CaseDetailsKeys.CorrespondenceWorkFlags]: instance.CORRESPONDENCE_WORK_FLAGS,
  };

  const scope = instance.ACCOUNT.SCOPES.find(item => item.NAME == "shortages");
  if (scope) {
    caseInstance[CaseDetailsKeys.RecoveryStreamActivationDate] = scope.ACTIVATION_DATE;
  }

  caseInstance[CaseDetailsKeys.Payee] = valueOrUndefined(instance.PAYEE);

  if (settlement != null) {
    caseInstance[CaseDetailsKeys.Sprinter] = valueOrUndefined(settlement.SPRINTER);
    caseInstance[CaseDetailsKeys.SprinterDate] = dateOrUndefined(settlement.SPRINTER_VIEWED_AT);
    caseInstance[CaseDetailsKeys.Processor] = valueOrUndefined(settlement.PROCESSOR);
    caseInstance[CaseDetailsKeys.ProcessorDate] = dateOrUndefined(settlement.PROCESSOR_VIEWED_AT);
    caseInstance[CaseDetailsKeys.OpsExternalCommunication] = valueOrBlankString(settlement.OPS_EXTERNAL_COMMUNICATION);
    caseInstance[CaseDetailsKeys.SubmissionPath] = valueOrUndefined(settlement.SUBMISSION_PATH);
    caseInstance[CaseDetailsKeys.Stage] = valueOrUndefined(settlement.STAGE);
    caseInstance[CaseDetailsKeys.CcUser] = valueOrUndefined(settlement.CC_USER);
    caseInstance[CaseDetailsKeys.PrevCaseIds] = valueOrUndefined(settlement.PREVIOUS_CASE_IDS);
    caseInstance[CaseDetailsKeys.AmazonLastRespondedAt] = dateOrUndefined(settlement.AMAZON_LAST_RESPONDED_AT);
    caseInstance[CaseDetailsKeys.CgLastRespondedAt] = dateOrUndefined(settlement.LAST_RESPONDED_AT);
    caseInstance[CaseDetailsKeys.PreSubmissionRequired] = valueOrBlankString(settlement.PRESUBMISSION_REQUIRED);
    caseInstance[CaseDetailsKeys.Business] = valueOrUndefined(settlement.BUSINESS);
    caseInstance[CaseDetailsKeys.VendorCodes] = valueOrUndefined(settlement.VENDOR_CODES);
    caseInstance[CaseDetailsKeys.DirectImport] = valueOrBlankString(settlement.DIRECT_IMPORT);
    caseInstance[CaseDetailsKeys.Category] = valueOrUndefined(settlement.CATEGORY);
    caseInstance[CaseDetailsKeys.SettlementNo] = valueOrUndefined(settlement.SETTLEMENT_COUNT);
    caseInstance[CaseDetailsKeys.AlignedBalAmount] = valueOrZero(settlement.ALIGNED_BALANCE_AMOUNT);
    caseInstance[CaseDetailsKeys.AlignedBalDate] = dateOrUndefined(settlement.ALIGNED_BALANCE_DATE);
    caseInstance[CaseDetailsKeys.RcaEndDate] = dateOrUndefined(settlement.RCA_ENDED_DATE);
    caseInstance[CaseDetailsKeys.CurrentAmount] = valueOrZero(settlement.CURRENT_AMOUNT);
    caseInstance[CaseDetailsKeys.ValidAmount] = valueOrZero(settlement.VALID_AMOUNT);
    caseInstance[CaseDetailsKeys.SettlementAcceptanceDateToAmazon] = dateOrUndefined(settlement.SETTLEMENT_ACCEPTED_AT);
    caseInstance[CaseDetailsKeys.FinalAmount] = valueOrZero(settlement.FINAL_AMOUNT);
    caseInstance[CaseDetailsKeys.FinalAmountDate] = dateOrUndefined(settlement.FINAL_AMOUNT_RECEIVED_AT);
    caseInstance[CaseDetailsKeys.Link] = undefined;
    caseInstance[CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment] = valueOrZero(settlement.PAID_AMOUNT_DURING_OPEN_SHORTAGE);
    caseInstance[CaseDetailsKeys.InvoiceStartDate] = undefined;
    caseInstance[CaseDetailsKeys.InvoiceEndDate] = undefined;
    caseInstance[CaseDetailsKeys.InvoiceStartDueDate] = dateOrUndefined(instance.INVOICE_START_DUE_DATE);
    caseInstance[CaseDetailsKeys.InvoiceEndDueDate] = dateOrUndefined(instance.INVOICE_END_DUE_DATE);
    caseInstance[CaseDetailsKeys.SubmissionAmount] = valueOrZero(instance.REMAINING_OPEN_BALANCE);
    caseInstance[CaseDetailsKeys.SubmissionDate] = dateOrUndefined(instance.AMAZON_CASE_CREATED_DATE);
    caseInstance[CaseDetailsKeys.SubmissionCount] = valueOrZero(instance.CASE_ITEMS_COUNT);
    caseInstance[CaseDetailsKeys.FirstOfferAmount] = 0;
    caseInstance[CaseDetailsKeys.FirstOfferDate] = undefined;
    caseInstance[CaseDetailsKeys.SecondOfferAmount] = 0;
    caseInstance[CaseDetailsKeys.SecondOfferDate] = undefined;
    caseInstance[CaseDetailsKeys.ThirdOfferAmount] = 0;
    caseInstance[CaseDetailsKeys.ThirdOfferDate] = undefined;
    caseInstance[CaseDetailsKeys.AmazonCaseManager] = valueOrUndefined(settlement.AMAZON_CASE_MANAGER);
    caseInstance[CaseDetailsKeys.FinalRecoveryRateCalc] = valueOrZero(settlement.FINAL_RR_CALC);
  }

  caseInstance[CaseDetailsKeys.InvoiceStartDate] = dateOrUndefined(instance.PAYMENT_DATES?.INVOICE_DATE_START);
  caseInstance[CaseDetailsKeys.InvoiceEndDate] = dateOrUndefined(instance.PAYMENT_DATES?.INVOICE_DATE_END);
  caseInstance[CaseDetailsKeys.InvoiceStartDueDate] = dateOrUndefined(instance.PAYMENT_DATES?.INVOICE_PAYMENT_DUE_DATE_START);
  caseInstance[CaseDetailsKeys.InvoiceEndDueDate] = dateOrUndefined(instance.PAYMENT_DATES?.INVOICE_PAYMENT_DUE_DATE_START);
  caseInstance[CaseDetailsKeys.VcDisputedIds] = instance.ITEMS?.[0]?.SHORTAGES_OSSR_WORK_PACKET?.DISPUTED_INVOICE_ID;

  if (!caseInstance[CaseDetailsKeys.AlignedBalAmount]) {
    caseInstance[CaseDetailsKeys.AlignedBalAmount] = 0;
  }
  if (!caseInstance[CaseDetailsKeys.ValidAmount]) {
    caseInstance[CaseDetailsKeys.ValidAmount] = 0;
  }

  if (caseInstance[CaseDetailsKeys.AlignedBalAmount] - caseInstance[CaseDetailsKeys.ValidAmount] !== 0) {
    caseInstance[CaseDetailsKeys.CurrentRRCalc] =
      caseInstance[CaseDetailsKeys.CurrentAmount] ?? 0 / (caseInstance[CaseDetailsKeys.AlignedBalAmount] - caseInstance[CaseDetailsKeys.ValidAmount]);
  } else {
    caseInstance[CaseDetailsKeys.CurrentRRCalc] = 0;
  }

  caseInstance[CaseDetailsKeys.FirstOfferID] = valueOrUndefined(instance.SHORTAGES_SETTLEMENT_OFFERS?.[0]?.ID);
  caseInstance[CaseDetailsKeys.FirstOfferAmount] = valueOrZero(instance.SHORTAGES_SETTLEMENT_OFFERS?.[0]?.OFFER_AMOUNT);
  caseInstance[CaseDetailsKeys.FirstOfferDate] = dateOrUndefined(instance.SHORTAGES_SETTLEMENT_OFFERS?.[0]?.OFFER_DATE);
  caseInstance[CaseDetailsKeys.SecondOfferID] = valueOrUndefined(instance.SHORTAGES_SETTLEMENT_OFFERS?.[1]?.ID);
  caseInstance[CaseDetailsKeys.SecondOfferAmount] = valueOrZero(instance.SHORTAGES_SETTLEMENT_OFFERS?.[1]?.OFFER_AMOUNT);
  caseInstance[CaseDetailsKeys.SecondOfferDate] = dateOrUndefined(instance.SHORTAGES_SETTLEMENT_OFFERS?.[1]?.OFFER_DATE);
  caseInstance[CaseDetailsKeys.ThirdOfferID] = valueOrUndefined(instance.SHORTAGES_SETTLEMENT_OFFERS?.[2]?.ID);
  caseInstance[CaseDetailsKeys.ThirdOfferAmount] = valueOrZero(instance.SHORTAGES_SETTLEMENT_OFFERS?.[2]?.OFFER_AMOUNT);
  caseInstance[CaseDetailsKeys.ThirdOfferDate] = dateOrUndefined(instance.SHORTAGES_SETTLEMENT_OFFERS?.[2]?.OFFER_DATE);

  caseInstance[CaseDetailsKeys.Notes] = valueOrUndefined(instance.NOTES);

  return caseInstance;
}

export const mapServerEscalationToLocalCase = (instance: ServerCase): EscalationCase => {
  const caseInstance: EscalationCase = {
    [CaseDetailsKeys.CaseId]: instance.ID,
    [CaseDetailsKeys.AmazonCaseId]: instance.AMAZON_CASE_ID,
    [CaseDetailsKeys.CaseOwner]: mapOwnerObject(instance.CASE_OWNER),
    [CaseDetailsKeys.VendorId]: instance.ACCOUNT?.VENDOR_ID ?? "",
    [CaseDetailsKeys.VendorName]: instance.ACCOUNT?.VENDOR?.VENDOR_NAME ?? "",
    [CaseDetailsKeys.StoreId]: instance.ACCOUNT?.ID ?? 0,
    [CaseDetailsKeys.StoreName]: instance.ACCOUNT?.STORE_NAME ?? "",
    [CaseDetailsKeys.Status]: instance.STATUS,
    [CaseDetailsKeys.RecoveryStream]: instance.FIRST_WORK_PACKET?.RECOVERY_STREAM,
    [CaseDetailsKeys.RecoveryStreamType]: formatTitleCase(instance.FIRST_WORK_PACKET?.RECOVERY_STREAM),
    [CaseDetailsKeys.RecoveryStreamActivationDate]: undefined,
    [CaseDetailsKeys.RecoveryStreamSubType]: instance.FIRST_WORK_PACKET?.RESOURCE?.RECOVERY_STREAM_SUB_TYPE,
    [CaseDetailsKeys.IssueSubTypeDesc]: valueOrUndefined(instance.FIRST_WORK_PACKET?.RESOURCE?.ISSUE_SUB_TYPE_DESC),
    [CaseDetailsKeys.Notes]: valueOrUndefined(instance.NOTES),
    [CaseDetailsKeys.LastModifiedBy]: valueOrUndefined(instance.LAST_MODIFIED_BY),
    [CaseDetailsKeys.ManualFilingUser]: valueOrUndefined(instance.ACCOUNT?.VC_FILING_USER),
    [CaseDetailsKeys.CaseCreationDate]: dateOrUndefined(instance.CREATED_AT),
    [CaseDetailsKeys.RemainingOpenBalance]: formatMaybeCurrency(instance.REMAINING_OPEN_BALANCE),
    [CaseDetailsKeys.ApprovedDate]: dateOrUndefined(instance.ESCALATION_DETAILS?.APPROVED_DATE),
    [CaseDetailsKeys.ApprovedAmount]: formatMaybeCurrency(instance.ESCALATION_APPROVED_AMOUNT),
    [CaseDetailsKeys.PaidAmount]: formatMaybeCurrency(instance.PAID_AMOUNT),
    [CaseDetailsKeys.DisputedAmount]: formatMaybeCurrency(instance.DISPUTED_AMOUNT),
    [CaseDetailsKeys.MonitoringDate]: dateOrUndefined(instance.ESCALATION_DETAILS?.MONITORED_DATE),
    [CaseDetailsKeys.VcLink]: valueOrUndefined(instance.VC_LINK),
  };
  if (instance.TYPE === CaseType.CHARGEBACK_ESCALATION) {
    caseInstance[CaseDetailsKeys.MonitoringDate] = dateOrUndefined(
      instance.CHARGEBACKS_ESCALATION_CASE_DETAILS?.MONITORED_DATE,
    );
    caseInstance[CaseDetailsKeys.ApprovedDate] = dateOrUndefined(
      instance.CHARGEBACKS_ESCALATION_CASE_DETAILS?.APPROVED_DATE,
    );
  } else if (instance.TYPE === CaseType.SHORTAGE_ESCALATION) {
    caseInstance[CaseDetailsKeys.CorrespondenceWorkFlags] = instance.CORRESPONDENCE_WORK_FLAGS;
  }
  const scope = instance.ACCOUNT?.SCOPES?.find(item => item.NAME == "shortages");
  if (scope) {
    caseInstance[CaseDetailsKeys.RecoveryStreamActivationDate] = dateOrUndefined(scope.ACTIVATION_DATE);
  }

  return caseInstance;
};
