import { FC, useState } from "react";
import { Box, Button, CircularProgress, Dialog } from "@mui/material";
import toast from "react-hot-toast";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { deleteCaseItem } from "src/pages/UserDashboard/Cases/api/casesApi.ts";
import { CaseItem } from "src/types/case-items.ts";

interface DeletePopupProps {
  caseItem: CaseItem;
  open: boolean;
  onClose: () => void;
  onDeleted?: () => void;
}

export const CaseItemDeletePopup: FC<DeletePopupProps> = ({ caseItem, open, onClose, onDeleted }) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const onDeleteCaseItem = () => {
    setIsDeleting(true);
    deleteCaseItem(caseItem.case_id, caseItem.id)
      .then(() => {
        if (onDeleted) {
          onDeleted();
        }
        onClose();
      })
      .finally(() => {
        toast.success(`You have successfully removed a work packet from this case.`, {
          position: "top-right",
          icon: <IoMdCheckmarkCircleOutline size={25} />,
          style: {
            background: " rgba(246, 254, 249, 1)",
            color: "rgba(2, 122, 72, 1)",
            border: "1px solid rgba(108, 233, 166, 1)",
            boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03) 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
          },
        });
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box display="flex" flexDirection="column" padding="10px" justifyContent="space-between" height="150px">
        <Box>Are you sure you want to remove Work Packet {caseItem.packetId} from this Case?</Box>
        <Box display="flex" justifyContent="end">
          <Button disabled={isDeleting} onClick={onClose} variant="text">
            No
          </Button>
          <Button disabled={isDeleting} onClick={onDeleteCaseItem} variant="contained">
            Yes
            {isDeleting && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
