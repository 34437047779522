import Linkify from "linkify-react";
import { type Opts as LinkifyOptions } from "linkifyjs";
import { type FC } from "react";
import { type Message } from "./correspondence-types";

const validLinkRegex = /^https?:\/\//;
const linkifyOptions: LinkifyOptions = {
  nl2br: true,
  target: "amazon",
  validate: { url: value => validLinkRegex.test(value) },
};

export const MessageDisplay: FC<{ message: Message; }> = ({ message: { from, timestamp, content } }) => {
  const date = timestamp.toDate();
  return <div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontWeight: "bold",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginBottom: "0.5rem",
      }}
    >
      <span>{from}</span>
      <span style={{ display: "inline-flex", flexDirection: "column" }}>
        {date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })} at {date.toLocaleTimeString("en-US")}
      </span>
    </div>
    <div style={{ whiteSpace: "pre-wrap" }}>
      <Linkify options={linkifyOptions}>{content}</Linkify>
    </div>
  </div>;
};

