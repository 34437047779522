import { FC } from "react";
import { Box, Button, CircularProgress, Divider } from "@mui/material";

interface CaseFormActionsProps {
  isSubmitting: boolean;
  isRefetching: boolean;
}

export const CaseFormActions: FC<CaseFormActionsProps> = ({ isSubmitting, isRefetching }) => (
  <>
    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
    <Box display="flex" flexDirection="row-reverse">
      <Button disabled={isSubmitting || isRefetching} type="submit" variant="contained">
        Save Changes {isSubmitting && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
      </Button>
    </Box>
  </>
);
