import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MouseEvent, useCallback, type FC } from "react";

export type CaseView = "pre-case" | "escalations" | "settlements";

export interface CaseTypeSelectorProps {
  value: CaseView;
  onChange: (value: CaseView) => void;
  showEscalations?: boolean;
  showSettlements?: boolean;
}

export const CaseTypeSelector: FC<CaseTypeSelectorProps> = ({ value, onChange, showEscalations, showSettlements }) => {
  const onToggleChange = useCallback(
    (_event: MouseEvent, newValue: CaseView | null) => {
      if (newValue !== null) {
        onChange(newValue);
      }
    },
    [onChange]
  );
  return (
    <ToggleButtonGroup value={value} exclusive onChange={onToggleChange} fullWidth>
      <ToggleButton value="pre-case">Pre-case</ToggleButton>
      {showEscalations && <ToggleButton value="escalations">Escalations</ToggleButton>}
      {showSettlements && <ToggleButton value="settlements">Settlements</ToggleButton>}
    </ToggleButtonGroup>
  );
};
