import { createContext } from "react";
import { CaseItem } from "src/types/case-items.ts";

/**
 * Context that provides a function to set the case item ID to view in a popup.
 * Passing `null` should close the popup.
 */
export const CaseItemDeletePopupContext = createContext((caseItem: CaseItem | null) => {
  console.error(`Tried to delete case item ${caseItem?.id} without a parent CaseItemDeletePopupContext`);
});
