import { useAuth0 } from "@auth0/auth0-react";
import { FilterList } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Tab, Tabs } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { DataGridPro, type GridPaginationModel, type GridSortModel } from "@mui/x-data-grid-pro";
import { type QueryFunction, useQuery } from "@tanstack/react-query";
import { CaseTypeSelector } from "components/work-packets/CaseTypeSelector";
import { Formik } from "formik";
import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import theme from "src/styles/theme";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";
import type { SortOption } from "../api/workPacketsAPI";
import { initialFilters } from "./case-initial-filters";
import { CaseFilterForm } from "./CaseFilters";

import { caseTypeColumnMap } from "./CaseGridColumns";
import { useNavigate, useParams } from "react-router-dom";
import { useUrlState } from "state-in-url/react-router";

import {
  CasesSummaryRecoverableAmount,
  fetchCases,
  type FetchCasesArgs,
  FetchCasesResponse,
  fetchCasesSummary,
  ServerCaseFilters,
} from "./casesApi";

import CommandCenter from "src/pages/UserDashboard/WorkPackets/Cases/CommandCenter";
import { CaseType, isEscalationCaseType, isShortageCaseType, isValidCaseType } from "./case-types";
import { SummaryBox, SummaryDisplay } from "./CasesSummary";
import Error404 from "src/ErrorPages/404/404";
import { SettlementsSummary } from "src/pages/UserDashboard/WorkPackets/Cases/SettlementsSummary.tsx";

const getSortOption = (sortModel: GridSortModel): SortOption | undefined => {
  if (!sortModel[0]) return;
  const { field: key, sort: direction } = sortModel[0];
  if (!direction) return;
  return { key, direction };
};

enum CaseViewTab {
  AllCases = "All Cases",
  NewCases = "New Cases",
  MyCases = "My Cases",
}

const getCasesData: QueryFunction<FetchCasesResponse, ["cases", caseType: CaseType, "list", FetchCasesArgs]> = async ({
  queryKey: [, , , fetchCaseArgs],
  signal,
}) => fetchCases(fetchCaseArgs, signal);

const getCasesSummary: QueryFunction<CasesSummaryRecoverableAmount, ["cases", CaseType, "summary"]> = async ({
  queryKey: [, caseType, _],
  signal,
}) => fetchCasesSummary(caseType, signal);

export const Cases: FC = () => {
  const { workPacketType, caseType: urlCaseType } = useParams();
  const caseType = useMemo(() => {
    return `${workPacketType?.slice(0, -1)}_${urlCaseType?.slice(0, -1)}`.toUpperCase() as CaseType;
  }, [workPacketType, urlCaseType]);

  const [showFilters, setShowFilters] = useState(true);
  const [lastSeenRowCount, setLastSeenRowCount] = useState<number>(0);

  const {
    tab: currentTab,
    setTab,
    currentFilters,
    setFilters,
    sortModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
  } = useCasesUrlState();
  const { page, pageSize } = paginationModel;

  const navigate = useNavigate();

  const fetchCasesParams = useMemo<FetchCasesArgs>(() => {
    const sorting = getSortOption(sortModel);
    return { caseType, page, pageSize, filters: currentFilters, sorting };
  }, [caseType, page, pageSize, currentFilters, sortModel]);

  const dataQuery = useQuery({
    queryFn: getCasesData,
    queryKey: ["cases", caseType, "list", fetchCasesParams],
    staleTime: 30000,
  });

  const summaryQuery = useQuery({
    queryFn: getCasesSummary,
    queryKey: ["cases", caseType, "summary"],
    staleTime: 50000,
  });

  const toggleFilters = () => setShowFilters(prev => !prev);
  const applyFilters = useCallback((values: ServerCaseFilters) => setFilters(values), [setFilters]);

  const { columnVisibilityModel, handleColumnVisibilityChange } = useColumnVisibility("work-packet-cases-grid");

  useEffect(() => {
    if (dataQuery.data?.totalRecords !== undefined) {
      setLastSeenRowCount(dataQuery.data.totalRecords);
    }
  }, [dataQuery.data]);

  if (!isValidCaseType(caseType)) {
    return <Error404 />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box padding={3} display="flex" gap="12px" flexDirection="column">
        <CaseTypeSelector
          value={isEscalationCaseType(caseType) ? "escalations" : "settlements"}
          onChange={type => {
            navigate(
              `/user-dashboard/work-packets/${workPacketType}` + (type === "pre-case" ? "?preCase=true" : `/${type}`),
            );
          }}
          showEscalations
          showSettlements={isShortageCaseType(caseType)}
        />
        <CommandCenter caseType={caseType} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={currentTab} onChange={(_event, tab: CaseViewTab) => setTab(tab)}>
            <Tab value={CaseViewTab.AllCases} label={CaseViewTab.AllCases} />
            <Tab value={CaseViewTab.NewCases} label={CaseViewTab.NewCases} />
            <Tab value={CaseViewTab.MyCases} label={CaseViewTab.MyCases} />
          </Tabs>
        </Box>

        <SummaryDisplay loading={summaryQuery.isLoading}>
          {caseType === CaseType.SHORTAGE_ESCALATION ? (
            <>
              <SummaryBox title="Total Recovery Amount" amount={summaryQuery.data?.total} />
              <SummaryBox title="Approved Recovery Amount" amount={summaryQuery.data?.approved} />
              <SummaryBox title="Pending Recovery Amount" amount={summaryQuery.data?.pending} />
              <SummaryBox title="Denied Recovery Amount" amount={summaryQuery.data?.denied} />
            </>
          ) : (
            <>
              <SummaryBox title="Pending Recovery Amount" amount={summaryQuery.data?.pending} />
              <SummaryBox title="Approved Recovery Amount" amount={summaryQuery.data?.approved} />
            </>
          )}
        </SummaryDisplay>
        {caseType === CaseType.SHORTAGE_SETTLEMENT && <SettlementsSummary caseType={caseType} />}
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBlock={1}>
          <Box display="flex" gap="12px">
            <Button
              startIcon={<FilterList />}
              variant="outlined"
              onClick={toggleFilters}
              className={showFilters ? "" : "active"}
            >
              Filters
            </Button>
          </Box>
        </Box>
        <Collapse in={showFilters}>
          <Formik initialValues={currentFilters} onSubmit={applyFilters} enableReinitialize>
            <CaseFilterForm caseType={caseType} />
          </Formik>
        </Collapse>
        <Box>
          {dataQuery.isError ? (
            <span>Failed to load list of cases.</span>
          ) : (
            <Box position="relative" overflow="auto">
              <Box maxHeight="70vh" display="flex" flexDirection="column">
                <DataGridPro
                  columns={caseTypeColumnMap[caseType]}
                  pinnedColumns={{ right: ["_details"] }}
                  rows={dataQuery.data?.cases ?? EMPTY_ARRAY}
                  getRowId={row => row.chargeGuardCaseId}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  rowCount={lastSeenRowCount}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  filterMode="server"
                  sortingMode="server"
                  onSortModelChange={setSortModel}
                  loading={dataQuery.isPending}
                  rowHeight={70}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={handleColumnVisibilityChange}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const defaultUrlState: CasesUrlState = {
  ...initialFilters,
  tab: CaseViewTab.AllCases,
  sort: [],
  page: 0,
  pageSize: 50,
};

type CasesUrlState = ServerCaseFilters & {
  tab: CaseViewTab;
  sort: [{ field: string; sort: "asc" | "desc" }] | [];
  page: number;
  pageSize: number;
};

const useCasesUrlState = () => {
  const { urlState, setUrl } = useUrlState(defaultUrlState, { replace: true });

  const setFilters = useCallback(
    (newFilters: ServerCaseFilters) =>
      setUrl(prevState => ({
        ...prevState,
        ...newFilters,
        ...(urlState.tab !== CaseViewTab.AllCases && {
          [ServerCaseFilters.CurrentCaseOwner]: "",
        }),
      })),
    [setUrl, urlState.tab],
  );

  const setTab = useCallback(
    (tab: CaseViewTab) => setUrl(prevState => ({ ...prevState, ...initialFilters, tab })),
    [setUrl],
  );

  const setSortModel = useCallback(
    (sortModel: GridSortModel) =>
      setUrl(prevState => {
        if (sortModel.length === 0) return { ...prevState, sort: [] };
        const { field, sort } = sortModel[0];
        if (!sort) return { ...prevState, sort: [] };
        const newSort = { field, sort };
        return { ...prevState, sort: [newSort] };
      }),
    [setUrl],
  );

  const setPaginationModel = useCallback(
    ({ page, pageSize }: GridPaginationModel) => setUrl(prevState => ({ ...prevState, page, pageSize })),
    [setUrl],
  );

  const { sort: sortModel, page, pageSize, tab, ...urlStateFilters } = urlState;

  const paginationModel = useMemo<GridPaginationModel>(() => ({ page, pageSize }), [page, pageSize]);

  const { user } = useAuth0();
  const userId = user!.sub!;
  const currentFilters = useMemo(() => computeFilters(urlStateFilters, tab, userId), [tab, urlStateFilters, userId]);

  return {
    tab,
    setTab,
    currentFilters,
    setFilters,
    sortModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
  };
};

const computeFilters = (filters: ServerCaseFilters, tab: CaseViewTab, userId: string) => {
  if (tab === CaseViewTab.MyCases) return { ...filters, [ServerCaseFilters.CurrentCaseOwner]: userId };
  if (tab === CaseViewTab.NewCases) return { ...filters, [ServerCaseFilters.CurrentCaseOwner]: "UNASSIGNED" };
  return filters;
};
